import { Button } from '@travelpass/design-system'
import {
  bookingDetailsContactContentCss,
  bookingDetailsContactCss,
  bookingDetailsContactSubtitleCss,
  bookingDetailsContactTitleCss,
} from './bookingDetailsContactCardStyles'

interface BookingDetailsContactCardProps {
  phoneNumber: string
  subtitle: string
  title: string
}

const BookingDetailsContactCard = ({
  phoneNumber,
  subtitle,
  title,
}: BookingDetailsContactCardProps) => {
  return (
    <div
      className={bookingDetailsContactCss}
      data-testid='BookingDetailsContactCard'
    >
      <div className={bookingDetailsContactContentCss}>
        <h3 className={bookingDetailsContactTitleCss}>{title}</h3>
        <p className={bookingDetailsContactSubtitleCss}>{subtitle}</p>
      </div>
      <Button
        label={phoneNumber}
        size='small'
        startIcon='phone'
        variant='text'
        onClick={() => (window.location.href = `tel:${phoneNumber}`)}
      />
    </div>
  )
}

export { BookingDetailsContactCard }
export type { BookingDetailsContactCardProps }
