import { Dropdown, DropdownOption } from '@travelpass/design-system'
import type {
  ExperienceSortBy,
  SortBy as HotelSortBy,
} from 'src/__generated__/graphql'
import { ResultsFiltersSection } from './ResultsFiltersSection'

interface ResultsFiltersSortProps {
  sort: HotelSortBy | ExperienceSortBy
  sortItems: { label: string; value: HotelSortBy | ExperienceSortBy }[]
  onChange(updatedSort: HotelSortBy | ExperienceSortBy): void
}

export const ResultsFiltersSort = ({
  sort,
  sortItems,
  onChange,
}: ResultsFiltersSortProps) => (
  <ResultsFiltersSection title='Sort List By:'>
    <Dropdown
      id='search-sort-drop-down'
      label='Sort by'
      placeholder='Sort by'
      portal={false}
      value={sort}
      onChange={onChange}
    >
      {sortItems.map(({ label, value }) => (
        <DropdownOption key={value} value={value}>
          {label}
        </DropdownOption>
      ))}
    </Dropdown>
  </ResultsFiltersSection>
)
