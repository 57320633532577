import type { ReactNode } from 'react'

interface ResultsCardContentProps {
  children?: ReactNode
}

export const ResultsCardContent = ({ children }: ResultsCardContentProps) => (
  <div className='p-4' data-testid='ResultsCardContent'>
    {children}
  </div>
)
