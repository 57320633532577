import classNames from 'classnames'

type Image = {
  url: string
  alt: string
}

type NativeImage = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

interface ImagePanelProps extends NativeImage {
  images: Image[]
}

export const ImagePanel = ({ images, ...rest }: ImagePanelProps) => {
  return (
    <div className='w-full'>
      <div className='min-h-50% flex'>
        {images
          ?.slice(0, 2)
          .map(({ url, alt }, i) => (
            <img
              key={i}
              alt={alt}
              className={imageCss(i == 0, true)}
              draggable={false}
              src={url}
              {...rest}
            />
          ))}
      </div>
      <div className='min-h-50% flex'>
        {images
          ?.slice(2, 4)
          .map(({ url, alt }, i) => (
            <img
              key={i}
              alt={alt}
              className={imageCss(i == 0, false)}
              draggable={false}
              src={url}
              {...rest}
            />
          ))}
      </div>
    </div>
  )
}

const imageCss = (isFirstImage: boolean, isOnTop: boolean) =>
  classNames('z-2 w-full object-cover opacity-100 max-w-50% max-h-100px', {
    'rounded-tl-10px': isFirstImage && isOnTop,
    'rounded-tr-10px': !isFirstImage && isOnTop,
    'rounded-bl-10px': isFirstImage && !isOnTop,
    'rounded-br-10px': !isFirstImage && !isOnTop,
  })
