export enum ExperienceSearchParam {
  arrival = 'arrival',
  customerReviewScore = 'customerReviewScore',
  departure = 'departure',
  duration = 'duration',
  latitude = 'latitude',
  location = 'location',
  longitude = 'longitude',
  priceRange = 'priceRange',
  radius = 'radius',
  sort = 'sort',
  tags = 'tags',
  timeOfDay = 'timeOfDay',
}
