import type { AvailableIcons } from '@travelpass/design-system'
import { HotelResultsSearchParams } from 'src/pages/hotels/results/hotelResultsConstants'

export type MainAmenity = {
  id?: HotelResultsSearchParams
  icon?: AvailableIcons
  label?: string
  matches?: string[]
}

const mainAmenities: MainAmenity[] = [
  {
    id: HotelResultsSearchParams.includeAirportShuttle,
    icon: 'airportShuttle',
    label: 'Airport Shuttle',
    matches: [
      'Airport Shuttle',
      'Free Airport Shuttle',
      'Airport Transportation',
    ],
  },
  {
    id: HotelResultsSearchParams.includeWifi,
    icon: 'wifi',
    label: 'Wifi',
    matches: ['Wifi', 'Free Wifi', 'Internet Access (free)'],
  },
  {
    id: HotelResultsSearchParams.includeBreakfast,
    icon: 'freeBreakfast',
    label: 'Breakfast',
    matches: ['Breakfast', 'Free Breakfast', 'Breakfast (free)'],
  },
  {
    id: HotelResultsSearchParams.includeParking,
    icon: 'localParking',
    label: 'Parking',
    matches: ['Parking', 'Free Parking', 'Parking (free)'],
  },
  {
    id: HotelResultsSearchParams.includeAccessibility,
    icon: 'accessible',
    label: 'Increased Accessibility',
    matches: ['Increased Accessibility', 'Wheelchair Accessible'],
  },
  {
    id: HotelResultsSearchParams.includeNonSmoking,
    icon: 'smokeFree',
    label: 'Non-smoking',
    matches: ['Non-smoking', 'Non Smoking'],
  },
  {
    id: HotelResultsSearchParams.includeFitnessCenter,
    icon: 'gym',
    label: 'Fitness Center',
    matches: [
      'Fitness Center',
      'Fitness Facility (24-hour)',
      'Fitness Facility',
    ],
  },
  {
    id: HotelResultsSearchParams.includeHotTub,
    icon: 'hottub',
    label: 'Hot Tub',
    matches: ['Hot Tub'],
  },
  {
    id: HotelResultsSearchParams.includePool,
    icon: 'pool',
    label: 'Pool',
    matches: ['Pool'],
  },
  {
    id: HotelResultsSearchParams.includeSpaServices,
    icon: 'spa',
    label: 'Spa Service',
    matches: ['Spa Service', 'Spa'],
  },
  {
    id: HotelResultsSearchParams.includePetFriendly,
    icon: 'pets',
    label: 'Pet Friendly (conditions apply)',
    matches: ['Pet Friendly (conditions apply)', 'Pets Allowed'],
  },
]

export default mainAmenities
