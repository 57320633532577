const searchBookingFormTitleContainerClasses =
  'flex flex-row items-center justify-start mb-6'

const searchBookingFormClasses = 'flex flex-col gap-2'

const searchBookingFormTitleClasses = 'type-h1'

const searchBookingButtonContainerClasses = 'mt-4'

export {
  searchBookingFormTitleContainerClasses,
  searchBookingFormClasses,
  searchBookingFormTitleClasses,
  searchBookingButtonContainerClasses,
}
