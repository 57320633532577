import { Divider, Skeleton } from '@travelpass/design-system'
import {
  detailsMinimalHeroContentCss,
  detailsMinimalHeroCss,
  detailsMinimalHeroHeaderCss,
  detailsMinimalHeroImageCss,
} from './detailsMinimalHeroStyles'

export const DetailsMinimalHeroLoading = () => (
  <div className={detailsMinimalHeroCss}>
    <div className={detailsMinimalHeroHeaderCss}>
      <div className={detailsMinimalHeroImageCss}>
        <Skeleton variant='rounded' />
      </div>
      <div className='md:h-38px h-6 w-20'>
        <Skeleton />
      </div>
    </div>
    <div className={detailsMinimalHeroContentCss}>
      <div className='h-18px w-2/5 md:h-5'>
        <Skeleton />
      </div>
      <div className='w-15 h-7'>
        <Skeleton variant='rounded' />
      </div>
    </div>
    <div className='pt-3'>
      <Divider />
    </div>
  </div>
)
