const checkInInstructionsTitle = 'Check-In Instructions'
const checkInSpecialInstructionsTitle = 'Check-In Special Instructions'
const feesTitle = 'Fees'
const ratePoliciesTitle = 'Additional Policies'

export {
  checkInInstructionsTitle,
  checkInSpecialInstructionsTitle,
  feesTitle,
  ratePoliciesTitle,
}
