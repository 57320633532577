import type { MouseEvent } from 'react'
import { useMemo, useState } from 'react'
import { Carousel, EmptyState, KeyCode } from '@travelpass/design-system'
import classNames from 'classnames'
import { ResultsCardImage } from './ResultsCardImage'

interface ResultsCardCarouselProps {
  height?: string
  images: string[]
  onClick?(event: MouseEvent): void
  title: string
}

const ResultsCardCarousel = ({
  height = 'h-50',
  images: initialImages,
  onClick = () => {},
  title,
}: ResultsCardCarouselProps) => {
  const [images, setImages] = useState(() => initialImages)
  const [isDragging, setIsDragging] = useState(false)
  const activeImages = useMemo(() => images?.slice(0, 5), [images])

  const onError = (currentIndex: number) =>
    setImages(images?.filter((_, index) => currentIndex !== index))

  if (images.length === 0)
    return (
      <div className='w-200px flex h-full flex-col items-center justify-center'>
        <EmptyState name='noImages' />
      </div>
    )

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div
      className='cursor-pointer'
      onClick={event => {
        if (event.target instanceof HTMLButtonElement)
          return event.stopPropagation()

        return !isDragging && onClick(event)
      }}
      onKeyDown={event =>
        event.key === KeyCode.ENTER &&
        event.target instanceof HTMLButtonElement &&
        event.stopPropagation()
      }
    >
      <Carousel
        isLight
        showArrowsOnHover
        afterChange={() => setIsDragging(false)}
        beforeChange={() => setIsDragging(true)}
        hideArrows={images.length === 1}
        hideDots={images.length === 1}
        isDisabled={images.length === 1}
        size='small'
      >
        {activeImages?.map((image, index) => (
          <div key={image} className={classNames('relative', height)}>
            <ResultsCardImage
              alt={`${title} hero #${index + 1}`}
              draggable={false}
              src={image}
              onError={() => onError(index)}
            />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export { ResultsCardCarousel }
