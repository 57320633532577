import mainAmenities from 'src/common/MainAmenities'

const constructAdditionalHotelAmenities = (amenities: string[]) =>
  amenities
    ?.filter(
      amenity =>
        !mainAmenities.some(mainAmenity =>
          mainAmenity.matches.includes(amenity)
        )
    )
    .sort() ?? []

const constructPopularHotelAmenities = (amenities: string[]) =>
  mainAmenities
    ?.filter(({ matches }) => matches.some(match => amenities?.includes(match)))
    ?.map(amenity => amenity) ?? []

export { constructAdditionalHotelAmenities, constructPopularHotelAmenities }
