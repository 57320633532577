import type { ReactNode } from 'react'
import { SnackbarProvider } from '@travelpass/design-system'

interface TpgThemeProviderProps {
  children: ReactNode
}

export const TpgThemeProvider = ({
  children,
}: TpgThemeProviderProps): JSX.Element => (
  <SnackbarProvider>{children}</SnackbarProvider>
)
