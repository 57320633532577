import { Modal, ModalScrollContents } from '@travelpass/design-system'
import { HotelPolicies } from './HotelPolicies'
import type { HotelPoliciesProps } from './types'

interface HotelPoliciesModalProps extends HotelPoliciesProps {
  onClose(): void
}

export const HotelPoliciesModal = ({
  checkinInstructions,
  checkinSpecialInstructions,
  fees,
  policies,
  ratePolicies,
  onClose,
}: HotelPoliciesModalProps) => (
  <Modal size='medium' title='Hotel Policy' onDismiss={onClose}>
    <ModalScrollContents>
      <div className={`${hotelPolicyModalCss} rebrand`}>
        <HotelPolicies
          checkinInstructions={checkinInstructions}
          checkinSpecialInstructions={checkinSpecialInstructions}
          contentType='type-body-1'
          fees={fees}
          policies={policies}
          ratePolicies={ratePolicies}
          titleType='type-h4'
        />
      </div>
    </ModalScrollContents>
  </Modal>
)

const hotelPolicyModalCss = 'flex flex-col gap-4 pb-8'
