import { Icon } from '@travelpass/design-system'
import { maxReviewScore } from 'src/constants/user'
import type {
  ReviewBadgeSize,
  ReviewBadgeVariant,
} from './reviewBadgeConstants'

interface ReviewBadgeProps {
  customerReviewScore: number
  size?: ReviewBadgeSize
  maxScore?: number
  totalCustomerReviews?: number
  variant?: ReviewBadgeVariant
}

export const ReviewBadge = ({
  customerReviewScore,
  maxScore = maxReviewScore,
  size = 'medium',
  totalCustomerReviews,
  variant = 'filled',
}: ReviewBadgeProps) => {
  const constructedTotalCustomerReviews = `${totalCustomerReviews} ${
    totalCustomerReviews > 1 ? 'Reviews' : 'Review'
  }`

  return (
    <div className={reviewBadgeCss}>
      <div className={reviewBadgeScoreCss({ size, variant })}>
        <div>
          {customerReviewScore}/{maxScore}
        </div>
        <div>
          <Icon name='thumbUpAlt' size='small' />
        </div>
      </div>
      {totalCustomerReviews && (
        <div className={reviewBadgeTypeStyles({ size })}>
          {constructedTotalCustomerReviews}
        </div>
      )}
    </div>
  )
}

const reviewBadgeCss = 'flex flex-row gap-2 items-center'
const reviewBadgeScoreSizeStyles = ({
  size,
  variant,
}: Partial<ReviewBadgeProps>) => {
  if (variant === 'text') return

  switch (size) {
    case 'large':
      return 'py-[5px] px-1.5'

    case 'small':
      return 'py-0.5 px-1.5'

    default:
      return 'py-1 px-1.5'
  }
}

const reviewBadgeScoreVariantStyles = (
  variant: ReviewBadgeProps['variant']
) => {
  if (variant === 'text') return

  return 'bg-forest rounded-md color-white'
}

const reviewBadgeTypeStyles = ({ size }: Partial<ReviewBadgeProps>) => {
  switch (size) {
    case 'large':
      return 'text-lg leading-5 font-bold'

    case 'small':
      return 'text-xs leading-none'

    default:
      return 'text-sm leading-none font-bold'
  }
}

const reviewBadgeScoreCss = ({ size, variant }: Partial<ReviewBadgeProps>) => {
  const badgeScoreSizeStyles = reviewBadgeScoreSizeStyles({ size, variant })
  const badgeScoreVariantStyles = reviewBadgeScoreVariantStyles(variant)
  const badgeTypeStyles = reviewBadgeTypeStyles({ size })

  return `flex flex-row gap-1 items-center justify-center ${badgeScoreSizeStyles} ${badgeScoreVariantStyles} ${badgeTypeStyles}`
}
