import type { ExperienceProduct } from 'src/__generated__/graphql'
import { DetailsMinimalHero } from './DetailsMinimalHero'
import { ReviewBadge } from '../ReviewBadge'

interface DetailsMinimalExperienceHeroProps {
  detailsMinimalExperienceHeroData: ExperienceProduct
}

export const DetailsMinimalExperienceHero = ({
  detailsMinimalExperienceHeroData,
}: DetailsMinimalExperienceHeroProps) => {
  const { destinations, images, reviews, title } =
    detailsMinimalExperienceHeroData ?? {}
  const { destinationName } = destinations?.[0] ?? {}
  const constructedImages = images?.size720x480 ?? []
  const { combinedAverageRating } = reviews ?? {}

  return (
    <DetailsMinimalHero
      isExperiences
      images={constructedImages.filter(
        (image: string | null | undefined): image is string =>
          image !== null && image !== undefined
      )}
      subtitle={destinationName ?? ''}
      title={title ?? ''}
    >
      {!!combinedAverageRating && (
        <ReviewBadge customerReviewScore={combinedAverageRating} maxScore={5} />
      )}
    </DetailsMinimalHero>
  )
}
