import isEmpty from 'lodash.isempty'
import type { Fee } from 'src/__generated__/graphql'
import { stringToNumber } from 'src/utils'
import type { Price } from './hotelPriceSummaryConstants'

const constructDueLater = (fees: Fee[] | null, payLaterAmount: Price) => {
  const dueLater = {
    otherFees: 0.0,
    payLaterAmount: 0.0,
    reserveNowPayLater: 0.0,
  }

  if (!isEmpty(fees)) {
    const feesTotal = fees.reduce(
      (sum, fee) => sum + parseFloat(fee?.amount?.amount),
      0
    )

    dueLater.otherFees = feesTotal
  }

  if (payLaterAmount) {
    dueLater.reserveNowPayLater = stringToNumber(payLaterAmount)
  } else {
    delete dueLater.reserveNowPayLater
  }

  return Object.values(dueLater)
    .reduce((sum, price) => sum + price, 0)
    ?.toString()
}

const constructDueNow = (dueNow: Price) =>
  stringToNumber(dueNow)?.toString() ?? '0'

const constructFees = (fees: Fee[]): Fee[] => {
  const updatedFees = []
  const resortFee: Fee = {
    type: 'Resort Fees',
    amount: {
      amount: '0',
    },
  }

  if (!isEmpty(fees)) {
    fees?.forEach(fee => {
      if (fee.type === 'Resort Fee') {
        resortFee.amount.amount = fee?.amount?.amount
        updatedFees.unshift(resortFee)
      } else {
        updatedFees.push(fee)
      }
    })
  }

  return updatedFees
}

const constructTotal = (dueLater: Price, dueNow: Price) => {
  const updatedDueLater = stringToNumber(dueLater) ?? 0
  const updatedDueNow = stringToNumber(dueNow) ?? 0
  const updatedTotal = (updatedDueLater + updatedDueNow)?.toFixed(2)

  return updatedTotal?.toString()
}

export { constructDueLater, constructDueNow, constructFees, constructTotal }
