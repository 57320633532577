import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import { createSearchParams } from 'react-router-dom'
import type { FilterBy, SortBy } from 'src/__generated__/graphql'
import type { allSearchParamsDecoded } from 'src/constants'
import {
  defaultHotelResultsParams,
  defaultHotelResultsParamsStrs,
  initialFilters,
} from 'src/constants'
import { validateLocation } from './addressUtils'
import { formatDate, validateDates } from './dateUtils'
import { validateFiltersAndSort } from './filterUtils'
import { stringToBoolean } from './stringUtils'
import type { hotelResultsUrlBasics } from './types'
import {
  validatePlaceId,
  validateGuests,
  validateMapZoom,
  validateRadius,
} from './userUtils'

interface validateDecodedSearchParamsArgs
  extends hotelResultsUrlBasics,
    FilterBy {
  placeCountry?: string
  placeId?: string
  placeShortName?: string
  placeType?: string
  mapBounds?: string
  mapExpanded?: boolean
  mapZoom?: boolean | number
  radius?: string
  sort?: SortBy
}

export const validateDecodedSearchParams = ({
  latitude,
  longitude,
  location,
  arrival,
  departure,
  adults,
  kids,
  placeCountry,
  placeId,
  placeShortName,
  placeType,
  mapBounds,
  mapExpanded,
  mapZoom,
  radius,
  sort,
  ...filters
}: validateDecodedSearchParamsArgs): allSearchParamsDecoded => {
  const { validLocation, validLat, validLon } = validateLocation({
    location,
    latitude,
    longitude,
  })

  const validPlaceCountry = placeCountry ?? ''

  const validPlaceId = validatePlaceId(placeId ?? '')

  const validPlaceShortName = placeShortName ?? ''

  const validPlaceType = placeType ?? ''

  const validMapBounds = mapBounds ?? ''

  const validMapExpanded = mapExpanded ?? false

  const validMapZoom = validateMapZoom(mapZoom)

  const validRadius = validateRadius(radius)

  const { validArrival, validDeparture } = validateDates({
    arrival,
    departure,
  })

  const { validAdults, validKids } = validateGuests({
    adults,
    kids,
  })

  const { validFilters, validSort } = validateFiltersAndSort({ filters, sort })
  return {
    placeCountry: validPlaceCountry,
    placeId: validPlaceId,
    placeShortName: validPlaceShortName,
    placeType: validPlaceType,
    mapBounds: validMapBounds,
    latitude: validLat,
    longitude: validLon,
    location: validLocation,
    mapExpanded: validMapExpanded,
    mapZoom: validMapZoom,
    radius: validRadius,
    arrival: validArrival,
    departure: validDeparture,
    adults: validAdults,
    kids: validKids,
    sort: validSort,
    ...validFilters,
  }
}

export const decodeAndValidateSearchParams = (
  searchParams: URLSearchParams
): allSearchParamsDecoded => {
  const decodedResponse = cloneDeep(defaultHotelResultsParams)

  const strings = [
    'mapBounds',
    'placeCountry',
    'placeId',
    'placeShortName',
    'placeType',
    'location',
    'radius',
    'sort',
  ]
  const ints = ['adults', 'kids', 'mapZoom']
  const floats = ['latitude', 'longitude']
  const objects = ['brands', 'customerReviewScore', 'priceRange', 'starRating']
  const dates = ['arrival', 'departure']

  Object.keys(decodedResponse).forEach(encodedKey => {
    const encodedValue = searchParams.get(encodedKey)

    if (ints.includes(encodedKey)) {
      decodedResponse[encodedKey] = parseInt(encodedValue) ?? 0
    } else if (floats.includes(encodedKey)) {
      decodedResponse[encodedKey] = parseFloat(encodedValue) ?? 200
    } else if (objects.includes(encodedKey)) {
      decodedResponse[encodedKey] = JSON.parse(encodedValue)
    } else if (dates.includes(encodedKey)) {
      decodedResponse[encodedKey] = dayjs(encodedValue)
    } else if (strings.includes(encodedKey)) {
      decodedResponse[encodedKey] = encodedValue
    } else if (stringToBoolean(encodedValue)) {
      decodedResponse[encodedKey] = true
    } else {
      delete decodedResponse[encodedKey]
    }
  })

  const {
    location,
    latitude,
    longitude,
    arrival,
    departure,
    adults,
    kids,
    placeCountry,
    placeId,
    placeShortName,
    placeType,
    mapExpanded,
    mapZoom,
    radius,
    sort,
    ...filters
  } = decodedResponse

  return validateDecodedSearchParams({
    location,
    latitude,
    longitude,
    arrival,
    departure,
    adults,
    kids,
    placeCountry,
    placeId,
    placeShortName,
    placeType,
    mapExpanded,
    mapZoom,
    radius,
    sort,
    ...filters,
  })
}

interface encodeSearchParamsArgs extends FilterBy {
  adults?: number
  arrival?: Dayjs
  departure?: Dayjs
  kids?: number
  latitude: number
  location: string
  longitude: number
  maxPrice?: number
  maxScore?: string
  maxStars?: string
  minPrice?: number
  minScore?: string
  minStars?: string
  sort?: SortBy
}
export const encodeSearchParams = (
  decodedSearchParams: encodeSearchParamsArgs
): URLSearchParams => {
  const endcodedResponse = cloneDeep(defaultHotelResultsParamsStrs)

  const strings = [
    'mapBounds',
    'maxScore',
    'maxStars',
    'minScore',
    'minStars',
    'placeCountry',
    'placeId',
    'placeShortName',
    'placeType',
    'location',
    'radius',
    'sort',
  ]
  const numbers = [
    'adults',
    'kids',
    'latitude',
    'longitude',
    'mapZoom',
    'maxPrice',
    'minPrice',
  ]
  const objects = ['brands']
  const dates = ['arrival', 'departure']

  Object.keys(endcodedResponse).forEach(decodedKey => {
    const decodedValue = decodedSearchParams[decodedKey]
    if (decodedValue) {
      if (numbers.includes(decodedKey)) {
        endcodedResponse[decodedKey] = Number(decodedValue).toString()
      } else if (objects.includes(decodedKey)) {
        endcodedResponse[decodedKey] = JSON.stringify(decodedValue)
      } else if (dates.includes(decodedKey)) {
        endcodedResponse[decodedKey] = formatDate(dayjs(decodedValue))
      } else if (strings.includes(decodedKey)) {
        endcodedResponse[decodedKey] = String(decodedValue)
      } else {
        endcodedResponse[decodedKey] = 'true'
      }
    } else {
      delete endcodedResponse[decodedKey]
    }
  })

  return createSearchParams(endcodedResponse)
}

/**
 * combineAndOverride
 * @summary combines and overrides the current search params with the
 * current filterValues, sortValue, and overrides
 *
 * Priority is as so
 * Overrides - override current and default values
 * FilterValues / SortValue- override current and default values
 * Current Values - if no FilterValues / SortValues override default values
 * Default Values
 */

interface combineAndOverrideArgs {
  searchParams: URLSearchParams
  filterValues?: FilterBy
  placeCountryValue?: string
  placeIdValue?: string
  placeShortNameValue?: string
  placeTypeValue?: string
  mapBoundsValue?: string
  mapExpandedValue?: boolean
  mapZoomValue?: boolean | number
  radiusValue?: string
  sortValue?: SortBy
  overrides?: hotelResultsUrlBasics
  openOnMap?: boolean
}

export const combineAndOverride = ({
  searchParams,
  filterValues,
  placeCountryValue,
  placeIdValue,
  placeShortNameValue,
  placeTypeValue,
  mapBoundsValue,
  mapExpandedValue,
  mapZoomValue,
  radiusValue,
  sortValue,
  overrides = {},
}: combineAndOverrideArgs) => {
  const combinedValues = cloneDeep(defaultHotelResultsParams)
  const currentValues = decodeAndValidateSearchParams(searchParams)

  const endcodedResponse = cloneDeep(defaultHotelResultsParamsStrs)

  Object.keys(endcodedResponse).forEach(paramKey => {
    const override = overrides[paramKey] ?? false
    const current = currentValues[paramKey] ?? false

    if (paramKey === 'placeCountry') {
      if (placeCountryValue) {
        combinedValues.placeCountry = placeCountryValue
      } else if (current) {
        combinedValues.placeCountry = current
      } else {
        delete combinedValues.placeCountry
      }
    } else if (paramKey === 'placeId') {
      if (placeIdValue) {
        combinedValues.placeId = placeIdValue
      } else if (current) {
        combinedValues.placeId = current
      } else {
        delete combinedValues.placeId
      }
    } else if (paramKey === 'placeShortName') {
      if (placeShortNameValue) {
        combinedValues.placeShortName = placeShortNameValue
      } else if (current) {
        combinedValues.placeShortName = current
      } else {
        delete combinedValues.placeShortName
      }
    } else if (paramKey === 'placeType') {
      if (placeTypeValue) {
        combinedValues.placeType = placeTypeValue
      } else if (current) {
        combinedValues.placeType = current
      } else {
        delete combinedValues.placeType
      }
    } else if (paramKey === 'mapBounds') {
      if (mapBoundsValue) {
        combinedValues.mapBounds = mapBoundsValue
      } else if (current) {
        combinedValues.mapBounds = current
      } else {
        delete combinedValues.mapBounds
      }
    } else if (paramKey === 'mapExpanded') {
      if (mapExpandedValue) {
        combinedValues.mapExpanded = mapExpandedValue
      } else if (current) {
        combinedValues.mapExpanded = current
      } else {
        delete combinedValues.mapExpanded
      }
    } else if (paramKey === 'mapZoom') {
      if (mapZoomValue) {
        combinedValues.mapZoom = mapZoomValue
      } else if (current) {
        combinedValues.mapZoom = current
      } else {
        delete combinedValues.mapZoom
      }
    } else if (paramKey === 'radius') {
      if (radiusValue) {
        combinedValues.radius = radiusValue
      } else if (current) {
        combinedValues.radius = current
      } else {
        delete combinedValues.radius
      }
    } else if (paramKey === 'sort') {
      if (sortValue) {
        combinedValues.sort = sortValue
      } else if (current) {
        combinedValues.sort = current
      } else {
        delete combinedValues.sort
      }
    } else if (Object.keys(initialFilters).includes(paramKey)) {
      if (isEmpty(filterValues)) {
        if (current) {
          combinedValues[paramKey] = current
        } else {
          delete combinedValues[paramKey]
        }
      } else {
        const filter = !isEmpty(filterValues) ? filterValues[paramKey] : false
        if (filter) {
          combinedValues[paramKey] = filter
        } else {
          delete combinedValues[paramKey]
        }
      }
    } else {
      if (override) {
        combinedValues[paramKey] = override
      } else if (current) {
        combinedValues[paramKey] = current
      }
    }
  })

  return combinedValues
}
