import { useEffect } from 'react'
import type { User } from 'firebase/auth'
import Cookies from 'js-cookie'
import TagManager from 'react-gtm-module'
import { env, Environment, isProdEnv, lucencyIdFromCookie } from 'src/utils'

const gtmEnvironmentConfig = {
  [Environment.INT]: {
    auth: 'Bd_pLvyz8k5ej76HqITnew',
    preview: 'env-57',
  },
  [Environment.STG]: {
    auth: 'J2Xm9Hq82g7xHeB1QfygKA',
    preview: 'env-58',
  },
  [Environment.PROD]: {
    auth: 'v1h6khYEEr4r4hEDnYevjQ',
    preview: 'env-1',
  },
}

const tagManagerArgs = {
  gtmId: 'GTM-KSQMJLV',
  ...gtmEnvironmentConfig[env],
}

const useInitializeTagManager = (user: User) => {
  const { uid: userId } = user ?? {}
  const uid = userId || lucencyIdFromCookie
  useEffect(() => {
    TagManager.initialize(tagManagerArgs)

    if (uid) {
      TagManager.dataLayer({
        dataLayer: { i_u_id: uid, vwoUuid: Cookies.get('_vwo_uuid') },
      })
      if (!import.meta.env.IS_LOCAL_ENV)
        pushDataToDataLayer('initialize_user', {
          user: user ?? { uid },
        })
    }
  }, [uid])
}

const pushDataToDataLayer = (
  eventName: string,
  data: Record<string, unknown> = {}
) => {
  const payload = {
    ...data,
    event: eventName,
  }
  TagManager.dataLayer({
    dataLayer: payload,
  })

  const DATA_LAYER_LOG_EVENTS_PAYLOAD =
    localStorage?.getItem('datalayer') === 'true'

  if (!isProdEnv && DATA_LAYER_LOG_EVENTS_PAYLOAD)
    console.info('[pushDataToDataLayer]', payload)
}

export { useInitializeTagManager, pushDataToDataLayer }
