import { useCallback, useRef, useEffect } from 'react'
import type { AutocompleteProps } from '@travelpass/design-system'
import {
  Autocomplete,
  Icon,
  IconButton,
  KeyCode,
} from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import type {
  Suggestion,
  HookArgs as PlacesAutocompleteOptions,
} from 'use-places-autocomplete'
import type { GeocoderType } from 'src/constants/user'
import { initialGeocoder } from 'src/constants/user'
import { GeocoderOption } from './GeocoderOption'
import { constructGeocoderOverride } from './geocoderUtils'
import { useGeocoderSearch } from './useGeocoderSearch'

const SearchIcon = () => (
  <span className='c-new-forest'>
    <Icon name='search' />
  </span>
)

interface GeocoderProps
  extends Pick<
    AutocompleteProps,
    | 'id'
    | 'isInvalid'
    | 'placeholder'
    | 'onClick'
    | 'isDisabled'
    | 'name'
    | 'onBlur'
    | 'onFocus'
    | 'required'
    | 'fullWidth'
    | 'slotBefore'
  > {
  checkTypes?: boolean
  label?: string
  focusOnInput?: boolean
  geocoder: GeocoderType
  helperText?: string
  errorText?: string
  listboxPosition?: AutocompleteProps['listboxPosition']
  onResult: (result: GeocoderType, rawResult?: {} | undefined) => void
  onClear?: VoidFunction
  config?: PlacesAutocompleteOptions
}

const Geocoder = ({
  checkTypes = false,
  focusOnInput = true,
  geocoder = {} as GeocoderType,
  onResult,
  onClear,
  config,
  slotBefore = <SearchIcon />,
  ...props
}: GeocoderProps) => {
  const defaultValue = geocoder?.placeName ?? ''
  const { ready, suggestions, value, setValue, clearSuggestions } =
    useGeocoderSearch({
      defaultValue,
      ...config,
    })
  const autocompleteRef = useRef<HTMLInputElement>(null)

  const { data: options } = suggestions ?? {}
  const showGeocoderOptions = value.trim() !== '' && !!options?.length

  const onKeyDown = event => {
    if (event.key === KeyCode.ESC) {
      clearSuggestions()
    }
    if (event.key === KeyCode.ENTER) {
      // prevent default to stop accidental form submit when user selects option with ENTER key
      event.preventDefault()

      if (showGeocoderOptions) onOptionSelect(options?.[0])
    }
  }

  const onChange = useCallback(
    debounce(e => {
      const value = e.target.value
      if (value === '') {
        onResult(initialGeocoder)
      }
      setValue(value, true)
    }, 300),
    []
  )

  const onOptionSelect = (selectedSuggestion: Suggestion) => {
    const address = selectedSuggestion.description
    autocompleteRef.current.value = address
    clearSuggestions()
    getGeocode({ address }).then(data => {
      const { place_id, types, address_components, geometry } = data?.[0]
      const { lat, lng } = getLatLng(data?.[0])
      const currentGeocoder = {
        addressComponents: address_components,
        center: [lat, lng],
        placeName: address,
        types,
        viewport: geometry?.viewport,
      }

      if (!checkTypes || types.includes('lodging'))
        currentGeocoder['placeId'] = place_id

      const constructedGeocoder = constructGeocoderOverride(currentGeocoder)

      onResult(constructedGeocoder, {
        place_id,
        types,
        address_components,
        geometry,
      })
    })
  }

  const onClearHandler = () => {
    setValue('', true)
    autocompleteRef.current.value = ''
    autocompleteRef.current?.focus() // return focus to input
    onClear?.()
    onResult(initialGeocoder)
  }

  const slotAfter = autocompleteRef.current?.value?.trim() !== '' && (
    <IconButton icon='clear' onClick={onClearHandler} />
  )

  useEffect(() => {
    setTimeout(() => {
      if (focusOnInput) autocompleteRef?.current?.focus()
    }, 300)
  }, [])

  useEffect(() => {
    // keep input value in sync with current geocoder placeName
    if (autocompleteRef.current) {
      autocompleteRef.current.value = geocoder?.placeName
    }
  }, [geocoder])

  return (
    <Autocomplete
      {...props}
      autoExpand
      autoComplete='off'
      defaultValue={defaultValue}
      isDisabled={!ready || props.isDisabled}
      ref={autocompleteRef}
      slotAfter={slotAfter}
      slotBefore={slotBefore}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onOptionSelect={onOptionSelect}
    >
      {showGeocoderOptions &&
        options.map(option => (
          <GeocoderOption key={option.place_id} option={option} />
        ))}
    </Autocomplete>
  )
}

export { Geocoder }
export type { GeocoderProps }
