import { useState } from 'react'
import { Icon } from '@travelpass/design-system'
import { HotelPriceSummaryRow } from './HotelPriceSummaryRow/HotelPriceSummaryRow'
import type { HotelPriceSummaryProps } from './hotelPriceSummaryConstants'
import { HotelTaxesAndFeesModal } from '../HotelPolicies'

interface HotelPriceSummaryTaxesProps {
  surcharges?: HotelPriceSummaryProps['surcharges']
  surchargeTotal: HotelPriceSummaryProps['surchargeTotal']
}

export const HotelPriceSummaryTaxes = ({
  surchargeTotal,
  surcharges,
}: HotelPriceSummaryTaxesProps) => {
  const [isHotelTaxesAndFeesModalOpen, setIsHotelTaxesAndFeesModalOpen] =
    useState(false)

  return (
    <>
      {isHotelTaxesAndFeesModalOpen && (
        <HotelTaxesAndFeesModal
          surcharges={surcharges}
          onClose={() => setIsHotelTaxesAndFeesModalOpen(false)}
        />
      )}
      <div
        className='flex flex-row items-center gap-1'
        data-testid='HotelPriceSummaryTaxes'
      >
        {
          /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
          <div
            className='c-newForest transition-ease-linear flex cursor-pointer flex-row transition-opacity duration-200 hover:opacity-70 active:opacity-70'
            data-testid='taxes-and-fees-tooltip'
            onClick={() => setIsHotelTaxesAndFeesModalOpen(true)}
          >
            <Icon name='info' size='small' />
          </div>
        }
        <div className='flex-grow-1'>
          <HotelPriceSummaryRow title='Taxes & Fees:' value={surchargeTotal} />
        </div>
      </div>
    </>
  )
}
