import dayjs from 'dayjs'
import type { FilterBy, SortBy } from 'src/__generated__/graphql'
import { hotelResultsPath } from 'src/constants'
import {
  validateDecodedSearchParams,
  encodeSearchParams,
  combineAndOverride,
} from './searchParamsUtils'
import type { hotelResultsUrlBasics } from './types'

interface generateHotelResultsUrlArgs extends hotelResultsUrlBasics {
  filters?: FilterBy
  placeCountry?: string
  placeId?: string
  placeShortName?: string
  placeType?: string
  mapBounds?: string
  mapExpanded?: boolean
  mapZoom?: boolean | number
  radius?: string
  sort?: SortBy
  generateDefaultDates?: boolean
  openOnMap?: boolean
}

/**
 * Everything is optional.
 * Call this function with no arguments and the function
 * returns a url to the default location, with default
 * dates, and guests
 *
 * - See initialGeocoderLatitude, initialGeocoderLongitude,
 * and initialGeocoderPlaceName.
 *
 * Pass in a valid latitude, longitude and location name to
 * generate a URL that will display hotels for that location.
 */
export const generateHotelResultsUrl = ({
  latitude,
  longitude,
  location,
  arrival,
  departure,
  adults,
  kids,
  filters,
  placeCountry,
  placeId,
  placeShortName,
  placeType,
  mapBounds,
  mapExpanded,
  mapZoom,
  radius,
  sort,
  generateDefaultDates = true,
  openOnMap = false,
}: generateHotelResultsUrlArgs): string => {
  let {
    arrival: validatedArrival,
    customerReviewScore,
    departure: validatedDeparture,
    priceRange,
    starRating,
    ...validValues
  } = validateDecodedSearchParams({
    latitude,
    longitude,
    location,
    arrival,
    departure,
    adults,
    kids,
    placeCountry,
    placeId,
    placeShortName,
    placeType,
    mapBounds,
    mapExpanded,
    mapZoom,
    radius,
    sort,
    ...filters,
  })

  if (!generateDefaultDates) {
    validatedArrival = arrival ? dayjs(arrival) : null
    validatedDeparture = departure ? dayjs(departure) : null
  }

  const encodedSearchParams = encodeSearchParams({
    arrival: validatedArrival,
    departure: validatedDeparture,
    ...validValues,
    ...customerReviewScore,
    ...priceRange,
    ...starRating,
  })

  return `${hotelResultsPath}${openOnMap ? '/map' : ''}?${encodedSearchParams}`
}

interface updateHotelResultsUrlArgs {
  searchParams: URLSearchParams
  filterValues?: FilterBy
  mapBoundsValue?: string
  mapExpandedValue?: boolean
  mapZoomValue?: boolean | number
  placeCountryValue?: string
  placeIdValue?: string
  placeShortNameValue?: string
  placeTypeValue?: string
  radiusValue?: string
  sortValue?: SortBy
  overrides?: hotelResultsUrlBasics
  openOnMap?: boolean
}

export const updateHotelResultsUrl = ({
  searchParams,
  filterValues,
  mapBoundsValue,
  mapExpandedValue,
  mapZoomValue,
  sortValue,
  placeCountryValue,
  placeIdValue,
  placeShortNameValue,
  placeTypeValue,
  radiusValue,
  overrides = {},
  openOnMap = false,
}: updateHotelResultsUrlArgs) => {
  const {
    adults,
    arrival,
    departure,
    kids,
    placeCountry,
    placeId,
    latitude,
    longitude,
    location,
    mapBounds,
    mapExpanded,
    mapZoom,
    radius,
    sort,
    ...filters
  } = combineAndOverride({
    searchParams,
    filterValues,
    placeCountryValue,
    placeIdValue,
    placeShortNameValue,
    placeTypeValue,
    mapBoundsValue,
    mapExpandedValue,
    mapZoomValue,
    radiusValue,
    sortValue,
    overrides,
  })

  return generateHotelResultsUrl({
    adults,
    arrival,
    departure,
    kids,
    latitude,
    longitude,
    location,
    mapBounds,
    mapExpanded,
    mapZoom,
    openOnMap,
    placeCountry,
    placeId,
    radius,
    sort,
    filters,
  })
}
