import type { GeocoderType } from 'src/constants/user'
import { destructureLocation } from 'src/pages/experiences/results/common/hooks/useExperienceResultsQuery'
import { geocoderOverrides } from './geocoderConstants'

const constructGeocoderOverride = (geocoder: GeocoderType) => {
  geocoderOverrides.filter(({ center, placeName }) => {
    if (placeName === geocoder?.placeName) {
      geocoder.center = center
    }
  })

  return geocoder
}

const constructCityAndState = (
  location: string
): { cityAndState: string; city: string; state: string } => {
  const { city, state } = destructureLocation(location)
  const sanitizedState = state?.split(' ')[0]
  if (city && state) {
    return {
      cityAndState: city.concat(', ').concat(sanitizedState),
      city,
      state: sanitizedState,
    }
  } else if (city) {
    return { cityAndState: city, city, state: city }
  } else {
    return { cityAndState: '', city: '', state: '' }
  }
}

export { constructGeocoderOverride, constructCityAndState }
