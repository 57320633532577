import type { ReactNode } from 'react'
import classNames from 'classnames'

// 96px added to account for padding
const CONTAINER_SIZES = {
  medium: '886px',
  large: '1296px',
  none: '100%',
}

type PageLayoutContainerSize = keyof typeof CONTAINER_SIZES

interface PageLayoutContainerProps {
  children: ReactNode
  size?: PageLayoutContainerSize
  includeTopPadding?: boolean
}

export const PageLayoutContainer = ({
  children,
  size = 'large',
  includeTopPadding = false,
}: PageLayoutContainerProps) => (
  <div
    className={classNames('mx-auto my-0 w-full px-2 md:px-6 lg:px-12', {
      'lg:pt-90px pt-16': includeTopPadding,
      'max-w-full': size === 'none',
      'max-w-886px': size === 'medium',
      'max-w-324': size === 'large',
    })}
  >
    {children}
  </div>
)
