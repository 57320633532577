import { useState } from 'react'
import { Carousel, EmptyState } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { isMobile } from 'react-device-detect'
import { ProductCardHeroImage } from './ProductCardHeroImage'
import type { ProductCardData } from './types'

interface ProductCardHeroProps {
  productCardData: ProductCardData
  onCarouselDragEnd(): void
  onCarouselDragStart(): void
}

export const ProductCardHero = ({
  productCardData,
  onCarouselDragEnd,
  onCarouselDragStart,
}: ProductCardHeroProps) => {
  const { images, name, route, isCollected } = productCardData ?? {}
  const [hasFirstImageLoaded, setHasFirstImageLoaded] = useState(false)
  const [imageLinks, setImageLinks] = useState(images)

  const onError = (index: number) => {
    const newImageLinks = imageLinks?.filter((_, i) => i !== index)

    setImageLinks(newImageLinks)
  }

  const onLoad = () => {
    if (!hasFirstImageLoaded) setHasFirstImageLoaded(true)
  }

  const onImageClick = () => window?.open(route)

  return (
    <div className={homeProductHeroCss}>
      <h3 className='sr-only'>Photo gallery for {name}</h3>
      {isEmpty(imageLinks) ? (
        <div className='h-41 flex w-full flex-col items-center justify-center'>
          <div className='w-200px'>
            <EmptyState name='noImages' />
          </div>
          <button className={productCardHeroButtonCss} onClick={onImageClick}>
            <span className='sr-only'>Error loading image of {name}</span>
          </button>
        </div>
      ) : (
        <Carousel
          isLight
          afterChange={onCarouselDragEnd}
          beforeChange={onCarouselDragStart}
          borderRadius={14}
          isDisabled={!hasFirstImageLoaded}
          showArrowsOnHover={!isMobile}
          size='small'
        >
          {imageLinks?.map((imageLink, index) => (
            <ProductCardHeroImage
              key={imageLink}
              hasFirstImageLoaded={hasFirstImageLoaded}
              imageLink={imageLink}
              index={index}
              name={name}
              onError={() => onError(index)}
              onImageClick={onImageClick}
              onLoad={onLoad}
            />
          ))}
        </Carousel>
      )}
    </div>
  )
}

const homeProductHeroCss = 'z-2'

const productCardHeroButtonCss =
  'z-2 bg-transparent w-full h-full cursor-pointer block inset-0 overflow-hidden absolute whitespace-nowrap border-none'
