import { Skeleton } from '@travelpass/design-system'
import { HotelCancellationBadgeLoading } from '../HotelPolicies'

interface ProductCardLoadingProps {
  displayFrom?: boolean
  className?: string
}
export const ProductCardLoading = ({
  displayFrom,
  className = '',
}: ProductCardLoadingProps) => (
  <div
    className={`${productCardLoadingCardCss} ${className}`}
    data-testid='product-card-loading-skeleton'
  >
    <h3 className='sr-only'>product loading card</h3>
    {/* image */}
    <div className='h-164px'>
      <Skeleton variant='rounded' />
    </div>
    {/* title, address, and reviews */}
    <div className={productCardLoadingTopContainerCss}>
      <div className='h-32px'>
        <Skeleton variant='rounded' />
      </div>
      <div className='h-16px'>
        <Skeleton variant='rounded' />
      </div>
      <div className='h-16px'>
        <Skeleton variant='rounded' />
      </div>
    </div>
    {/* from, price, subtotal / per */}
    <div className={productCardLoadingBottomContainerCss}>
      <div className={productCardLoadingBottomSubContainerCss}>
        {/* from skeleton */}
        {displayFrom && (
          <div className='h-16px w-100px'>
            <Skeleton variant='rounded' />
          </div>
        )}
        <div className='h-16px w-100px'>
          <Skeleton variant='rounded' />
        </div>
        <div className='h-16px w-100px'>
          <Skeleton variant='rounded' />
        </div>
      </div>
      {/* cancellation badge */}
      <div className={productCardLoadingBottomSubContainerCss}>
        <HotelCancellationBadgeLoading />
      </div>
    </div>
  </div>
)

const productCardLoadingCardCss =
  'flex flex-col relative bg-white rounded-20px gap-3 shadow-1 text-inherit px-3 pt-3 pb-4 w-full min-w-64'

const productCardLoadingTopContainerCss = 'flex flex-col gap-1 min-h-21'

const productCardLoadingBottomContainerCss =
  'flex flex-row justify-between min-h-8 items-end mb-4'

const productCardLoadingBottomSubContainerCss =
  'flex flex-col gap-1 justify-end'
