import { Skeleton } from '@travelpass/design-system'
import {
  bookingDetailsSectionHeaderCss,
  bookingDetailsSectionHeaderDividerCss,
} from './bookingDetailsSectionHeaderStyles'

export const BookingDetailsSectionHeaderLoading = () => (
  <div className={bookingDetailsSectionHeaderCss}>
    <div className='md:w-1/2'>
      <div className='h-14px'>
        <Skeleton />
      </div>
    </div>
    <div className={bookingDetailsSectionHeaderDividerCss}>
      <div className='h-px'>
        <Skeleton />
      </div>
    </div>
  </div>
)
