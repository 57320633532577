const bookingDetailsSectionHeaderCss = 'flex flex-col gap-4 pb-9'

const bookingDetailsSectionHeaderDividerCss = 'md:mx--6'

const bookingDetailsSectionHeaderTitleCss =
  'color-black text-caption-1 uppercase'

export {
  bookingDetailsSectionHeaderCss,
  bookingDetailsSectionHeaderDividerCss,
  bookingDetailsSectionHeaderTitleCss,
}
