import classNames from 'classnames'

interface CollectedItemTipProps {
  label: 'Experience' | 'Hotel' | 'Guide' | 'Event'
}

export const CollectedItemTip = ({ label }: CollectedItemTipProps) => (
  <span
    className={classNames(
      'type-body-1-medium border-1 flex flex-row items-center rounded-full border-solid px-3 py-1',
      {
        'bg-blueLight border-blueMedium c-blueMedium': label == 'Experience',
        'bg-orangeLight border-orangeMedium c-orangeMedium': label == 'Event',
        'bg-purpleLight border-purpleMedium c-purpleMedium': label == 'Hotel',
        'bg-valleyLight border-forestLight c-forestLight': label == 'Guide',
      }
    )}
  >
    {label}
  </span>
)
