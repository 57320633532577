import type { ReactNode } from 'react'
import { Avatar } from '@travelpass/design-system'
import { profilePath } from 'src/constants'
import { getGuideOwnerImage, getGuideOwnerName } from 'src/utils'

type InteractionType = 'button' | 'link' | 'none'
type OwnerBadgeSize = 'sm' | 'lg'

interface OwnerBadgeProps {
  accountHandle: string
  border?: 'lg' | 'md' | 'sm' | 'none'
  classNames?: {
    avatarStyles?: string
    containerStyles?: string
    ownerNameStyles?: string
    supplementalContentStyles?: string
    textStyles?: string
  }
  displayName: string
  interactivity?: InteractionType
  profileImageUrl: string
  size?: OwnerBadgeSize
  supplementalContent?: ReactNode | string
  onClick?: VoidFunction
  /**@desc onClickOwnerProfile exists to handle the profile modal opening and closing logic. Use onClick for everything else, e.g. Google Tag Manager events. */
  onClickOwnerProfile?: (accountHandle: string) => void
}

export const OwnerBadge = ({
  accountHandle,
  border = 'sm',
  classNames,
  displayName,
  interactivity = 'button',
  profileImageUrl,
  size = 'sm',
  supplementalContent,
  onClick,
  onClickOwnerProfile,
}: OwnerBadgeProps) => {
  const ownerName = getGuideOwnerName({
    accountHandle,
    displayName,
  })
  const profileUrl = `${window.location.origin}${profilePath}/${accountHandle}`

  {
    switch (interactivity) {
      case 'link':
        return (
          <div
            className={`flex flex-row items-center gap-3 ${classNames?.containerStyles || 'w-fit'}`}
          >
            <a
              className={`transition-opacity-200 pointer-events-auto cursor-pointer ease-linear hover:opacity-50 active:opacity-50 ${classNames?.avatarStyles || ''}`}
              href={profileUrl}
              rel='noreferrer'
              target='_BLANK'
              onClick={onClick}
            >
              <Avatar
                border={border || size}
                size={size}
                src={getGuideOwnerImage(profileImageUrl)}
              />
            </a>
            <div className={classNames?.textStyles || ''}>
              <a
                className={`decoration-none transition-opacity-200 pointer-events-auto cursor-pointer ease-linear hover:opacity-50 active:opacity-50 md:truncate ${classNames?.ownerNameStyles || 'type-body-1-medium'}`}
                href={profileUrl}
                rel='noreferrer'
                target='_BLANK'
                onClick={onClick}
              >
                {ownerName}
              </a>
              <div className={classNames?.supplementalContentStyles || ''}>
                {supplementalContent}
              </div>
            </div>
          </div>
        )
      case 'button':
        return (
          <div
            className={`flex flex-row items-center gap-3 ${classNames?.containerStyles || 'w-fit'}`}
            role='button'
            tabIndex={0}
            onClick={() => {
              onClick?.()
              onClickOwnerProfile?.(accountHandle)
            }}
          >
            <section
              className={`transition-opacity-200 pointer-events-auto cursor-pointer ease-linear hover:opacity-50 active:opacity-50 ${classNames?.avatarStyles || ''}`}
            >
              <Avatar
                border={border || size}
                size={size}
                src={getGuideOwnerImage(profileImageUrl)}
              />
            </section>
            <section className={classNames?.textStyles || ''}>
              <p
                className={`transition-opacity-200 pointer-events-auto cursor-pointer ease-linear hover:opacity-50 active:opacity-50 ${classNames?.ownerNameStyles || 'type-body-1-medium'}`}
              >
                {ownerName}
              </p>
              <div className={classNames?.supplementalContentStyles || ''}>
                {supplementalContent}
              </div>
            </section>
          </div>
        )
      case 'none':
        return (
          <div
            className={`flex flex-row items-center gap-3 ${classNames?.containerStyles || 'w-fit'}`}
          >
            <section className={classNames?.avatarStyles || ''}>
              <Avatar
                border={border || size}
                size={size}
                src={getGuideOwnerImage(profileImageUrl)}
              />
            </section>
            <section className={classNames?.textStyles || ''}>
              <p
                className={classNames?.ownerNameStyles || 'type-body-1-medium'}
              >
                {ownerName}
              </p>
              <div className={classNames?.supplementalContentStyles || ''}>
                {supplementalContent}
              </div>
            </section>
          </div>
        )
      default:
        return null
    }
  }
}
