import { Skeleton } from '@travelpass/design-system'

import {
  occupancySummaryCss,
  occupancySummaryItemCss,
} from './occupancySummaryStyles'

export const OccupancySummaryLoading = () => {
  const constructedItems = () =>
    Array(3)
      .fill(0)
      .map((_, index) => (
        <div
          key={`occupancy-summary-item-${index}`}
          className={occupancySummaryItemCss}
        >
          <div className='h-24px w-24px'>
            <Skeleton />
          </div>
          <div className='h-16px w-50px md:h-18px'>
            <Skeleton />
          </div>
        </div>
      ))

  return <div className={occupancySummaryCss}>{constructedItems()}</div>
}
