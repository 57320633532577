import type { ChangeEvent } from 'react'
import { Fragment } from 'react'
import { Input } from '@travelpass/design-system'
import type {
  ExperienceFilters,
  FilterBy as HotelFilter,
} from 'src/__generated__/graphql'
import { isLastIndex } from 'src/utils'
import { ResultsFiltersSection } from './ResultsFiltersSection'

interface ResultsFiltersPriceProps {
  filters: HotelFilter | ExperienceFilters
  title?: string
  onChange(updatedFilters: HotelFilter | ExperienceFilters): void
}

export const ResultsFiltersPrice = ({
  filters,
  title = 'Price Per Night',
  onChange,
}: ResultsFiltersPriceProps) => {
  const { priceRange } = filters ?? {}
  const { maxPrice, minPrice } = priceRange ?? {}
  const constructedPriceRange = [
    {
      label: 'Min Price',
      name: 'minPrice',
      value: minPrice,
    },
    {
      label: 'Max Price',
      name: 'maxPrice',
      value: maxPrice,
    },
  ]

  const getPrice = (value: string) => {
    const valueAsNumber = Number(value)
    if (value.trim() === '' || isNaN(valueAsNumber)) return null

    return valueAsNumber
  }

  const onPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.currentTarget ?? {}
    const updatedPriceRange = priceRange ?? {}
    updatedPriceRange[name] = getPrice(value)

    onChange({
      ...filters,
      priceRange: updatedPriceRange,
    })
  }

  return (
    <ResultsFiltersSection title={title}>
      <div className='flex items-center sm:gap-4 md:gap-4 lg:gap-8'>
        {constructedPriceRange.map(({ label, name, value }, index) => (
          <Fragment key={name}>
            <div className='sm:w-[calc(50%-24px)] md:w-[calc(50%-24px)] lg:w-[calc(50%-40px)]'>
              <Input
                fullWidth
                label={label}
                maxLength={6}
                name={name}
                placeholder='Select'
                value={value?.toString() ?? ''}
                onChange={onPriceChange}
              />
            </div>
            {!isLastIndex(constructedPriceRange, index) && (
              <div className='bg-grey600 mt-4 h-[1px] min-w-4' />
            )}
          </Fragment>
        ))}
      </div>
    </ResultsFiltersSection>
  )
}
