import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

const getHoursWithMinutes = (minutes: number): string | null => {
  const hoursText = minutes >= 120 ? 'hours' : 'hour'
  const minutesText = minutes > 1 ? 'minutes' : 'minute'
  const showHours = minutes > 59
  const showMinutes = minutes % 60 !== 0
  const dayjsHourFormat = showHours ? `H [${hoursText}]` : ''
  const dayjsMinutesFormat = showMinutes ? `m [${minutesText}]` : ''
  const spacing = showHours && showMinutes ? ' ' : ''
  const hasError = isNaN(parseInt(minutes?.toString()))

  if (hasError) return null

  return dayjs
    .duration(minutes, 'minutes')
    .format(`${dayjsHourFormat}${spacing}${dayjsMinutesFormat}`)
}

// TODO: BE should return this
const getTimeFromMilitaryTimeString = (
  militaryTimeString: string,
  formatTemplate = 'hh:mm A'
): string | null => {
  if (!militaryTimeString) return null

  return dayjs(militaryTimeString, 'HH:mm:ss').format(formatTemplate)
}

export { getHoursWithMinutes, getTimeFromMilitaryTimeString }
