import { RefundType } from 'src/__generated__/graphql'
import { constructDate, constructTime } from './hotelCancellationTimelineUtils'
import type { HotelCancellationTimelineItem } from './types'

const { None, Full, Partial } = RefundType

interface HotelCancellationTimelineProps {
  fullyRefundableUntil?: string
  nonRefundableAfter?: string
  partiallyRefundableUntil?: string
}

const HotelCancellationTimeline = ({
  fullyRefundableUntil,
  nonRefundableAfter,
  partiallyRefundableUntil,
}: HotelCancellationTimelineProps) => {
  const showHotelCancellationTimeline =
    !!fullyRefundableUntil || !!partiallyRefundableUntil

  const timelineItems: HotelCancellationTimelineItem[] = [
    {
      backgroundColor: 'bg-newForest',
      color: 'c-newForest',
      date: fullyRefundableUntil,
      isHidden: !fullyRefundableUntil,
      title: 'Free cancellation with full refund',
      type: Full,
    },
    {
      backgroundColor: 'bg-grey-800',
      color: 'c-grey-800',
      date: partiallyRefundableUntil,
      isHidden: !partiallyRefundableUntil,
      title: 'Partial cancellation (see below for details)',
      type: Partial,
    },
    {
      backgroundColor: 'bg-redMedium',
      color: 'c-redMedium',
      date: nonRefundableAfter,
      isHidden: !nonRefundableAfter,
      title: 'Non-refundable after this time',
      type: None,
    },
  ]

  return (
    showHotelCancellationTimeline && (
      <div className='relative' data-testid='HotelCancellationTimeline'>
        <div className='bg-grey400 left-15px top-3px w-2px absolute sm:bottom-10 md:bottom-11' />
        <ul className='relative m-0 list-none space-y-6 p-0'>
          {timelineItems.map(
            ({ backgroundColor, color, date, isHidden, title, type }) =>
              !isHidden && (
                <li
                  key={title}
                  className='space-y-2px type-body-2 pl-19 relative m-0'
                >
                  <div
                    className={`${backgroundColor} rounded-3 left-10px top-3px absolute h-3 w-3`}
                  />
                  <p className='font-700'>{constructDate({ date })}</p>
                  <p>{constructTime({ date, type })}</p>
                  <p className={color}>{title}</p>
                </li>
              )
          )}
        </ul>
      </div>
    )
  )
}

export { HotelCancellationTimeline }
export type { HotelCancellationTimelineProps }
