import { Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'
import type { HotelCancellationBadgeProps } from './HotelCancellationBadge'

interface HotelCancellationBadgeLoadingProps {
  isRow?: HotelCancellationBadgeProps['isRow']
  showContent?: boolean
}

export const HotelCancellationBadgeLoading = ({
  isRow,
  showContent = false,
}: HotelCancellationBadgeLoadingProps) => (
  <div
    className={classNames('inline-flex sm:flex-col lg:items-center', {
      'gap-2 lg:flex-col': !isRow,
      'gap-6px lg:flex-row': isRow,
    })}
  >
    <div className='w-140px h-26px'>
      <Skeleton variant='rounded' />
    </div>
    {showContent && (
      <div className='h-18px w-100px'>
        <Skeleton />
      </div>
    )}
  </div>
)
