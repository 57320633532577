const bookingDetailsContactContentCss = 'flex flex-col gap-2 md:max-w-70'

const bookingDetailsContactCss = 'flex flex-col gap-4'

const bookingDetailsContactSubtitleCss = 'color-grey-800 text-body2'

const bookingDetailsContactTitleCss = 'text-h6'

export {
  bookingDetailsContactContentCss,
  bookingDetailsContactCss,
  bookingDetailsContactSubtitleCss,
  bookingDetailsContactTitleCss,
}
