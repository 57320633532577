import { Carousel } from '@travelpass/design-system'
import type { CarouselProps } from '@travelpass/design-system'
import { DetailsHeroImage } from './DetailsHeroImage'

interface DetailsHeroCarouselProps {
  altPrefix: string
  height: string
  hideArrows?: CarouselProps['hideArrows']
  hideDots?: CarouselProps['hideDots']
  images: string[]
  keyBoardControl?: CarouselProps['keyBoardControl']
  slideIndex?: CarouselProps['slideIndex']
}

export const DetailsHeroCarousel = ({
  altPrefix,
  height,
  hideArrows = false,
  hideDots = false,
  images,
  keyBoardControl,
  slideIndex,
}: DetailsHeroCarouselProps) => {
  const detailsHeroImages = hideDots ? images : images.slice(0, 5) // TODO: fix dot pagination
  const detailsHeroCarousel =
    detailsHeroImages?.map((image, index) => {
      const constructedAlt = `${altPrefix} ${index + 1}`

      return (
        <div key={constructedAlt} className={height}>
          <DetailsHeroImage alt={constructedAlt} src={image} />
        </div>
      )
    }) ?? []

  const hasSingleImage = detailsHeroCarousel.length === 1

  return (
    <Carousel
      isLight
      borderRadius={14}
      hideArrows={hasSingleImage || hideArrows}
      hideDots={hideDots || hasSingleImage}
      keyBoardControl={keyBoardControl}
      slideIndex={slideIndex}
    >
      {detailsHeroCarousel}
    </Carousel>
  )
}
