import type { Dayjs } from 'dayjs'
import type { ExperienceFilters } from 'src/__generated__/graphql'
import { ExperienceSortBy } from 'src/__generated__/graphql'
import {
  initialArrivalDate,
  initialDepartureDate,
  initialGeocoderLatitude,
  initialGeocoderLongitude,
  initialGeocoderPlaceName,
} from './user'

const initialExpSize = 72
const initialExpFilters = {
  customerReviewScore: {
    minScore: '1.0',
    maxScore: '5.0',
  },
  duration: {
    maxHours: 24,
    minHours: 0,
  },
  timeOfDay: [],
  priceRange: {
    minPrice: 0,
    maxPrice: 1000,
  },
  tags: [],
}
const initialExpSort = ExperienceSortBy.Recommended

interface allExpSearchParamsDecoded extends ExperienceFilters {
  arrival: Dayjs
  departure: Dayjs
  latitude: number
  location: string
  longitude: number
  placeId?: string
  sort?: ExperienceSortBy
}

const defaultExperiencesResultsParams: allExpSearchParamsDecoded = {
  ...initialExpFilters,
  arrival: initialArrivalDate,
  departure: initialDepartureDate,
  latitude: initialGeocoderLatitude,
  location: initialGeocoderPlaceName,
  longitude: initialGeocoderLongitude,
  placeId: '',
  sort: initialExpSort,
}

interface allExpSearchParamsEncoded {
  [key: string]: string
  arrival: string
  customerReviewScore: string
  departure: string
  duration: string
  latitude: string
  location: string
  longitude: string
  placeId: string
  priceRange: string
  sort: ExperienceSortBy
  tags: string
  timeOfDay: string
}

const defaultExperiencesResultsParamsStrs: allExpSearchParamsEncoded = {
  arrival: initialArrivalDate.format('YYYY-MM-DD'),
  customerReviewScore: JSON.stringify(initialExpFilters.customerReviewScore),
  departure: initialDepartureDate.format('YYYY-MM-DD'),
  duration: JSON.stringify(initialExpFilters.duration),
  latitude: initialGeocoderLatitude.toString(),
  location: initialGeocoderPlaceName,
  longitude: initialGeocoderLongitude.toString(),
  placeId: 'false',
  priceRange: JSON.stringify(initialExpFilters.priceRange),
  sort: initialExpSort,
  tags: JSON.stringify(initialExpFilters.tags),
  timeOfDay: JSON.stringify(initialExpFilters.timeOfDay),
}

interface experienceTagType {
  tagName: string
  tagId: number
}

const adventureTours: experienceTagType = {
  tagName: 'Adventure Tours',
  tagId: 22046,
}

const airportAndHotelTransfers: experienceTagType = {
  tagName: 'Airport & Hotel Transfers',
  tagId: 12044,
}

const attractionsAndMuseums: experienceTagType = {
  tagName: 'Attractions & Museums',
  tagId: 12716,
}

const culturalTours: experienceTagType = {
  tagName: 'Cultural Tours',
  tagId: 12028,
}

const excellentQuality: experienceTagType = {
  tagName: 'Excellent Quality',
  tagId: 21972,
}

const extremeSports: experienceTagType = {
  tagName: 'Extreme Sports',
  tagId: 11923,
}

const familyFriendly: experienceTagType = {
  tagName: 'Family-friendly Shows ',
  tagId: 11917,
}

const fourWheelDriveTours: experienceTagType = {
  tagName: '4WD Tours',
  tagId: 12035,
}

const foodAndDrink: experienceTagType = {
  tagName: 'Food & Drink',
  tagId: 21911,
}

const fullDayTours: experienceTagType = {
  tagName: 'Full-day Tours',
  tagId: 11928,
}

const ghostTours: experienceTagType = {
  tagName: 'Ghost Tours',
  tagId: 12066,
}

const halfDayTours: experienceTagType = {
  tagName: 'Half-day Tours',
  tagId: 11929,
}

const museums: experienceTagType = {
  tagName: 'Museums',
  tagId: 21514,
}

const natureAndWildlife: experienceTagType = {
  tagName: 'Nature & Wildlife',
  tagId: 11903,
}

const nightTours: experienceTagType = {
  tagName: 'Night Tours',
  tagId: 12057,
}

const photoShoots: experienceTagType = {
  tagName: 'Photo Shoots',
  tagId: 21753,
}

const selfGuidedTours: experienceTagType = {
  tagName: 'Self-guided Tours',
  tagId: 12041,
}

const topRated: experienceTagType = { tagName: 'Top Rated', tagId: 22143 }

const walkingTours: experienceTagType = {
  tagName: 'Walking Tours',
  tagId: 12046,
}

const waterTours: experienceTagType = {
  tagName: 'Water Tours',
  tagId: 20255,
}

const experienceTagsAll: experienceTagType[] = [
  adventureTours,
  airportAndHotelTransfers,
  attractionsAndMuseums,
  culturalTours,
  excellentQuality,
  extremeSports,
  familyFriendly,
  foodAndDrink,
  fourWheelDriveTours,
  fullDayTours,
  ghostTours,
  halfDayTours,
  museums,
  natureAndWildlife,
  nightTours,
  photoShoots,
  selfGuidedTours,
  topRated,
  walkingTours,
  waterTours,
]

const experienceTagsPrioritized: experienceTagType[] = [
  adventureTours,
  airportAndHotelTransfers,
  attractionsAndMuseums,
  culturalTours,
  excellentQuality,
  extremeSports,
  familyFriendly,
  foodAndDrink,
  fourWheelDriveTours,
  fullDayTours,
  ghostTours,
  halfDayTours,
  museums,
  natureAndWildlife,
  nightTours,
  photoShoots,
  selfGuidedTours,
  topRated,
  walkingTours,
  waterTours,
]

export type { allExpSearchParamsDecoded }
export type { experienceTagType }
export {
  experienceTagsAll,
  experienceTagsPrioritized,
  defaultExperiencesResultsParams,
  defaultExperiencesResultsParamsStrs,
  initialExpFilters,
  initialExpSize,
  initialExpSort,
}
