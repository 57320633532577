// const convertStringToSpacedAndLowerCased = (string: string): string => {
//   if (string.includes('_')) {
//     return string.split('_').join(' ').toLowerCase()
//   }
//   if (string.includes('-')) {
//     return string.split('-').join(' ').toLowerCase()
//   }
//   return string.toLowerCase()
// }
const convertStringToSpacedAndLowerCased = (string: string): string => {
  const regexp = new RegExp('[-_]')
  if (string.search(regexp) != -1) {
    return string.split(regexp).join(' ').toLowerCase().trim()
  }
  return string.toLowerCase()
}

const capitalizeFirstLetterOfWords = (string: string = '') => {
  const convertedString = convertStringToSpacedAndLowerCased(string)
  return convertedString
    .split(' ')
    .map(char => char.charAt(0).toUpperCase() + char.substring(1))
    .join(' ')
}

const stringToBoolean = (boolStr: string): boolean =>
  String(boolStr).toLowerCase() === 'true'

const stringToNumber = (string: string): number | null => {
  const updatedString = parseFloat(string)

  return isNaN(updatedString) ? null : updatedString
}

function onlyNumbers(inputString: string): string {
  const numbers = inputString.match(/\d+/g) || []
  return numbers.join('')
}

export {
  convertStringToSpacedAndLowerCased,
  capitalizeFirstLetterOfWords,
  stringToBoolean,
  stringToNumber,
  onlyNumbers,
}
