const getUtmParams = (searchParams: URLSearchParams) => {
  const constructedSearchParamsObject =
    Object.fromEntries([...(searchParams ?? [])]) ?? {}

  Object.keys(constructedSearchParamsObject).forEach(key => {
    if (!key.includes('utm_')) {
      delete constructedSearchParamsObject[key]
    }
  })

  return constructedSearchParamsObject
}

const isUrlValid = (url: string) => {
  try {
    new URL(url)
    return true
  } catch (error) {
    return false
  }
}

export { getUtmParams, isUrlValid }
