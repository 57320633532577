export const TOGGLE_ADD_TO_COLLECTIONS_MODAL = 'toggleAddToCollectionsModal'

export const dispatchToggleAddToCollectionsModal = ({
  isModalOpen,
  item,
}: {
  isModalOpen: boolean
  item: AddToItem
}) => {
  const event = new CustomEvent(TOGGLE_ADD_TO_COLLECTIONS_MODAL, {
    detail: { isModalOpen, item },
  })
  window?.dispatchEvent(event)
}
