import type { ReactNode } from 'react'

interface VerticalStepperProps {
  children: ReactNode
}

export const VerticalStepper = ({ children }: VerticalStepperProps) => (
  <div aria-label='progress' className='relative' role='group'>
    <div className='left-7px w-2px md:left-9px absolute bottom-0 top-0 bg-black' />
    <ol className='m-0 list-none p-0'>{children}</ol>
  </div>
)
