import { useEffect } from 'react'
import load from 'load-script'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { googleMapsConfig } from 'src/config/maps'

export const useInitializeGoogleMaps = () => {
  useEffect(() => {
    if (window?.google?.maps) return

    load(
      `https://maps.googleapis.com/maps/api/js?key=${googleMapsConfig}&libraries=places,marker&callback=initializeGeocoder`,
      () => {}
    )
  }, [])

  usePlacesAutocomplete({
    callbackName: 'initializeGeocoder',
  })
}
