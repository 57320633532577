import { Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'
import {
  hotelAmenitiesButtonCss,
  hotelAmenitiesContentCss,
  hotelAmenitiesCss,
  hotelAmenitiesItemCss,
} from './hotelAmenitiesStyles'

export const HotelAmenitiesLoading = () => {
  const constructedText = (
    <div className='h-18px w-15 md:h-5'>
      <Skeleton />
    </div>
  )

  return (
    <div className={hotelAmenitiesCss}>
      <div className='h-15px w-17 md:h-5'>
        <Skeleton />
      </div>
      <div className={hotelAmenitiesContentCss}>
        {Array(6)
          .fill(0)
          .map((_, index) => (
            <div key={index} className={hotelAmenitiesItemCss}>
              <div className='h-6 w-6'>
                <Skeleton />
              </div>
              {constructedText}
            </div>
          ))}
      </div>
      <div className={hotelAmenitiesLoadingButtonCss}>{constructedText}</div>
    </div>
  )
}

const hotelAmenitiesLoadingButtonCss = classNames(
  hotelAmenitiesButtonCss,
  'flex flex-col items-end'
)
