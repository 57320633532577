import { Link } from 'react-router-dom'

const ButtonLinkVariant = {
  forest:
    'text-white bg-forest-light hover:bg-forest ring-forest-light text-shadow-sm',
  valley:
    'text-forest bg-valley ring-black/5 hover:bg-valley-dark text-shadow-sm',
  outline:
    'text-forest bg-transparent border-forest c-forest border-1 border-solid hover:bg-forest hover:text-white ring-forest/70 text-shadow',
  'white-outline':
    'text-white bg-transparent hover:bg-forest border-white border-1 border-solid',
}

export const ButtonLink = props => (
  <Link
    {...props}
    className={`tracking-1px inline-flex h-12 items-center gap-1 rounded-md px-5 py-2.5 text-center font-semibold uppercase no-underline ring-1 ring-inset transition md:px-10 ${ButtonLinkVariant[props?.variant || 'forest']} ${props?.className || ''}`}
  />
)
