import { Skeleton } from '@travelpass/design-system'

export const DetailsOverviewLoading = () => (
  <div className='flex flex-col gap-2 pb-6 md:pb-3'>
    <div className='text-subtitle-2 flex items-center justify-between gap-2'>
      <div className='h-18px w-22 md:h-5'>
        <Skeleton />
      </div>
    </div>
    <div className='h-45px md:h-15'>
      <Skeleton />
    </div>
    <div className='text-right'>
      <div className='h-22px w-80px md:h-6'>
        <Skeleton />
      </div>
    </div>
  </div>
)
