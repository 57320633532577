import classNames from 'classnames'

type ResultsDotVariant = 'primary' | 'secondary'

interface ResultsDotProps {
  label?: string
  variant?: ResultsDotVariant
}

export const ResultsDot = ({ label, variant = 'primary' }: ResultsDotProps) => (
  <div
    className={classNames(
      'c-white absolute flex flex-col items-center justify-center',
      {
        'bg-valley': variant === 'primary',
        'bg-forest': variant === 'secondary',
        'rounded-9px h-4.5 w-4.5 sm:right--.5 sm:top-.5 lg:right--2.5 lg:top-1':
          label,
        'rounded-3px right-3px top-1.5 h-1.5 w-1.5': !label,
      }
    )}
  >
    {!!label && (
      <span className='font-size-3 font-700 line-height-4'>{label}</span>
    )}
  </div>
)
