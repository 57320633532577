import type { ReactNode } from 'react'
import { Divider } from '@travelpass/design-system'
import {
  detailsMinimalHeroContentCss,
  detailsMinimalHeroCss,
  detailsMinimalHeroHeaderCss,
  detailsMinimalHeroImageCss,
  detailsMinimalHeroSubtitleCss,
  detailsMinimalHeroTitleCss,
} from './detailsMinimalHeroStyles'
import { DetailsHeroCarousel } from '../DetailsHeroImages/common'

interface DetailsMinimalHeroProps {
  children?: ReactNode
  images?: string[]
  isExperiences?: boolean
  subtitle?: string
  title: string
  hasDivider?: boolean
}

const DetailsMinimalHero = ({
  children,
  images,
  subtitle,
  title,
  hasDivider = true,
}: DetailsMinimalHeroProps) =>
  title ? (
    <section className={detailsMinimalHeroCss}>
      <div className={detailsMinimalHeroHeaderCss}>
        <DetailsHeroCarousel
          altPrefix={`${title} hero`}
          height={detailsMinimalHeroImageCss}
          images={images ?? []}
        />
        <h1 className={detailsMinimalHeroTitleCss}>{title}</h1>
      </div>
      <div className={detailsMinimalHeroContentCss}>
        {!!subtitle && (
          <p className={detailsMinimalHeroSubtitleCss}>{subtitle}</p>
        )}
        {children}
      </div>
      {hasDivider && (
        <div className='pt-3'>
          <Divider />
        </div>
      )}
    </section>
  ) : null

export { DetailsMinimalHero }
export type { DetailsMinimalHeroProps }
