import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH } from '@travelpass/design-system'

type ImageDimensions = string
type ImageLink = string
type Resolution = string

const formatImageLink = (imageLink: ImageLink) => {
  const updatedImageLink = imageLink?.includes('://')
    ? imageLink.split('://')[1]
    : imageLink

  return `https://${updatedImageLink}`
}

const formatImageLinkForProductCard = (
  imageLink: ImageLink,
  hotelResolution: Resolution = '320x200',
  placesResolution: Resolution = '100'
) => {
  let imgUrl = imageLink

  if (imgUrl?.includes('googleapis')) {
    const [domain, searchParams] = imgUrl.split('?')
    return `${domain}?maxheight=${placesResolution}&${searchParams}`
  }

  if (imgUrl.startsWith('img')) {
    imgUrl = `${imgUrl}/${hotelResolution}`
  }

  if (imgUrl.startsWith('http')) {
    imgUrl = imgUrl.replace('http://', 'https://')
  }

  if (!imgUrl.startsWith('https://')) {
    imgUrl = `https://${imgUrl}`
  }

  return imgUrl
}

const constructImageLink = (imageLink: ImageLink, dimensions = '320x200') => {
  const formattedImageLink = formatImageLink(imageLink)

  if (dimensions.trim() === '') return formattedImageLink

  return `${formattedImageLink}/${dimensions}`
}

// TODO: create a similar util for srcset
const constructImageLinks = (
  imageLinks: ImageLink[],
  dimensions?: ImageDimensions
) =>
  imageLinks?.map(imageLink => constructImageLink(imageLink, dimensions)) ?? []

const getImageSrcSet = ({
  desktop,
  mobile,
}: {
  desktop: string
  mobile: string
}) => {
  if (!desktop && !mobile) return ''

  if (!desktop) return mobile

  if (!mobile) return desktop

  return `${mobile} ${MAX_MOBILE_WIDTH}w, ${desktop} ${MIN_TABLET_WIDTH}w`
}

export {
  constructImageLink,
  constructImageLinks,
  formatImageLink,
  formatImageLinkForProductCard,
  getImageSrcSet,
}
