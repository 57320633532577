import { TimePicker } from './TimePicker'
import { defaultTimeRange, defaultTimeIncrement } from './timeRangeConstants'
import { updateTimeRangeStart, updateTimeRangeEnd } from './timeRangeUtils'
import type { TimeRangeType } from './types'

export const TimeRangePicker = ({
  increment = defaultTimeIncrement,
  timeRange = defaultTimeRange,
  onChange,
}: {
  increment?: number
  timeRange: TimeRangeType
  onChange(times: TimeRangeType): void
}): JSX.Element => {
  const onStartChange = (updatedStartTime: string) => {
    const updatedTimeRange = updateTimeRangeStart({
      updatedStartTime,
      timeRange,
      increment,
    })

    onChange(updatedTimeRange)
  }

  const onEndChange = (updatedEndTime: string) => {
    const updatedTimeRange = updateTimeRangeEnd({
      updatedEndTime,
      timeRange,
      increment,
    })

    onChange(updatedTimeRange)
  }

  return (
    <div className={timeRangePickerCss}>
      <TimePicker
        increment={increment}
        label='Start Time'
        name='startTime'
        value={timeRange?.startTime}
        onChange={onStartChange}
      />
      <TimePicker
        increment={increment}
        label='End Time'
        name='endTime'
        startTime={timeRange?.startTime}
        value={timeRange?.endTime}
        onChange={onEndChange}
      />
    </div>
  )
}

const timeRangePickerCss = 'flex flex-col gap-5 w-full md:flex-row'

export type { TimeRangeType }
