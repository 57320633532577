import { Modal, ModalScrollContents } from '@travelpass/design-system'
import { PrivacyContent } from './PrivacyContent'

export const PrivacyModal = ({ onClose }: { onClose(): void }): JSX.Element => {
  return (
    <Modal size='medium' title='Privacy Policy' onDismiss={onClose}>
      <ModalScrollContents>
        <PrivacyContent />
      </ModalScrollContents>
    </Modal>
  )
}
