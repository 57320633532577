import { useState } from 'react'
import { Icon, Input, Button } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import { emailValidationRegex } from 'src/constants/validation'
import type { SearchBookingApolloProps } from './SearchBookingApollo'
import { SearchBookingApollo } from './SearchBookingApollo'
import {
  searchBookingFormClasses,
  searchBookingButtonContainerClasses,
} from './searchBookingStyles'

export const SearchBookingForm = () => {
  const [bookingDetails, setBookingDetails] =
    useState<SearchBookingApolloProps>({
      email: null,
      externalConfirmationId: null,
    })
  const { formState, handleSubmit, register } = useFormContext()
  const { errors } = formState
  const {
    searchMyBookingEmail: emailError,
    searchMyBookingId: confirmationNumberError,
  } = errors

  const onSubmit = ({ searchMyBookingId, searchMyBookingEmail }) => {
    const updatedBookingDetails = {
      email: searchMyBookingEmail,
      externalConfirmationId: searchMyBookingId,
    }
    setBookingDetails(updatedBookingDetails)
  }

  const constructEmailErrorText = () => {
    if (emailError?.type === 'required') return 'Email is required'
    if (emailError?.type === 'validate')
      return 'Please enter a valid email address'
  }

  return (
    <form
      noValidate
      className={searchBookingFormClasses}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        required
        errorText={confirmationNumberError && 'Confirmation number is required'}
        id='confirmationNumber'
        label='Confirmation Number'
        slotBefore={<Icon name='mapOutlined' />}
        type='text'
        {...register('searchMyBookingId', {
          required: true,
        })}
      />
      <Input
        required
        errorText={constructEmailErrorText()}
        id='email'
        label='Email'
        slotBefore={<Icon name='mailOutline' />}
        type='email'
        {...register('searchMyBookingEmail', {
          required: true,
          validate: (value: string) => {
            const regex = new RegExp(emailValidationRegex)
            return regex.test(value)
          },
        })}
      />
      <div className={searchBookingButtonContainerClasses}>
        <Button fullWidth label='SEARCH' size='large' type='submit' />
      </div>
      <SearchBookingApollo
        email={bookingDetails.email}
        externalConfirmationId={bookingDetails.externalConfirmationId}
      />
    </form>
  )
}
