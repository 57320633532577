import { Divider } from '@travelpass/design-system'
import {
  bookingDetailsSectionHeaderCss,
  bookingDetailsSectionHeaderDividerCss,
  bookingDetailsSectionHeaderTitleCss,
} from './bookingDetailsSectionHeaderStyles'

interface BookingDetailsSectionHeaderProps {
  title: string
}

export const BookingDetailsSectionHeader = ({
  title,
}: BookingDetailsSectionHeaderProps) => (
  <div className={bookingDetailsSectionHeaderCss}>
    <h2 className={bookingDetailsSectionHeaderTitleCss}>{title}</h2>
    <div className={bookingDetailsSectionHeaderDividerCss}>
      <Divider />
    </div>
  </div>
)
