import { Skeleton } from '@travelpass/design-system'

export const GuideRecommendedCardLoading = props => {
  return (
    <div
      className={`bg-grey100 h-88 of-hidden rounded-3 w-75 ws-nowrap lg:h-116 relative flex flex-col justify-end md:min-w-full md:max-w-full ${props?.className || ''}`}
    >
      <div className='p-x-4 p-y-6 relative space-y-4'>
        <div className='absolute' />
        <div className='relative space-y-2'>
          <div className='space-y-1'>
            <div className='h-4.5 max-w-16'>
              <Skeleton />
            </div>
            <div className='h-5.25 md:h-9'>
              <Skeleton />
            </div>
          </div>
          <div className='max-w-34 h-8'>
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  )
}
