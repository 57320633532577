import { Skeleton } from '@travelpass/design-system'

export const HotelCancellationCardLoading = () => {
  return (
    <div className='b-grey-300 b-solid b-1 rounded-2 flex cursor-pointer items-center gap-4 p-4'>
      <div className='h-24px md:h-36px w-24px md:w-36px'>
        <Skeleton />
      </div>
      <div className='flex w-full flex-col gap-1'>
        <div className='h-16px md:h-20px w-100px'>
          <Skeleton />
        </div>
        <div className='h-12px md:h-14px md:w-200px'>
          <Skeleton />
        </div>
      </div>
    </div>
  )
}
