import { Button } from '@travelpass/design-system'
import classNames from 'classnames'
import {
  CollectionItemIdType,
  type Guide,
  type UserProfile,
} from 'src/__generated__/graphql'
import { CollectedItemTip, ResultsCard } from 'src/common/components'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { getGuideAddress, getGuideDetailsUrl } from 'src/utils'
import { GuideImage } from '../../../pages/guides/details/common/GuideImage'
import { dispatchToggleAddToCollectionsModal } from '../Collections/dispatchToggleAddToCollectionsModal'
import { OwnerBadge } from '../OwnerBadge/OwnerBadge'

export type OnClickOwnerProfile = (accountHandle: string) => void

export interface GuideRecommendedCardProps {
  guideRecommendedCardData: Pick<
    Guide,
    | 'addresses'
    | 'guideType'
    | 'id'
    | 'images'
    | 'imageUrl'
    | 'insertedAt'
    | 'name'
    | 'updatedAt'
    | 'timeZone'
    | 'isCollected'
  > & {
    ownerProfile?: {
      accountHandle: UserProfile['accountHandle']
      displayName?: UserProfile['displayName']
      id: UserProfile['id']
      isUserOwner?: UserProfile['isUserOwner']
      profileImageUrl: UserProfile['profileImageUrl']
      userId?: UserProfile['userId']
    }
  }
  onClickOwnerProfile?: OnClickOwnerProfile
  showOwnerBadge?: boolean
  smallSize?: boolean
  addToItem?: AddToItem
  onRemove?: VoidFunction
  asLink?: boolean
  showTip?: boolean
  hideAddTo?: boolean
}

export const GuideRecommendedCard = ({
  guideRecommendedCardData,
  onClickOwnerProfile,
  showOwnerBadge = true,
  smallSize = false,
  addToItem = null,
  asLink = true,
  onRemove,
  showTip,
  hideAddTo = false,
}: GuideRecommendedCardProps) => {
  const {
    addresses,
    guideType,
    id,
    images,
    imageUrl,
    name,
    ownerProfile,
    isCollected,
  } = guideRecommendedCardData ?? {}
  const image = images?.[0]?.url ?? imageUrl
  const { city, country, state } = addresses?.[0] ?? {}
  const { accountHandle, displayName, isUserOwner, profileImageUrl } =
    ownerProfile ?? {}

  const onCardClick = () => {
    let url = getGuideDetailsUrl({ id, name })
    pushDataToDataLayer('guide_view', { isUserOwner, guideType, url })

    if (!asLink) return window.open(url)
  }

  return (
    <ResultsCard
      addToItem={addToItem}
      href={asLink ? getGuideDetailsUrl({ id, name }) : undefined}
      isCollected={isCollected}
      itemId={id}
      label={`More information about "${name}" guide, opens in a new tab.`}
      name={name}
      onClick={onCardClick}
      onRemove={onRemove}
    >
      <div
        className={classNames(
          'of-hidden rounded-3 ws-nowrap pointer-events-none flex h-full flex-col justify-end pt-20',
          // todo: fixed heights are a bad practice and should always be avoided
          smallSize ? 'max-h-90 max-w-full' : 'md:pt-50 min-h-475px'
        )}
        data-testid='GuideRecommendedCard'
      >
        {showTip && (
          <div className='z-1 absolute top-2 flex justify-between px-4 pb-5'>
            <CollectedItemTip label='Guide' />
          </div>
        )}
        <GuideImage
          alt=''
          className='absolute inset-0 h-full w-full object-cover'
          draggable={false}
          src={image}
        />
        <div className='p-x-4 p-y-6 relative space-y-3'>
          <div className='from-black/73 from-22% absolute inset-0 bg-gradient-to-t via-black/50 via-50% to-gray-300/10 to-100%' />
          <div className='relative space-y-3'>
            <h4
              className={`color-white line-clamp-2 text-wrap ${smallSize ? 'type-h4 text-left' : 'type-h3 lg:min-h-18 min-h-11'}`}
            >
              {name}
            </h4>
            <p className='color-white type-overline-desktop min-h-4 truncate text-left'>
              {getGuideAddress({ city, country, state })}
            </p>
          </div>
          {showOwnerBadge && (
            <OwnerBadge
              accountHandle={accountHandle}
              border='none'
              classNames={{
                containerStyles: 'relative',
                ownerNameStyles: 'color-white type-body-1-desktop',
                textStyles: 'space-y-1',
              }}
              displayName={displayName}
              interactivity='button'
              profileImageUrl={profileImageUrl}
              onClickOwnerProfile={onClickOwnerProfile}
            />
          )}
        </div>
        {!hideAddTo && (
          <div className='[&_button]:hover:c-white [&_button]:active:c-white [&_button]:transition-all-200 pointer-events-auto absolute bottom-2 right--2 [&_button]:hover:opacity-50 [&_button]:active:opacity-50'>
            <Button
              size='small'
              startIcon='addCircleOutline'
              variant='white'
              onClick={() => {
                dispatchToggleAddToCollectionsModal({
                  isModalOpen: true,
                  item: { id, name, type: CollectionItemIdType.Guide },
                })
              }}
            >
              Add To
            </Button>
          </div>
        )}
      </div>
    </ResultsCard>
  )
}
