import type { ProductCardData } from '../types'

const defaultProductData: Partial<ProductCardData> = {
  cancellationPolicy: null,
  displayFrom: false,
  displayPriceUnit: false,
  durationMinutes: 0,
  name: 'Could not load name',
  primaryPrice: '$-',
  priceUnit: 'traveler',
  totalCustomerReviews: 0,
}

export { defaultProductData }
