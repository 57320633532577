import { IconButton, MenuItem, Popover } from '@travelpass/design-system'
import { dispatchToggleAddToCollectionsModal } from '../Collections/dispatchToggleAddToCollectionsModal'
import { dispatchToggleAddToGuideModal } from '../Guides/dispatchToggleAddToGuideModal'

interface CollectionDetailsMenuProps {
  onRemove: Function
  item: AddToItem
  hideGuide?: boolean
}

export const CollectionDetailsMenu = ({
  onRemove,
  item,
  hideGuide,
}: CollectionDetailsMenuProps) => (
  <Popover
    trigger={
      <IconButton
        aria-label='Collection Options'
        color='white'
        icon='moreHoriz'
        outline='square'
        size='small'
      />
    }
  >
    {!hideGuide && (
      <MenuItem
        label='Add to Guide'
        startIcon='listAlt'
        value='addToGuide'
        onClick={() =>
          dispatchToggleAddToGuideModal({ isModalOpen: true, item })
        }
      />
    )}
    <MenuItem
      label='Add to Collection'
      startIcon='bookmarkBorder'
      value='addToCollection'
      onClick={() =>
        dispatchToggleAddToCollectionsModal({ isModalOpen: true, item })
      }
    />
    <MenuItem
      label='Remove'
      startIcon='delete'
      value='addToCollection'
      onClick={() => onRemove()}
    />
  </Popover>
)
