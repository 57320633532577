import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'

enum ResultsCardBannerVariant {
  featured = 'featured',
  login = 'login',
  travelpass = 'travelpass',
}

interface ResultsCardBannerProps {
  variant?: ResultsCardBannerVariant
}

const ResultsCardBanner = ({
  variant = ResultsCardBannerVariant.login,
}: ResultsCardBannerProps) => (
  <p
    className={classNames(
      'p-x-2 p-y-1 rounded-8 type-body-2-mobile flex w-fit flex-row items-center gap-1',
      {
        'bg-orange color-white': variant !== ResultsCardBannerVariant.login,
        'bg-mint-light color-new-forest':
          variant === ResultsCardBannerVariant.login,
        'font-500': variant === ResultsCardBannerVariant.travelpass,
      }
    )}
  >
    {variant !== ResultsCardBannerVariant.travelpass && (
      <Icon name='priceTag' size='small' />
    )}
    {variant === ResultsCardBannerVariant.featured && (
      <span>Recommended for you</span>
    )}
    {/* {variant === ResultsCardBannerVariant.login && (
      <span>Sign in for extra savings</span>
    )} */}
    {variant === ResultsCardBannerVariant.travelpass && (
      <span>Recommended by Travelpass</span>
    )}
  </p>
)

export { ResultsCardBanner, ResultsCardBannerVariant }
