import isEmpty from 'lodash.isempty'
import {
  EventType,
  type PublishedEvent,
  type GenericAddress,
  type Guide,
  type UserProfile,
} from 'src/__generated__/graphql'
import { guidesPath, guideDraftPath, profilePath } from 'src/constants'
import { DEFAULT_PROFILE_IMAGE_URL } from 'src/pages/profile/profileConstants'
import { getDateFromUTC } from './dateUtils'

const checkGuideEventIsHotelSearch = (selectedEvent: PublishedEvent) =>
  !!selectedEvent?.standardHotelId && selectedEvent?.type === EventType.Stay

const decodeGuideId = (guideId: string) => {
  const guideDraftPrefix = 'GuideDraft:gded_'
  const guidePrefix = 'Guide:gde_'

  if (!guideId) return ''

  try {
    const decodedGuideId = atob(guideId)

    if (
      decodedGuideId.includes(guideDraftPrefix) ||
      decodedGuideId.includes(guidePrefix)
    )
      return decodedGuideId
        .replace(guideDraftPrefix, '')
        .replace(guidePrefix, '')
  } catch (error) {
    console.error(error)
  }

  return guideId
}

const decodeGuideDetailsName = (name: string) => {
  let nameFormatted = name?.replace(/-/g, ' ') ?? ''

  return decodeURIComponent(nameFormatted)
}

const encodeGuideDetailsName = (name: string) => {
  /** @link https://stackoverflow.com/a/41543705 */
  const sanitizedName =
    name?.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    ) ?? ''
  let nameFormatted = sanitizedName
    .replace(/\./g, '-')
    .split(' ')
    .join('-')
    .replace(/-+/g, '-')

  if (nameFormatted.endsWith('-')) {
    nameFormatted = nameFormatted.slice(0, -1)
  }

  return encodeURIComponent(nameFormatted)
}

const encodeGuideId = ({
  guideId,
  isGuideDraft = false,
}: {
  guideId: string
  isGuideDraft?: boolean
}) => {
  if (!guideId) return ''

  try {
    const guidePrefix = isGuideDraft ? 'GuideDraft:gded_' : 'Guide:gde_'

    return encodeURIComponent(btoa(`${guidePrefix}${guideId}`))
  } catch (error) {
    console.error(error)

    return guideId
  }
}

const getGuideDetailsUrl = ({
  id,
  name,
}: {
  id: Guide['id']
  name?: Guide['name']
}) => {
  const encodedName = encodeGuideDetailsName(name)

  if (!name) return `${guidesPath}/${id}`

  return `${guidesPath}/${id}?name=${encodedName}`
}

const getGuideAddress = ({
  addressLine1,
  city,
  country,
  state,
}: Pick<
  GenericAddress,
  'addressLine1' | 'city' | 'country' | 'state'
>): string => {
  // solution based on this criteria => https://linear.app/travelpass/issue/BOOK-2541/guide-card-isnt-displaying-location-when-guide-only-has-state#comment-74b65772
  if (country === 'United States' || country === 'United States of America')
    return [addressLine1, city, state, !city && country]
      .filter(Boolean)
      .join(', ')

  return [addressLine1, city, !city && state, country]
    .filter(Boolean)
    .join(', ')
}

const getGuideOwnerImage = (
  profileImageUrl: Guide['ownerProfile']['profileImageUrl']
) => profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL

const getGuideOwnerName = ({
  accountHandle,
  displayName,
}: Pick<Guide['ownerProfile'], 'accountHandle' | 'displayName'>) => {
  if (displayName) return displayName

  if (accountHandle) return accountHandle

  return 'Anonymous'
}

const getGuideOwnerUrl = (
  accountHandle: Guide['ownerProfile']['accountHandle']
) => {
  if (accountHandle) return `${profilePath}/${accountHandle}`

  return profilePath
}

const getGuidePlaceLatLng = (
  location?: google.maps.LatLng | google.maps.LatLngLiteral
): google.maps.LatLngLiteral => {
  if (isEmpty(location)) return null

  if (
    typeof location?.lat === 'function' ||
    typeof location?.lng === 'function'
  ) {
    const { lat, lng } = (location as google.maps.LatLng) ?? {}

    return {
      lat: lat(),
      lng: lng(),
    }
  }

  const { lat, lng } = (location as google.maps.LatLngLiteral) ?? {}

  return {
    lat,
    lng,
  }
}

const getGuidePublishedDate = ({
  insertedAt,
  timeZone,
  updatedAt,
}: Pick<Guide, 'insertedAt' | 'timeZone' | 'updatedAt'>) => {
  const date = getDateFromUTC(updatedAt ?? insertedAt, timeZone)

  return date.format('MMMM D, YYYY')
}

const getGuideDraftDetailsUrl = ({
  id,
  isUserOwner = false,
  name,
}: {
  id: Guide['id']
  isUserOwner?: UserProfile['isUserOwner']
  name: Guide['name']
}) => {
  const encodedName = encodeGuideDetailsName(name)

  if (isUserOwner) return `${guideDraftPath}/${id}/${encodedName}`

  return `${guideDraftPath}/${id}/${encodedName}/share`
}

export {
  checkGuideEventIsHotelSearch,
  decodeGuideDetailsName,
  decodeGuideId,
  encodeGuideDetailsName,
  encodeGuideId,
  getGuideAddress,
  getGuideDetailsUrl,
  getGuideOwnerImage,
  getGuideOwnerName,
  getGuideOwnerUrl,
  getGuidePlaceLatLng,
  getGuidePublishedDate,
  getGuideDraftDetailsUrl,
}
