import merge from 'lodash.merge'
import usePlacesAutocomplete from 'use-places-autocomplete'
import type { HookArgs as PlacesAutocompleteOptions } from 'use-places-autocomplete'
import { useFlag } from 'src/common/hooks'

const RESTRICTED_COUNTRIES = ['ca', 'mx', 'us']

export enum CACHE_KEYS {
  onlyNorthAmerica = 'ONLY_NORTH_AMERICA',
  withoutRestriction = 'WIHTOUT_RESTRICTION',
}

export const useGeocoderSearch = (config?: PlacesAutocompleteOptions) => {
  const areInternationalHotelsEnabled = useFlag(
    'isInternationalGeocoderEnabled'
  )

  return usePlacesAutocomplete(
    merge(
      {
        debounce: 300,
        requestOptions: {
          componentRestrictions: {
            country:
              config?.requestOptions?.componentRestrictions?.country ||
              areInternationalHotelsEnabled
                ? []
                : RESTRICTED_COUNTRIES,
          },
        },
        cacheKey: areInternationalHotelsEnabled
          ? CACHE_KEYS.withoutRestriction
          : CACHE_KEYS.onlyNorthAmerica,
      } as PlacesAutocompleteOptions,
      config
    )
  )
}
