const detailsMinimalHeroContentCss = 'flex gap-3 items-center justify-between'

const detailsMinimalHeroCss = 'flex flex-col gap-1'

// TODO: update flex flex-col gap-12 when mobile design is ready
const detailsMinimalHeroHeaderCss = 'flex flex-col gap-12'

const detailsMinimalHeroImageCss = 'h-55'

const detailsMinimalHeroSubtitleCss = 'color-grey-800 text-body-2'

const detailsMinimalHeroTitleCss = 'type-h4'

export {
  detailsMinimalHeroContentCss,
  detailsMinimalHeroCss,
  detailsMinimalHeroHeaderCss,
  detailsMinimalHeroImageCss,
  detailsMinimalHeroSubtitleCss,
  detailsMinimalHeroTitleCss,
}
