import isEmpty from 'lodash.isempty'
import { currency } from 'src/constants'
import { stringToNumber } from './stringUtils'

interface GetPriceDifference {
  hideCurrency?: boolean
  prices: [string?, string?]
  willRoundPrices?: boolean
}

type PriceLanguages = string[]

const currencyNumberFormat = new Intl.NumberFormat('en-US', {
  currency,
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: 'currency',
})

const currencyNumberFormatWithDigits = new Intl.NumberFormat('en-US', {
  currency,
  style: 'currency',
})

const checkIsForeignUser = (languages?: PriceLanguages) => {
  const updatedLanguages = isEmpty(languages) ? navigator?.languages : languages

  return !updatedLanguages?.includes('en-US')
}

const getPrice = (price: string, languages?: PriceLanguages) => {
  const isForeignUser = checkIsForeignUser(languages)
  const updatedPrice = currencyNumberFormat.format(parseFloat(price))

  if (isForeignUser) return `US${updatedPrice}`

  return updatedPrice
}

const getPriceDifference = ({
  hideCurrency = false,
  prices,
  willRoundPrices = false,
}: GetPriceDifference) => {
  const priceDiff = prices
    .map(price => {
      const updatedPrice = stringToNumber(price?.toString() ?? '') ?? 0

      if (willRoundPrices) return Math.round(updatedPrice)

      return updatedPrice
    })
    .reduce(
      (previousPrice, currentPrice) => Math.abs(previousPrice - currentPrice),
      0
    )

  if (!hideCurrency) return currencyNumberFormat.format(priceDiff)

  return priceDiff
}

const getPriceWithDigits = (price: string, languages?: PriceLanguages) => {
  const isForeignUser = checkIsForeignUser(languages)
  const updatedPrice = currencyNumberFormatWithDigits.format(parseFloat(price))

  if (isForeignUser) return `US${updatedPrice}`

  return updatedPrice
}

const showStrikethroughPrice = ({
  nightlyAverage,
  strikethrough,
}: {
  nightlyAverage: string | null
  strikethrough: string | null
}) => {
  if (!!nightlyAverage && !!strikethrough && strikethrough !== '0.00') {
    return (
      (stringToNumber(
        getPriceDifference({
          hideCurrency: true,
          prices: [nightlyAverage, strikethrough],
        })?.toString()
      ) ?? 0) >= 1
    )
  }

  return false
}

export {
  checkIsForeignUser,
  getPrice,
  getPriceDifference,
  getPriceWithDigits,
  showStrikethroughPrice,
}
