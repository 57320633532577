import {
  sectionHeaderCss,
  sectionHeaderSubtitleCss,
  sectionHeaderTitleCss,
} from './sectionHeaderStyles'

interface SectionHeaderProps {
  subtitle?: string
  title: string
}

export const SectionHeader = ({ subtitle, title }: SectionHeaderProps) => (
  <div className={sectionHeaderCss}>
    {subtitle && <p className={sectionHeaderSubtitleCss}>{subtitle}</p>}
    <h2 className={sectionHeaderTitleCss}>{title}</h2>
  </div>
)
