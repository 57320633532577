import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import type {
  ExperienceSortBy,
  ExperienceFilters,
} from 'src/__generated__/graphql'
import {
  experienceTagsAll,
  initialExpFilters,
  initialExpSort,
} from 'src/constants'
import { validateCustomerReviewScore, validatePriceRange } from './filterUtils'

interface validateDurationResponse {
  validMinHours: number
  validMaxHours: number
  resetToDefaultDuration: boolean
}

const validateDuration = ({
  duration,
}: Partial<ExperienceFilters>): validateDurationResponse => {
  const { minHours, maxHours } = duration ?? {}
  const { minHours: initMinHours, maxHours: initMaxHours } = cloneDeep<{
    minHours: number
    maxHours: number
  }>(initialExpFilters.duration)
  const minHourInt = parseInt(String(minHours))
  const maxHourInt = parseInt(String(maxHours))

  const validDuration = {
    validMinHours: initMinHours,
    validMaxHours: initMaxHours,
    resetToDefaultDuration: true,
  }

  if (minHourInt) {
    if (minHourInt < initMinHours) {
      validDuration.validMinHours = initMinHours
    } else if (minHourInt > initMaxHours) {
      validDuration.validMinHours = initMaxHours
    } else {
      validDuration.validMinHours = minHourInt
    }
  }

  if (maxHourInt) {
    if (maxHourInt < initMinHours) {
      validDuration.validMaxHours = initMinHours
    } else if (maxHourInt > initMaxHours) {
      validDuration.validMaxHours = initMaxHours
    } else {
      validDuration.validMaxHours = maxHourInt
    }
  }

  if (validDuration.validMinHours > validDuration.validMaxHours) {
    validDuration.validMinHours = maxHourInt
    validDuration.validMaxHours = minHourInt
  }

  validDuration.resetToDefaultDuration =
    validDuration.validMinHours === initMinHours &&
    validDuration.validMaxHours === initMaxHours

  return validDuration
}

const validateFilterTags = ({ tags }: Partial<ExperienceFilters>) => {
  const allTags = cloneDeep(experienceTagsAll)
  const experienceTagIds = allTags.map(({ tagId }) => tagId)

  const validTags = (tags ?? []).reduce<number[]>((addedTags, tag) => {
    if (typeof tag === 'number' && experienceTagIds.includes(tag)) {
      addedTags.push(tag)
    }
    return addedTags
  }, [] as number[])

  const resetToDefaultTags = isEmpty(validTags)
  return { resetToDefaultTags, validTags }
}

interface validateExpFiltersAndSortArgs {
  filters?: ExperienceFilters
  sort?: ExperienceSortBy
}

interface validateExpFiltersAndSortResponse {
  validFilters: ExperienceFilters
  validSort: ExperienceSortBy
  sortResetToDefault: boolean
}

/**
 * Validates filters and sort
 * if filters are same as default values, they are removed
 * if sort is same as Sortby.Distance (the default value)
 * then sortResetToDefault = true
 */
const validateExpFiltersAndSort = ({
  filters,
  sort,
}: validateExpFiltersAndSortArgs): validateExpFiltersAndSortResponse => {
  const validResponse = {
    validFilters: {},
    validSort: initialExpSort,
    sortResetToDefault: true,
  }

  if (!!sort && sort !== validResponse.validSort) {
    validResponse.validSort = sort
    validResponse.sortResetToDefault = false
  }

  const { customerReviewScore, duration, priceRange, tags, timeOfDay } =
    filters ?? {}

  const { minScore, maxScore } = customerReviewScore ?? {}
  const { validMinScore, validMaxScore, resetToDefaultCustomerReviewScore } =
    validateCustomerReviewScore({
      minScore: minScore ?? 0,
      maxScore: maxScore ?? 0,
    })

  const { validMinHours, validMaxHours, resetToDefaultDuration } =
    validateDuration({ duration })

  const { minPrice, maxPrice } = priceRange ?? {}
  const { validMinPrice, validMaxPrice, resetToDefaultPriceRange } =
    validatePriceRange({
      minPrice: minPrice ?? 0,
      maxPrice: maxPrice ?? 0,
    })
  const { resetToDefaultTags, validTags } = validateFilterTags({ tags })

  if (!isEmpty(filters)) {
    Object.keys(initialExpFilters).forEach(filter => {
      if (
        filter === 'customerReviewScore' &&
        !resetToDefaultCustomerReviewScore
      ) {
        validResponse.validFilters['customerReviewScore'] = {
          minScore: validMinScore,
          maxScore: validMaxScore,
        }
      } else if (filter === 'duration' && !resetToDefaultDuration) {
        validResponse.validFilters['duration'] = {
          minHours: validMinHours,
          maxHours: validMaxHours,
        }
      } else if (filter === 'priceRange' && !resetToDefaultPriceRange) {
        if (validMaxPrice || validMinPrice) {
          validResponse.validFilters['priceRange'] = {
            minPrice: validMinPrice,
            maxPrice: validMaxPrice,
          }
        }
      } else if (filter === 'tags' && !resetToDefaultTags) {
        validResponse.validFilters['tags'] = validTags
      } else if (filter === 'timeOfDay' && !isEmpty(timeOfDay)) {
        validResponse.validFilters['timeOfDay'] = timeOfDay
      }
    })
  }

  return validResponse
}

export { validateExpFiltersAndSort, validateDuration, validateFilterTags }
