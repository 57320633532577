import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import type { Booking, ExperienceBooking } from 'src/__generated__/graphql'
import { experiencesPath, hotelsPath } from 'src/constants'
import { searchBookingQuery } from './searchBookingQuery'
import { useStatusSnackbars } from '../useStatusSnackbars'

export interface SearchBookingApolloProps {
  email: string | null
  externalConfirmationId: string | null
}

export const SearchBookingApollo = ({
  email,
  externalConfirmationId,
}: SearchBookingApolloProps): JSX.Element => {
  const [isBookingFound, setIsBookingFound] = useState(false)
  const [isErrorBookingFound, setIsErrorBookingFound] = useState(false)

  const navigate = useNavigate()

  const { error: searchBookingError, loading: isSearchBookingLoading } =
    useQuery(searchBookingQuery, {
      variables: { externalConfirmationId, email },
      skip: !externalConfirmationId || !email,
      onCompleted: data => {
        const { findBooking } = data ?? {}
        const { id } = findBooking ?? {}

        if (id) {
          setIsBookingFound(true)

          const hotelData = findBooking as Booking
          const expData = findBooking as ExperienceBooking

          if (hotelData?.hotelId) {
            const url = `${hotelsPath}/confirmation/${id}`
            navigate(url)
          } else if (expData?.externalConfirmationId) {
            const url = `${experiencesPath}/confirmation/${expData?.externalConfirmationId}`
            navigate(url)
          } else {
            setIsErrorBookingFound(true)
          }
        } else {
          setIsErrorBookingFound(true)
        }
      },
    })

  useEffect(() => {
    if (searchBookingError) {
      setIsErrorBookingFound(true)
    }
  }, [searchBookingError])

  useStatusSnackbars({
    errorMessage: 'Unable to find booking.',
    isError: isErrorBookingFound,
    isLoading: isSearchBookingLoading,
    isSuccess: isBookingFound,
    loadingMessage: 'Finding booking details...',
    successMessage: 'Redirecting...',
  })

  return
}
