import { stringTimeToDayjs } from 'src/utils'
import {
  minTime,
  maxTime,
  defaultTimeFormat,
  defaultTimeRange,
  defaultTimeIncrement,
} from './timeRangeConstants'
import type { TimeRangeType } from './types'

const generateTimeOptions = ({
  startTime = minTime.format(defaultTimeFormat),
  endTime = maxTime.format(defaultTimeFormat),
  increment = defaultTimeIncrement,
}: {
  startTime?: string | null
  endTime?: string | null
  increment?: number
}): string[] => {
  const formattedTimes = []

  const endTimeDjs = stringTimeToDayjs(endTime)

  let iterableTime = stringTimeToDayjs(startTime)

  while (iterableTime.isBefore(endTimeDjs)) {
    const formattedTime = iterableTime.format(defaultTimeFormat)

    if (formattedTime === '8:00 AM') formattedTimes.push('None')

    formattedTimes.push(formattedTime)
    iterableTime = iterableTime.add(increment, 'minute')
  }

  if (!formattedTimes.includes('None')) formattedTimes.unshift('None')

  if (endTimeDjs.isBefore(maxTime) && !endTimeDjs.isSame(maxTime)) {
    const formattedEndTime = endTimeDjs.format(defaultTimeFormat)
    formattedTimes.push(formattedEndTime)
  }

  return formattedTimes
}

const updateTimeRangeStart = ({
  updatedStartTime,
  timeRange = defaultTimeRange,
  increment = defaultTimeIncrement,
}: {
  updatedStartTime: string
  timeRange?: TimeRangeType
  increment?: number
}): TimeRangeType => {
  const { endTime } = timeRange ?? {}
  const startTimeDjs = stringTimeToDayjs(updatedStartTime)

  if (updatedStartTime === 'None') {
    return { startTime: updatedStartTime, endTime: 'None' }
  } else if (startTimeDjs.isValid()) {
    const endTimeDjs = stringTimeToDayjs(endTime)

    const validEndTime =
      endTimeDjs.isValid() && !endTimeDjs.isAfter(startTimeDjs)
        ? startTimeDjs.add(increment, 'minute').format(defaultTimeFormat)
        : endTime

    return {
      startTime: startTimeDjs.format(defaultTimeFormat),
      endTime: validEndTime,
    }
  } else {
    return timeRange
  }
}

const updateTimeRangeEnd = ({
  updatedEndTime,
  timeRange = defaultTimeRange,
  increment = defaultTimeIncrement,
}: {
  updatedEndTime: string
  timeRange?: TimeRangeType
  increment?: number
}): TimeRangeType => {
  const { startTime } = timeRange ?? {}
  const endTimeDjs = stringTimeToDayjs(updatedEndTime)

  if (updatedEndTime === 'None' || startTime === 'None') {
    return { startTime, endTime: 'None' }
  } else if (endTimeDjs.isValid()) {
    const startTimeDjs = stringTimeToDayjs(startTime)

    const validStartTime =
      startTimeDjs.isValid() && !startTimeDjs.isBefore(endTimeDjs)
        ? endTimeDjs.subtract(increment, 'minute').format(defaultTimeFormat)
        : startTime

    return {
      startTime: validStartTime,
      endTime: endTimeDjs.format(defaultTimeFormat),
    }
  } else {
    return timeRange
  }
}

export { generateTimeOptions, updateTimeRangeStart, updateTimeRangeEnd }
