import dayjs from 'dayjs'
import type { TimeRangeType } from './types'

const defaultTimeFormat = 'h:mm A'
const defaultTimeIncrement = 30

const minTime = dayjs().set('hour', 0).set('minute', 0)
const maxTime = dayjs().set('hour', 23).set('minute', 59)

const defaultTimeRange: TimeRangeType = {
  startTime: 'None',
  endTime: 'None',
}

export {
  minTime,
  maxTime,
  defaultTimeFormat,
  defaultTimeIncrement,
  defaultTimeRange,
}
