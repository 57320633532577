export const TOGGLE_PROFILE_MODAL = 'toggleProfileModal'

export const dispatchToggleProfileModal = ({
  accountHandle,
}: {
  accountHandle?: string
}) => {
  const event = new CustomEvent(TOGGLE_PROFILE_MODAL, {
    detail: { accountHandle },
  })
  // TODO: should we use window or document here for test env?
  window?.dispatchEvent(event)
}
