import isEmpty from 'lodash.isempty'
import { Helmet as ReactHelmet } from 'react-helmet'
import { useLocation, useSearchParams } from 'react-router-dom'
import { destinationsSegment, hotelsSegment } from 'src/constants'
import {
  breadcrumbSchemaMarkup,
  homepageSchemaMarkup,
  hotelsSchemaMarkup,
} from './helmetUtils'
import type { HotelsSchemaMarkupsProps } from './types'

export interface HelmetProps {
  canonicalUrl?: string
  metaDescription?: string
  metaImage?: string
  pageName?: string
  shouldFollow?: boolean
  hotelsSchemaMarkups?: HotelsSchemaMarkupsProps
}

export const Helmet = ({
  canonicalUrl,
  metaDescription,
  metaImage,
  pageName,
  shouldFollow = true,
  hotelsSchemaMarkups,
}: HelmetProps) => {
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const pageNameStr = !isEmpty(pageName) ? `${pageName} | ` : ''
  const helmetText = `${pageNameStr}Travelpass`
  const descriptionText = metaDescription?.trim()?.length
    ? `${metaDescription}`
    : 'The best rates on hotels. Save money and travel more with exclusive member discounts and deals on hotels, from luxury resorts to budget accommodations.'

  const isHomePage = location?.pathname == '/'
  const isResultsDestinationsRoute =
    location?.pathname?.includes(destinationsSegment) &&
    location?.pathname?.includes(hotelsSegment)

  return (
    <ReactHelmet>
      {/* https://docs.google.com/document/d/1j7rZLDwAgYVW6FmmMrty7YZPEKWpzkDNOzJVgqT4Clk/edit?pli=1 */}
      {helmetText && <title>{helmetText}</title>}
      {descriptionText && <meta content={descriptionText} name='description' />}
      {canonicalUrl && <link href={canonicalUrl} rel='canonical' />}
      <meta
        content={shouldFollow ? 'index, follow' : 'noindex, nofollow'}
        name='robots'
      />
      {helmetText && <meta content={helmetText} property='og:title' />}
      {descriptionText && (
        <meta content={descriptionText} property='og:description' />
      )}
      {canonicalUrl && <meta content={canonicalUrl} property='og:url' />}
      {metaImage && <meta content={metaImage} property='og:image' />}
      <meta content='Travelpass' property='og:site_name' />
      <meta content='website' property='og:type' />
      <meta content='en_US' property='og:locale' />
      <script type='application/ld+json'>
        {JSON.stringify([
          isHomePage ? homepageSchemaMarkup : {},
          breadcrumbSchemaMarkup(location?.pathname, searchParams),
          isResultsDestinationsRoute
            ? hotelsSchemaMarkup(hotelsSchemaMarkups)
            : {},
        ])}
      </script>
    </ReactHelmet>
  )
}
