import { Button, EmptyState } from '@travelpass/design-system'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

interface ResultsEmptyProps {
  subtitle?: string
  title?: string
  ctaLabel?: string
  onCtaClick?: () => void
}

const defaultTitle =
  "We're sorry, there aren't any products available that match your search."
const defaultSubtitle = 'Go back, or try adjusting your search.'
const defaultCtaLabel = 'adjust your search'

export const ResultsEmpty = ({
  subtitle = defaultSubtitle,
  title = defaultTitle,
  ctaLabel = defaultCtaLabel,
  onCtaClick,
}: ResultsEmptyProps) => {
  const navigate = useNavigate()
  const onGoBackClick = () => navigate(-1)

  return (
    <div className={resultsEmptyCss} data-testid='ResultsEmpty'>
      <div className={resultsEmptyContainerCss(!!onCtaClick)}>
        <div className={resultsEmptyTopRowCss}>
          <div className={resultsEmptyIllustrationContainerCss}>
            <div className='w-125px'>
              <EmptyState name='noSearchResult' />
            </div>
          </div>
          <div className={resultsEmptyTextContainerCss}>
            <h1 className={resultsEmptyTitleCss}>{title}</h1>
            <p className={resultsEmptySubtitleCss}>{subtitle}</p>
          </div>
        </div>
        <div className={resultsEmptyBottomRowCss}>
          <Button
            fullWidth={isMobile}
            label='Go Back'
            variant='filled'
            onClick={onGoBackClick}
          />
          {onCtaClick && (
            <Button
              fullWidth={isMobile}
              label={ctaLabel}
              variant='outlined'
              onClick={onCtaClick}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const resultsEmptyCss =
  'h-screen w-full bg-white/50 flex items-start justify-center pt-9'
const resultsEmptyContainerCss = (hasTwoButtons: boolean) =>
  classNames(
    'w-full h-full flex flex-col gap-3 items-center justify-center max-w-144 md:max-h-49 b-solid b-1 b-grey300 rd-2xl p-4',
    { 'sm:max-h-96': hasTwoButtons, 'max-h-80': !hasTwoButtons }
  )
const resultsEmptyTopRowCss =
  'flex flex-col md:flex-row gap-6 grow items-center justify-center'
const resultsEmptyIllustrationContainerCss =
  'w-full h-full max-w-31 flex items-center justify-center'
const resultsEmptyTextContainerCss =
  'w-full h-full flex flex-col gap-2 justify-center grow'
const resultsEmptyBottomRowCss =
  'w-full h-full flex flex-col md:flex-row grow items-center justify-center gap-4 grow'
const resultsEmptyTitleCss = 'text-subtitle-1 c-grey800'
const resultsEmptySubtitleCss = 'text-body-1 c-grey800 m-0'
