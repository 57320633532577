import type { RefObject } from 'react'
import { useEffect, useState } from 'react'
interface HookOptions extends IntersectionObserverInit {
  freezeOnceVisible?: boolean
}

/**
 * Custom hook for tracking the intersection of a DOM element with its containing element or the viewport.
 * @param {RefObject<Element>} elementRef - React ref containing the DOM element to be observed.
 * @param {HookOptions} [options] - The options for configuring the Intersection Observer.
 * @param {number} [options.threshold=0] - A threshold indicating the percentage of the target's visibility needed to trigger the callback.
 * @param {Element | null} [options.root=null] - The element that is used as the viewport for checking visibility of the target.
 * @param {string} [options.rootMargin='0%'] - A margin around the root.
 * @param {boolean} [options.freezeOnceVisible=false] - If true, freezes the intersection state once the element becomes visible.
 *
 * @returns {boolean} - A boolean indicating whether the target element is intersecting with the root.
 *
 * @example
 * const elementRef = useRef<HTMLDivElement>(null)
 * const isVisible = useIsElementOnScreen(elementRef, { threshold: 0.5 })
 *
 * @see [Documentation](https://usehooks-ts.com/react-hook/use-intersection-observer)
 * @see [MDN Intersection Observer API](https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API)
 */
export function useIsElementOnScreen(
  elementRef: RefObject<Element>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = false,
  }: HookOptions = {}
): boolean {
  const [entry, setEntry] = useState<IntersectionObserverEntry>()

  const frozen = entry?.isIntersecting && freezeOnceVisible

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry)
  }

  useEffect(() => {
    const node = elementRef.current // DOM Ref
    if (!node) return

    const hasIOSupport = !!window.IntersectionObserver
    if (!hasIOSupport || frozen) return

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(node)

    return () => {
      observer.disconnect()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen])

  return entry?.isIntersecting ?? false
}
