import { Dropdown, DropdownOption } from '@travelpass/design-system'
import { defaultTimeIncrement } from './timeRangeConstants'
import { generateTimeOptions } from './timeRangeUtils'

export const TimePicker = ({
  increment = defaultTimeIncrement,
  label,
  name,
  startTime,
  value,
  onChange,
}: {
  increment?: number
  label: string
  name: string
  startTime?: string
  value?: string
  onChange(newValue: string): void
}): JSX.Element => {
  const options = generateTimeOptions({ startTime, increment })
  const displayValue = value ?? 'None'

  return (
    <div className={timePickerCss}>
      <Dropdown
        id='timerange-select'
        label={label}
        name={name}
        portal={false}
        value={displayValue}
        onChange={onChange}
      >
        {options?.map(option => (
          <DropdownOption key={`time-option-${option}`} value={option}>
            {option}
          </DropdownOption>
        ))}
      </Dropdown>
    </div>
  )
}

const timePickerCss = 'c-black flex-1 w-full'
