const guestsPopoverCss = 'flex flex-col gap-4'

const guestsPopoverItemCss =
  'color-forest flex gap-8 items-center justify-between'

const guestsPopoverItemDetailsCss = 'min-w-23'

const guestsPopoverItemDetailsSubtitleCss = 'text-small-text'

const guestsPopoverItemDetailsTitleCss = 'text-subtitle-1'

const guestsPopoverItemStepperCss =
  'flex gap-2 grow items-center all-[i]:color-new-forest'

const guestsPopoverItemStepperTitleCss = 'grow text-center text-subtitle-1'

const guestsPopoverWrapperCss =
  'bg-white mr--5 mt-5 px-7 py-7 rounded-5 shadow-1 w-78'

export {
  guestsPopoverCss,
  guestsPopoverItemCss,
  guestsPopoverItemDetailsCss,
  guestsPopoverItemDetailsSubtitleCss,
  guestsPopoverItemDetailsTitleCss,
  guestsPopoverItemStepperCss,
  guestsPopoverItemStepperTitleCss,
  guestsPopoverWrapperCss,
}
