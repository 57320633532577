export const getLanguageNameByCode = (languageCode: string): string => {
  if (!languageCode) return ''

  try {
    const languageName = new Intl.DisplayNames('en', {
      type: 'language',
    })?.of(languageCode)
    return languageName ?? ''
  } catch (error) {
    return ''
  }
}
