import { FormProvider, useForm } from 'react-hook-form'
import { SearchBookingForm } from './SearchBookingForm'
import {
  searchBookingFormTitleContainerClasses,
  searchBookingFormTitleClasses,
} from './searchBookingStyles'

interface SearchBookingProps {
  showTitle?: boolean
}
export const SearchBooking = ({ showTitle = true }: SearchBookingProps) => {
  const methods = useForm({
    defaultValues: { searchMyBookingId: '', searchMyBookingEmail: '' },
  })

  return (
    <FormProvider {...methods}>
      <div>
        {showTitle && (
          <div className={searchBookingFormTitleContainerClasses}>
            <h3 className={searchBookingFormTitleClasses}>
              Booking Information
            </h3>
          </div>
        )}
        <SearchBookingForm />
      </div>
    </FormProvider>
  )
}
