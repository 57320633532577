import type { Dayjs } from 'dayjs'
import { createSearchParams } from 'react-router-dom'
import { destinationsSegment, hotelsPath } from 'src/constants'
import { getStateAbbreviation } from 'src/utils/stateUtils'
import { validateDates, formatDate } from './dateUtils'
import { validateGuests } from './userUtils'

interface generateHotelDetailsUrlArgs {
  city: string
  id: number | string
  name: string
  state: string
  stateAbbreviation: string
  arrival?: string | Dayjs
  departure?: string | Dayjs
  adults?: number
  eventId?: string
  kids?: number
  tripId?: string
  utmParams?: Record<string, string>
  excludeSearchParams?: boolean
}

export const generateHotelDetailsUrl = ({
  city,
  id,
  name,
  state,
  stateAbbreviation,
  arrival,
  departure,
  adults,
  eventId,
  kids,
  tripId,
  utmParams = {},
  excludeSearchParams = false,
}: generateHotelDetailsUrlArgs): string => {
  const title = [name, city, state].reduce((titleStr, currentStr) => {
    if (currentStr) {
      const encodedStr = encodeURIComponent(currentStr?.split(' ').join('-'))
      if (titleStr !== '') {
        return [titleStr, encodedStr].join('-')
      } else {
        return encodedStr
      }
    }
    return titleStr
  }, '')

  // Some of the hotel names have a period at the end of the name. This removes that period.
  const convertedTitle = title === '' ? 'Hotel-Name' : title.replace(/\.$/, '')

  const encodedCity = encodeURIComponent(
    city ? city?.toLowerCase?.().split(' ').join('-') : 'city'
  )
  const getEncodedStateAbbreviation = () => {
    const stateCode = getStateAbbreviation(state, stateAbbreviation)
    if (stateCode === 'State Not Found') return 'state'
    return encodeURIComponent(stateCode.toLowerCase())
  }

  // Date Validation
  const { validArrival, validDeparture } = validateDates({
    arrival,
    departure,
  })

  // Guest Validation
  const { validAdults, validKids } = validateGuests({
    adults,
    kids,
  })

  const validatedParams = {
    arrival: formatDate(validArrival),
    departure: formatDate(validDeparture),
    adults: validAdults.toString(),
    kids: validKids.toString(),
    ...utmParams,
  }

  if (eventId) validatedParams['eventId'] = eventId

  if (tripId) validatedParams['tripId'] = tripId

  const searchParams = createSearchParams(validatedParams)

  const hotelDetailUrlWithoutParams = `/${destinationsSegment}/${getEncodedStateAbbreviation()}/${encodedCity}${hotelsPath}/${id}/${convertedTitle}`
  if (excludeSearchParams) return hotelDetailUrlWithoutParams
  return `${hotelDetailUrlWithoutParams}?${searchParams}`
}
