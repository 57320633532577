import type { DatesType, GeocoderType } from 'src/constants/user'
import {
  generateExperiencesResultsUrl,
  updateExperiencesResultsUrl,
} from 'src/utils'

interface ConstructArguments {
  dates: DatesType
  geocoder: GeocoderType
  searchParams: URLSearchParams
  willUpdate?: boolean
}

const constructArguments = ({
  dates,
  geocoder,
  searchParams,
  willUpdate = false,
}: ConstructArguments): string => {
  const [arrival, departure] = dates
  const { center, placeId, placeName } = geocoder
  const [latitude, longitude] = center
  const updatedArguments = {
    arrival,
    departure,
    latitude,
    longitude,
    location: placeName,
  }

  searchParams.delete('placeId') // TODO: look into this later

  if (willUpdate) {
    return updateExperiencesResultsUrl({
      overrides: updatedArguments,
      placeIdValue: placeId ?? '',
      searchParams,
    })
  }

  return generateExperiencesResultsUrl(updatedArguments)
}

export { constructArguments }
