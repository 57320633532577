import { Icon } from '@travelpass/design-system'
import { EventType } from 'src/__generated__/graphql'
import { getReviewAverage, getReviewTotal } from './resultsCardReviewsUtils'
import type { ResultsCardReviewsProps } from './types'

const initialMaxAverage = 10

const ResultsCardReviews = ({
  average,
  total,
  type,
  variant = 'default',
}: ResultsCardReviewsProps) => {
  /* maxAverage will always be 5 unless a 'type' prop is explicitly passed in,
  therefore the 'type' prop needs to be non-optional */
  const maxAverage = type === EventType.Stay ? 10 : 5
  const denominator = initialMaxAverage / maxAverage
  const averageTitle = getReviewAverage({
    average,
    denominator,
    total,
    variant,
  })
  const shouldBeTeal = average >= 7 / denominator
  const totalWithText = getReviewTotal({ total, variant })

  if (!average && !totalWithText) return

  if (variant === 'featured')
    return (
      <p className='color-white m-0 flex items-center gap-1'>
        <span className='b-solid b-white b-1 rounded-1 flex items-center gap-1 px-2 py-0.5'>
          <span className='color-white type-link-mobile'>
            {average}/{maxAverage}
          </span>
          <Icon name='thumbUpAlt' size='small' />
        </span>
        <span className='color-white type-body-2-mobile'>{totalWithText}</span>
      </p>
    )

  return (
    <p className='m-0 flex items-center gap-1'>
      <span
        className={`color-white rounded-1 type-link-mobile min-w-fit px-2 py-1 ${shouldBeTeal ? 'bg-teal-medium' : 'bg-grey-700'}`}
      >
        {average}
      </span>
      {!!averageTitle && (
        <span
          className={`type-body-2 min-w-fit ${shouldBeTeal ? 'color-teal-medium' : 'color-grey-700'}`}
        >
          {averageTitle}
        </span>
      )}
      <span className='color-grey-700 type-small-text-desktop grow truncate'>
        {totalWithText}
      </span>
    </p>
  )
}

export { ResultsCardReviews }
