import type { BookingRequest } from 'src/__generated__/graphql'

const suppliers = {
  '01W93G': 'bdc',
  '02NP6C': 'bdcpn',
  '11WA3K': 'bonotel',
  '03F09B': 'getaroom',
  '04EFZZ': 'sabre',
  '05MZ6H': 'ppncom',
  '06W93G': 'ppncug',
  '07E4J5': 'expedia',
  '08WVVM': 'priceline',
  '09X6JR': 'web_beds',
}

export const constructSupplier = (rateToken: BookingRequest['rateToken']) => {
  const supplierKey = rateToken?.replace('V-', '')?.split('_')[0]

  return suppliers?.[supplierKey] ?? ''
}
