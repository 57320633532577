import type { Dayjs } from 'dayjs'
import { createSearchParams } from 'react-router-dom'
import { destinationsSegment, experiencesPath } from 'src/constants'
import { getStateAbbreviation } from 'src/utils/stateUtils'
import { validateDates, formatDate } from './dateUtils'

interface generateExperienceDetailsUrlArgs {
  city?: string
  id: number | string
  name?: string
  state?: string
  stateAbbreviation?: string
  arrival?: string | Dayjs
  departure?: string | Dayjs
  eventId?: string
  tripId?: string
  utmParams?: Record<string, string>
  excludeSearchParams?: boolean
}

export const generateExperienceDetailsUrl = ({
  city,
  id,
  name,
  state,
  stateAbbreviation,
  arrival,
  departure,
  eventId,
  tripId,
  utmParams = {},
  excludeSearchParams = false,
}: generateExperienceDetailsUrlArgs): string => {
  const title =
    encodeURIComponent(
      [name, city, state]
        .filter(Boolean)
        .join(' ')
        .replace(/\s+/g, '-')
        /** @reference BOOK-2187 - URLs can't end in periods */
        .replace(/\./g, '')
    ) || 'Experience-Name'

  const encodedCity = encodeURIComponent(
    city ? city?.toLowerCase?.().split(' ').join('-') : 'city'
  )
  const getEncodedStateAbbreviation = () => {
    const stateAbbrevation = getStateAbbreviation(state, stateAbbreviation)
    if (stateAbbrevation === 'State Not Found') return 'state'
    return encodeURIComponent(stateAbbrevation.toLowerCase())
  }

  // Date Validation
  const { validArrival, validDeparture } = validateDates({
    arrival,
    departure,
    isSameDateRangeAllowed: true,
  })

  const validatedParams = {
    arrival: formatDate(validArrival),
    departure: formatDate(validDeparture),
    ...utmParams,
  }

  if (eventId) validatedParams['eventId'] = eventId

  if (tripId) validatedParams['tripId'] = tripId

  const searchParams = createSearchParams(validatedParams)

  const experienceDetailUrlWithoutParams = `/${destinationsSegment}/${getEncodedStateAbbreviation()}/${encodedCity}${experiencesPath}/${id}/${title}`
  if (excludeSearchParams) return experienceDetailUrlWithoutParams
  return `${experienceDetailUrlWithoutParams}?${searchParams}`
}
