const hotelAmenitiesButtonCss = 'text-right'

const hotelAmenitiesContentCss =
  'gap-x-2 gap-y-1.5 grid grid-cols-2 grid-flow-dense'

const hotelAmenitiesCss = 'flex flex-col gap-2 pb-8 md:pb-6'

const hotelAmenitiesItemCss = 'flex gap-2 items-center text-body-1'

const hotelAmenitiesTitleCss =
  'flex gap-2 items-center justify-between text-subtitle-2'

export {
  hotelAmenitiesButtonCss,
  hotelAmenitiesContentCss,
  hotelAmenitiesCss,
  hotelAmenitiesItemCss,
  hotelAmenitiesTitleCss,
}
