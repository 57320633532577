import { useState } from 'react'
import classNames from 'classnames'

interface ProductCardHeroImageProps {
  hasFirstImageLoaded?: boolean
  imageLink?: string | null
  index?: number
  name?: string
  onError(): void
  onImageClick(): void
  onLoad(): void
}

export const ProductCardHeroImage = ({
  hasFirstImageLoaded,
  imageLink,
  index,
  name,
  onError,
  onImageClick,
  onLoad,
}: ProductCardHeroImageProps) => {
  const [isLoading, setIsLoading] = useState(true)

  const onImgLoad = () => {
    onLoad()
    setIsLoading(false)
  }

  return (
    <div className={productCardHeroImageWrapperCss(isLoading)}>
      <img
        alt={`${name} hero #${index + 1}`}
        className={productCardHeroImageCss(isLoading)}
        draggable={false}
        loading={hasFirstImageLoaded ? 'eager' : 'lazy'}
        src={imageLink}
        onError={onError}
        onLoad={onImgLoad}
      />
      <button className={productCardHeroImageButtonCss} onClick={onImageClick}>
        <span className='sr-only'>
          Image {index + 1} of {name}
        </span>
      </button>
    </div>
  )
}

const productCardHeroImageCss = (hideImg: boolean) =>
  classNames('z-2 w-full h-full object-cover', {
    'opacity-100': !hideImg,
    'opacity-0': hideImg,
  })

const productCardHeroImageWrapperCss = (hideImg: boolean) =>
  classNames('flex flex-col h-41 bg-grey400', {
    'animate-pulse': hideImg,
  })

const productCardHeroImageButtonCss =
  'z-2 bg-transparent w-full h-full cursor-pointer block inset-0 overflow-hidden absolute whitespace-nowrap border-none'
