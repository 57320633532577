import type { ReactNode } from 'react'
import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface ScrollToTopProps {
  children: ReactNode
}

export const ScrollToTop = ({ children }: ScrollToTopProps) => {
  const { pathname, state } = useLocation()

  useLayoutEffect(() => {
    if (!state?.preventScroll) {
      const behavior = state?.enableSmoothScroll === true ? 'smooth' : undefined
      window?.scrollTo({
        top: 0,
        left: 0,
        behavior,
      })
    }
  }, [pathname, state])

  return <>{children}</>
}
