import { useRef, useState } from 'react'
import { Popover } from 'react-tiny-popover'
import type { GuestsType } from 'src/constants/user'
import { getTotalGuests } from 'src/utils'
import { GuestsPopover } from '../Guests'
import { ResultsInput } from '../ResultsInput'

interface ResultsGuestsProps {
  guests: GuestsType
  onChange(guests: GuestsType): void
}

export const ResultsGuests = ({ guests, onChange }: ResultsGuestsProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  const onKeyDown = event => {
    const { key } = event ?? {}

    if (key === 'Enter') setIsPopoverOpen(true)
  }

  const popoverContent = (
    <div
      className='rounded-5 shadow-1 w-78 mr--5 mt-2 bg-white px-7 py-7'
      data-testid='results-guests-popover'
    >
      <GuestsPopover guests={guests} onChange={onChange} />
    </div>
  )

  return (
    <Popover
      containerClassName='left-15! md:left-unset!'
      containerStyle={{ zIndex: '450' }}
      content={popoverContent}
      isOpen={isPopoverOpen}
      positions={['bottom']}
      ref={ref}
      onClickOutside={() => setIsPopoverOpen(false)}
    >
      {/* TODO: keep zIndex under 500 if possible */}
      {/* Removed 'all-[button>i]:color-black' since it was conflicting with IconButton.tsx styles */}
      <div className='z-2 all-[div>i]:color-new-forest flex w-full flex-col'>
        <ResultsInput
          fullWidth
          readOnly
          label='Guest'
          labelIcon='personOutline'
          name='guest'
          placeholder='Select'
          value={getTotalGuests({ guests })}
          onClick={() => setIsPopoverOpen(true)}
          onKeyDown={onKeyDown}
        />
      </div>
    </Popover>
  )
}
