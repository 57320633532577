import type { MouseEvent } from 'react'
import { useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { ProductCardBottom } from './ProductCardBottom'
import { ProductCardHero } from './ProductCardHero'
import { ProductCardTop } from './ProductCardTop'
import type { ProductCardData } from './types'

export interface ProductCardProps {
  productCardData: ProductCardData
  onProductCardClick?(product: ProductCardData): void
  containerCss?: string
}

export const ProductCard = ({
  productCardData,
  onProductCardClick = () => {},
  containerCss = '',
}: ProductCardProps) => {
  const [isCarouselDragging, setIsCarouselDragging] = useState(false)
  const { name, route } = productCardData ?? {}

  const onCardClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const willNavigate = !isCarouselDragging

    if (willNavigate) {
      onProductCardClick(productCardData)
    } else {
      event.preventDefault()
    }
  }

  return (
    <div className={classNames(productCardCss, containerCss)}>
      <ProductCardHero
        productCardData={productCardData}
        onCarouselDragEnd={() => setIsCarouselDragging(false)}
        onCarouselDragStart={() => setIsCarouselDragging(true)}
      />
      <ProductCardTop productCardData={productCardData} />
      <ProductCardBottom productCardData={productCardData} />
      <Link
        className={productCardLink}
        draggable='false'
        rel='noopener'
        target='_blank'
        to={route}
        onClick={onCardClick}
      >
        <span className='sr-only'>
          More information about {name}, opens in a new tab
        </span>
      </Link>
    </div>
  )
}
const productCardCss =
  'flex flex-col relative bg-white rounded-20px gap-3 shadow-1 text-inherit px-3 pt-3 pb-4 w-full min-w-64'

const productCardLink =
  'z-1 bg-none block h-full rounded-20px block w-full inset-0 outline-none overflow-hidden absolute whitespace-nowrap transition-shadow duration-200 ease-linear focus:shadow-2 focus:border-2 focus:border-red-500 hover:shadow-2'
