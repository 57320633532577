import { forwardRef, useCallback, useId, useMemo, useState } from 'react'
import type { ListBoxPosition } from '@travelpass/design-system'
import { KeyCode } from '@travelpass/design-system'
import classNames from 'classnames'
import { ActiveDescendantContext } from './ActiveDescendantContext'
import { useTrackActiveDescendant } from './useTrackActiveDescendant'
import type { ResultsInputProps } from '../ResultsInput'
import { ResultsInput } from '../ResultsInput'

interface ResultsAutocompleteProps extends ResultsInputProps {
  children: React.ReactNode
  autoExpand?: boolean
  listboxPosition?: ListBoxPosition
  value?: string
  onOptionSelect?: (value: unknown) => void
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

/**
 * Autocomplete
 *
 * @summary Use this component to create an accessible autocomplete input with a list of options.
 *
 * @param {boolean} props.autoExpand - controls whether the listbox is expanded on focus
 * @param {boolean} props.listboxPosition - the position of the listbox relative to the input
 * @param {string} [props.value] - the value of the input, optional.
 * @param {(value: unknown) => void} props.onOptionSelect - use onOptionSelect to handle when an option is selected either with mouse or keyboard. When using onOptionSelect, consider providing the correct type for the value, eg. `const onOptionSelect = (value: ExpectedValueType) => {}`
 * @param {React.KeyboardEventHandler<HTMLInputElement>} props.onKeyDown - use onKeyDown to react to keydown events on the autocomplete input
 *
 * Props not listed are forwarded to the underlying input component.
 * This component is patterned after the [WAI ARIA Combobox autocomplete list example](https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-autocomplete-list/).
 */
export const ResultsAutocomplete = forwardRef<
  HTMLInputElement,
  ResultsAutocompleteProps
>(function ResultsAutocompleteComponent(
  {
    id,
    children,
    label,
    autoExpand = false,
    listboxPosition = 'bottom',
    value,
    onBlur,
    onFocus,
    onKeyDown,
    onOptionSelect,
    ...remainingProps
  },
  comboboxRef
) {
  const autoId = useId()
  const comboboxId = id || autoId
  const listboxId = `${comboboxId}-listbox`
  const [expanded, setExpanded] = useState(false)

  const [domNode, setDomNode] = useState<HTMLInputElement>()
  const listboxRefCb = useCallback(domNode => {
    setDomNode(domNode)
  }, [])
  const activeDescendant = useTrackActiveDescendant({ element: domNode })

  const activeDescendantState = useMemo(() => {
    return {
      checkIfActive: activeDescendant.check,
      onOptionSelect: (id, newValue) => {
        activeDescendant.update(id)
        onOptionSelect?.(newValue)
      },
    }
  }, [domNode, activeDescendant.check, onOptionSelect])

  const trackActiveDescendant = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (expanded) {
      if (e.key === KeyCode.DOWN) {
        e.preventDefault()
        activeDescendant.next()
      } else if (e.key === KeyCode.UP) {
        e.preventDefault()
        activeDescendant.prev()
      } else if (e.key === KeyCode.ESC) {
        setExpanded(false)
        activeDescendant.clear()
      } else if (e.key === KeyCode.ENTER) {
        activeDescendant.click()
      }
    } else {
      if (e.key === KeyCode.ENTER && e.altKey) {
        setExpanded(true)
      }
    }
  }

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    trackActiveDescendant(e)
    onKeyDown?.(e)
  }

  const onFocusHandler = e => {
    setExpanded(autoExpand)
    onFocus?.(e)
  }

  const onBlurHandler = e => {
    setExpanded(false)
    onBlur?.(e)
  }

  return (
    <div
      className={`flex flex-col ${remainingProps.fullWidth ? 'w-full' : ''}`}
    >
      <ResultsInput
        {...remainingProps}
        fullWidth
        aria-activedescendant={activeDescendant.current ?? undefined}
        aria-autocomplete='list'
        aria-controls={listboxId}
        aria-expanded={expanded}
        autoComplete='off'
        id={comboboxId}
        label={label}
        labelIcon='placeOutline'
        ref={comboboxRef}
        role='combobox'
        value={value}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        onKeyDown={onKeyDownHandler}
      />
      <div className='relative flex flex-col'>
        {/* TODO: import ActiveDescendantContext from @travelpass/design-system */}
        <ActiveDescendantContext.Provider value={activeDescendantState}>
          {expanded && (
            <ul
              aria-labelledby={comboboxId}
              className={classNames(
                'b-grey-300 b-solid b-1 rd-3 z-1 absolute left-0 right-0 m-0 list-none space-y-4 overflow-hidden bg-white px-0 py-4 empty:hidden',
                {
                  'bottom-14': listboxPosition === 'top',
                  'top-2': listboxPosition === 'bottom',
                }
              )}
              id={listboxId}
              ref={listboxRefCb}
              role='listbox'
            >
              {children}
            </ul>
          )}
        </ActiveDescendantContext.Provider>
      </div>
    </div>
  )
})

ResultsAutocomplete.displayName = 'Autocomplete'
