import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect'
import type { BookingOccupancy, Options } from 'src/__generated__/graphql'
import type {
  GuestsType,
  GeocoderType,
  DatesType,
  GeocoderData,
} from 'src/constants/user'
import {
  nativeAppUserKey,
  initialAdultGuests,
  initialArrivalDate,
  initialDepartureDate,
  initialGeocoderLatitude,
  initialGeocoderLongitude,
  initialGeocoderPlaceName,
  initialKidGuests,
  searchChildAge,
  minAdultGuests,
  maxAdultGuests,
  minKidGuests,
  maxKidGuests,
} from 'src/constants/user'
import { lucencyIdFromCookie } from './cookies'
import { GRAPHQL_DATE_FMT, getDateDiff } from './dateUtils'
import { stringToNumber } from './stringUtils'

interface TotalGuests {
  guests: GuestsType
  hideLabel?: boolean
  isLowerCase?: boolean
}

interface TotalNights {
  arrival: string
  departure: string
  hideLabel?: boolean
  isLowerCase?: boolean
}

interface TotalRooms {
  hideLabel?: boolean
  isLowerCase?: boolean
  rooms: number
}

const checkIsNativeAppUser = () => !!sessionStorage.getItem(nativeAppUserKey)

const constructDates = ({
  arrival,
  departure,
  timezone,
}: {
  arrival: null | string | Dayjs
  departure: null | string | Dayjs
  timezone?: string
}): DatesType => [
  dayjs(getArrivalDate({ date: arrival, timezone })),
  dayjs(getDepartureDate({ date: departure, timezone })),
]

const constructGeocoder = ({
  latitude,
  longitude,
  placeId = '',
  placeName,
}: GeocoderData): GeocoderType => {
  const validData = {
    latitude: initialGeocoderLatitude,
    longitude: initialGeocoderLongitude,
  }

  if (latitude > 90) {
    validData.latitude = 90
  } else if (latitude < -90) {
    validData.latitude = -90
  } else {
    validData.latitude = latitude
  }

  if (longitude > 180) {
    validData.longitude = 180
  } else if (longitude < -180) {
    validData.longitude = -180
  } else {
    validData.longitude = longitude
  }

  return {
    center: [
      getGeocoderLatitude(validData.latitude),
      getGeocoderLongitude(validData.longitude),
    ],
    placeId: placeId ?? '',
    placeName: getGeocoderPlaceName(placeName),
  }
}
const constructGuests = ({ adults, kids }): GuestsType => {
  const { validAdults, validKids } = validateGuests({ adults, kids })
  return [validAdults, validKids]
}
const getAdultGuests = (adults?: null | number | string) => {
  if (typeof adults === 'string' && !isNaN(parseInt(adults))) {
    return parseInt(adults)
  } else if (typeof adults === 'number') {
    return adults
  }
  return initialAdultGuests
}

const getArrivalDate = ({
  date,
  timezone,
  format = GRAPHQL_DATE_FMT,
}: {
  date?: null | string | Dayjs
  timezone?: string
  format?: string
}) => {
  let arrivalDate = date ? dayjs(date) : initialArrivalDate
  if (arrivalDate?.tz && timezone) arrivalDate = arrivalDate.tz(timezone)
  return arrivalDate?.format
    ? arrivalDate.format(format)
    : arrivalDate.toString()
}

const getDepartureDate = ({
  date,
  timezone,
  format = GRAPHQL_DATE_FMT,
}: {
  date?: null | string | Dayjs
  timezone?: string
  format?: string
}) => {
  let departureDate = date ? dayjs(date) : initialDepartureDate
  if (departureDate?.tz && timezone) departureDate = departureDate.tz(timezone)
  return departureDate?.format
    ? departureDate.format(format)
    : departureDate.toString()
}

const getGeocoderLatitude = (latitude?: null | number | string) => {
  if (typeof latitude === 'string' && !isNaN(parseFloat(latitude))) {
    return parseFloat(latitude)
  } else if (typeof latitude === 'number' && !isNaN(latitude)) {
    return latitude
  }
  return initialGeocoderLatitude
}

const getGeocoderLongitude = (longitude?: null | number | string) => {
  if (typeof longitude === 'string' && !isNaN(parseFloat(longitude))) {
    return parseFloat(longitude)
  } else if (typeof longitude === 'number' && !isNaN(longitude)) {
    return longitude
  } else {
    return initialGeocoderLongitude
  }
}

const getGeocoderPlaceCountryFromAddressComponents = (
  addressComponents: GeocoderType['addressComponents']
) =>
  addressComponents?.find(({ types }) => types.includes('country'))
    ?.long_name ?? ''

const getGeocoderPlaceName = (placename?: null | string) =>
  placename || initialGeocoderPlaceName

const getGeocoderPlaceTypeFromAddressComponents = (
  addressComponents: GeocoderType['addressComponents']
) => {
  const types: {} = addressComponents?.reduce(
    (total, { short_name: shortName, types }) => {
      if (types?.includes('administrative_area_level_1')) {
        total['administrative_area_level_1'] = {
          shortName,
          type: 'administrative_area_level_1',
        }
      } else if (types?.includes('administrative_area_level_2')) {
        total['administrative_area_level_2'] = {
          shortName,
          type: 'administrative_area_level_2',
        }
      } else if (types?.includes('colloquial_area')) {
        total['colloquial_area'] = {
          shortName,
          type: 'colloquial_area',
        }
      } else if (types?.includes('country')) {
        total['country'] = {
          shortName,
          type: 'country',
        }
      } else if (types?.includes('locality')) {
        total['locality'] = {
          shortName,
          type: 'locality',
        }
      } else if (types?.includes('neighborhood')) {
        total['neighborhood'] = {
          shortName,
          type: 'neighborhood',
        }
      } else if (types?.includes('park')) {
        total['park'] = {
          shortName,
          type: 'park',
        }
      } else if (types?.includes('street_address')) {
        total['street_address'] = {
          shortName,
          type: 'street_address',
        }
      } else if (types?.includes('street_number')) {
        total['street_number'] = {
          shortName,
          type: 'street_number',
        }
      } else if (types?.includes('sublocality')) {
        total['sublocality'] = {
          shortName,
          type: 'sublocality',
        }
      }
      return total
    },
    {}
  )

  if (types?.['street_address']) return types?.['street_address']

  if (types?.['street_number']) return types?.['street_number']

  if (types?.['neighborhood']) return types?.['neighborhood']

  if (types?.['sublocality']) return types?.['sublocality']

  if (types?.['colloquial_area']) return types?.['colloquial_area']

  if (types?.['park']) return types?.['park']

  if (types?.['locality']) return types?.['locality']

  if (types?.['administrative_area_level_2'])
    return types?.['administrative_area_level_2']

  if (types?.['administrative_area_level_1'])
    return types?.['administrative_area_level_1']

  if (types?.['country']) return types?.['country']

  return {}
}

const getKidGuests = (kids?: null | number | string) => {
  const { validKids } = validateGuests({ kids: kids ?? 0 })
  return validKids
}
const getKidGuestsAges = (kidGuests?: string) =>
  Array(getKidGuests(kidGuests)).fill(searchChildAge)

const getRateOptions = (
  isAnonymous: boolean,
  checkIsNativeAppUser?: boolean
): Options | null => {
  if (checkIsNativeAppUser || (!isAnonymous && isMobile)) {
    return { additionalRateTypes: ['cug', 'mobile'] }
  }

  if (isAnonymous && !isMobile) return {}

  if (isAnonymous && isMobile) {
    return { additionalRateTypes: ['mobile'] }
  }

  return { additionalRateTypes: ['cug'] }
}

const getTotalGuests = ({
  guests,
  hideLabel = false,
  isLowerCase = false,
}: TotalGuests) => {
  const totalGuests =
    guests?.reduce(
      (previousValue, currentValue) => previousValue + currentValue
    ) ?? 1
  const label = hideLabel ? '' : totalGuests > 1 ? ' Guests' : ' Guest'
  const updatedLabel = isLowerCase ? label.toLowerCase() : label

  return `${totalGuests}${updatedLabel}`
}

const getTotalNights = ({
  arrival,
  departure,
  hideLabel = false,
  isLowerCase = false,
}: TotalNights) => {
  const totalNights = getDateDiff([departure, arrival])
  const label = hideLabel ? '' : totalNights > 1 ? ' Nights' : ' Night'
  const updatedLabel = isLowerCase ? label.toLowerCase() : label

  return `${totalNights}${updatedLabel}`
}

const getTotalRooms = ({
  rooms,
  hideLabel = false,
  isLowerCase = false,
}: TotalRooms) => {
  const validRooms = rooms < 1 || !rooms ? 1 : rooms
  const label = hideLabel ? '' : validRooms > 1 ? ' Rooms' : ' Room'
  const updatedLabel = isLowerCase ? label.toLowerCase() : label

  return `${validRooms}${updatedLabel}`
}

const getTotalRoomsGuests = ({
  hideLabel,
  occupancies = [],
}: {
  hideLabel?: boolean
  occupancies: BookingOccupancy[]
}) => {
  const totalGuests =
    occupancies?.reduce((previousValue, currentValue) => {
      const { adults, childAges } = currentValue ?? {}
      const constructedChildAges = childAges?.length ?? 0
      previousValue += (adults ?? 0) + constructedChildAges

      return previousValue
    }, 0) ?? 1

  const label = hideLabel ? '' : totalGuests > 1 ? ' Guests' : ' Guest'

  return `${totalGuests}${label}`
}

const getTracker = (geocoderTypePlaceName: string): string =>
  lucencyIdFromCookie || geocoderTypePlaceName

const validateGuests = ({
  adults,
  kids,
}: {
  adults?: number | string
  kids?: number | string
}): {
  validAdults: number
  validKids: number
} => {
  const adultsInt = parseInt(String(adults))
  const kidsInt = parseInt(String(kids))

  const validGuests = {
    validAdults: initialAdultGuests,
    validKids: initialKidGuests,
  }

  if (adultsInt) {
    if (adultsInt >= maxAdultGuests) {
      validGuests.validAdults = maxAdultGuests
    } else if (adultsInt <= minAdultGuests) {
      validGuests.validAdults = minAdultGuests
    } else {
      validGuests.validAdults = adultsInt
    }
  }

  if (kidsInt) {
    if (kidsInt >= maxKidGuests) {
      validGuests.validKids = maxKidGuests
    } else if (kidsInt <= minKidGuests) {
      validGuests.validKids = minKidGuests
    } else {
      validGuests.validKids = kidsInt
    }
  }

  return validGuests
}

const validateMapZoom = (mapZoom: number | boolean | string) => {
  const minMapZoom = 1
  const mapZoomInt = parseInt(String(mapZoom)) || false
  const maxMapZoom = 22

  if (mapZoomInt) {
    if (mapZoomInt >= maxMapZoom) return maxMapZoom
    if (mapZoomInt <= minMapZoom) return minMapZoom
  }

  return mapZoomInt
}

const validatePlaceId = (placeId: string) => placeId?.toString() || ''

const validateRadius = (radius: string): string => {
  const minRadius = 20
  const radiusInt = stringToNumber(radius)
  const maxRadius = 5000

  if (radiusInt) {
    if (radiusInt >= maxRadius) return maxRadius.toString()
    if (radiusInt <= minRadius) return ''
  }

  return radiusInt?.toString() ?? ''
}

export {
  checkIsNativeAppUser,
  constructDates,
  constructGeocoder,
  constructGuests,
  getAdultGuests,
  getArrivalDate,
  getDepartureDate,
  getGeocoderLatitude,
  getGeocoderLongitude,
  getGeocoderPlaceCountryFromAddressComponents,
  getGeocoderPlaceName,
  getGeocoderPlaceTypeFromAddressComponents,
  getKidGuests,
  getKidGuestsAges,
  getRateOptions,
  getTotalGuests,
  getTotalNights,
  getTotalRooms,
  getTotalRoomsGuests,
  getTracker,
  validateGuests,
  validateMapZoom,
  validatePlaceId,
  validateRadius,
}
