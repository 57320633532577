import isEmpty from 'lodash.isempty'
import {
  constructCheckInInstructions,
  constructFees,
  constructPolicies,
  constructRatePolicies,
  constructSpecialCheckInInstructions,
} from 'src/utils'
import {
  checkInInstructionsTitle,
  checkInSpecialInstructionsTitle,
  feesTitle,
  ratePoliciesTitle,
} from './hotelPoliciesConstants'
import {
  hotelPolicyContentClasses,
  hotelPolicyTitleClasses,
} from './hotelPoliciesStyles'
import type { HotelPoliciesProps } from './types'

interface HotelPolicyItem {
  isHidden?: boolean
  text?: string | string[]
  title?: string
}

const HotelPolicies = ({
  checkinInstructions = [],
  checkinSpecialInstructions = [],
  contentType = 'type-body-2',
  fees = [],
  policies = [],
  ratePolicies = [],
  showExcerpt = false,
  titleType = 'type-h5',
}: HotelPoliciesProps) => {
  const constructedTitle = (title: string) => title.replace(/_/g, ' ')
  const constructedCheckInInstructions =
    constructCheckInInstructions(checkinInstructions)
  const constructedSpecialCheckInInstructions =
    constructSpecialCheckInInstructions(checkinSpecialInstructions)
  const constructedFees = constructFees(fees)
  const constructedPolicies = constructPolicies(policies)
  const constructedRatePolicies = constructRatePolicies({
    policies,
    ratePolicies,
  })

  const constructItems: HotelPolicyItem[] = [
    ...constructedPolicies,
    {
      isHidden: isEmpty(checkinInstructions),
      text: constructedCheckInInstructions,
      title: checkInInstructionsTitle,
    },
    {
      isHidden: isEmpty(constructedSpecialCheckInInstructions),
      text: constructedSpecialCheckInInstructions,
      title: checkInSpecialInstructionsTitle,
    },
    {
      isHidden: isEmpty(constructedRatePolicies),
      text: constructedRatePolicies,
      title: ratePoliciesTitle,
    },
    {
      isHidden: isEmpty(constructedFees),
      text: constructedFees,
      title: feesTitle,
    },
  ]

  const constructedItemText = ({
    contentType,
    text,
  }: {
    contentType: HotelPoliciesProps['contentType']
    text: HotelPolicyItem['text']
  }) => {
    const textItems = Array.isArray(text) ? text : [text]

    return textItems.map((textItem, index) => (
      <p
        key={`${text}-${index}`}
        className={hotelPolicyContentClasses({ contentType, showExcerpt })}
      >
        {textItem}
      </p>
    ))
  }

  const constructedItems = constructItems.map(
    ({ isHidden, text, title }, index) =>
      !isHidden && (
        <div key={`${title}-${index}`} className='space-y-2'>
          {title && (
            <h3 className={hotelPolicyTitleClasses({ titleType })}>
              {constructedTitle(title)}
            </h3>
          )}
          {constructedItemText({ contentType, text })}
        </div>
      )
  )

  return <div className='space-y-5'>{constructedItems}</div>
}

export { HotelPolicies }
