import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import type { GeocodeResult } from 'use-places-autocomplete'

type DatesType = [Dayjs, Dayjs]
type GeocoderType = {
  addressComponents?: GeocodeResult['address_components']
  center: number[]
  placeId?: string
  placeName: string
  types?: string[]
  viewport?: google.maps.GeocoderGeometry['viewport']
}
type GuestsType = number[]

interface GeocoderData {
  latitude: number
  longitude: number
  placeId: string
  placeName: string
}
interface DatesData {
  arrival: string
  departure: string
}
const initialAdultGuests = 2
const initialArrivalDate: Dayjs = dayjs()
const initialDepartureDate: Dayjs = dayjs().add(1, 'd')
const initialDates: DatesType = [initialArrivalDate, initialDepartureDate]
const initialKidGuests = 0
const initialGeocoder: GeocoderType = {
  center: [],
  placeName: '',
}
const initialGeocoderLatitude = 40.7484
const initialGeocoderLongitude = -73.9857
const initialStreetNumber = '20'
const initialRoute = 'W 34th Street'
const initialSublocality = 'New York City'
const initialAdminstrativeAreaLvl1 = 'New York'
const initialPostalCode = '10001'
const initialCountry = 'United States of America'
const initialGeocoderPlaceName = `${initialStreetNumber} ${initialRoute}, ${initialSublocality}, ${initialAdminstrativeAreaLvl1} ${initialPostalCode}, ${initialCountry}`
const initialGuests: GuestsType = [initialAdultGuests, initialKidGuests]
const initialRooms = 1
const todayDate = dayjs()
const maxAdultGuests = 4
const maxDate = todayDate.add(365, 'd')
const maxKidGuests = 4
const maxReviewScore = 10
const minAdultGuests = 1
const minKidGuests = 0
const nativeAppUserKey = 'nativeAppUser'
const searchChildAge = 5

export type { DatesType, GeocoderType, GuestsType, GeocoderData, DatesData }
export {
  initialAdultGuests,
  initialArrivalDate,
  initialDates,
  initialDepartureDate,
  initialGeocoder,
  initialGeocoderLatitude,
  initialGeocoderLongitude,
  initialGeocoderPlaceName,
  initialStreetNumber,
  initialRoute,
  initialAdminstrativeAreaLvl1,
  initialCountry,
  initialPostalCode,
  initialGuests,
  initialKidGuests,
  initialRooms,
  maxAdultGuests,
  maxDate,
  maxKidGuests,
  maxReviewScore,
  minAdultGuests,
  minKidGuests,
  nativeAppUserKey,
  searchChildAge,
  todayDate,
}
