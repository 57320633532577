import { useEffect } from 'react'

import { useSnackbar } from '@travelpass/design-system'

interface StatusSnackBarsProps {
  isError?: boolean
  isLoading?: boolean
  isSuccess?: boolean
  errorMessage?: string | null
  loadingMessage?: string | null
  successMessage?: string | null
}

export const useStatusSnackbars = ({
  isError,
  isLoading,
  isSuccess,
  errorMessage = 'Error',
  loadingMessage = 'Loading',
  successMessage = 'Success',
}: StatusSnackBarsProps) => {
  const { addSuccessSnack, addErrorSnack } = useSnackbar()

  useEffect(() => {
    if (isError) {
      addErrorSnack({ actionText: 'ERROR', title: errorMessage })
    }
    if (isLoading) {
      addSuccessSnack({ actionText: 'LOADING', title: loadingMessage })
    }
    if (isSuccess) {
      addSuccessSnack({ actionText: 'SUCCESS', title: successMessage })
    }
  }, [isError, isLoading, isSuccess])
}
