import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import { SortBy } from 'src/__generated__/graphql'
import type { FilterBy } from 'src/__generated__/graphql'
import { initialFilters } from 'src/constants'
import { stringToBoolean } from './stringUtils'
const MAX_PRICE_OFFSET = 5000

interface validateCustomerReviewScoreArgs {
  minScore?: number | string
  maxScore?: number | string
}

interface validateCustomerReviewScoreResponse {
  validMinScore: string
  validMaxScore: string
  resetToDefaultCustomerReviewScore: boolean
}

export const validateCustomerReviewScore = ({
  minScore,
  maxScore,
}: validateCustomerReviewScoreArgs): validateCustomerReviewScoreResponse => {
  const { minScore: initMinScore, maxScore: initMaxScore } = cloneDeep<{
    maxScore: string
    minScore: string
    // @ts-ignore - Why are we cloning this??
  }>(initialFilters.customerReviewScore)
  const minScoreFloat = parseFloat(String(minScore))
  const maxScoreFloat = parseFloat(String(maxScore))
  const initMinScoreFloat = parseFloat(initMinScore)
  const initMaxScoreFloat = parseFloat(initMaxScore)

  const validCustomerReviewScore = {
    validMinScore: initMinScore,
    validMaxScore: initMaxScore,
    resetToDefaultCustomerReviewScore: true,
  }

  if (minScoreFloat) {
    if (minScoreFloat < initMinScoreFloat) {
      validCustomerReviewScore.validMinScore = initMinScore
    } else if (minScoreFloat > initMaxScoreFloat) {
      validCustomerReviewScore.validMinScore = initMaxScore
    } else {
      validCustomerReviewScore.validMinScore = minScoreFloat.toFixed(1)
    }
  }

  if (maxScoreFloat) {
    if (maxScoreFloat < initMinScoreFloat) {
      validCustomerReviewScore.validMaxScore = initMinScore
    } else if (maxScoreFloat > initMaxScoreFloat) {
      validCustomerReviewScore.validMaxScore = initMaxScore
    } else {
      validCustomerReviewScore.validMaxScore = maxScoreFloat.toFixed(1)
    }
  }

  if (
    parseFloat(validCustomerReviewScore.validMinScore) >
    parseFloat(validCustomerReviewScore.validMaxScore)
  ) {
    validCustomerReviewScore.validMinScore = maxScoreFloat.toFixed(1)
    validCustomerReviewScore.validMaxScore = minScoreFloat.toFixed(1)
  }

  validCustomerReviewScore.resetToDefaultCustomerReviewScore =
    validCustomerReviewScore.validMinScore === initMinScore &&
    validCustomerReviewScore.validMaxScore === initMaxScore

  return validCustomerReviewScore
}

interface validateStarRatingArgs {
  minStars?: number | string
  maxStars?: number | string
}

interface validateStarRatingResponse {
  validMinStars: string
  validMaxStars: string
  resetToDefaultStarRating: boolean
}

export const validateStarRating = ({
  minStars,
  maxStars,
}: validateStarRatingArgs): validateStarRatingResponse => {
  const { minStars: initMinStars, maxStars: initMaxStars } = cloneDeep<{
    minStars: string
    maxStars: string
    // @ts-ignore - Why are we cloning this?
  }>(initialFilters.starRating)
  const minStarFloat = parseFloat(String(minStars))
  const maxStarFloat = parseFloat(String(maxStars))
  const initMinStarFloat = parseFloat(initMinStars)
  const initMaxStarFloat = parseFloat(initMaxStars)

  const validStarRating = {
    validMinStars: initMinStars,
    validMaxStars: initMaxStars,
    resetToDefaultStarRating: true,
  }

  if (minStarFloat) {
    if (minStarFloat < initMinStarFloat) {
      validStarRating.validMinStars = initMinStars
    } else if (minStarFloat > initMaxStarFloat) {
      validStarRating.validMinStars = initMaxStars
    } else {
      validStarRating.validMinStars = minStarFloat.toFixed(1)
    }
  }

  if (maxStarFloat) {
    if (maxStarFloat < initMinStarFloat) {
      validStarRating.validMaxStars = initMinStars
    } else if (maxStarFloat > initMaxStarFloat) {
      validStarRating.validMaxStars = initMaxStars
    } else {
      validStarRating.validMaxStars = maxStarFloat.toFixed(1)
    }
  }

  if (
    parseFloat(validStarRating.validMinStars) >
    parseFloat(validStarRating.validMaxStars)
  ) {
    validStarRating.validMinStars = maxStarFloat.toFixed(1)
    validStarRating.validMaxStars = minStarFloat.toFixed(1)
  }

  validStarRating.resetToDefaultStarRating =
    validStarRating.validMinStars === initMinStars &&
    validStarRating.validMaxStars === initMaxStars

  return validStarRating
}

interface validatePriceRangeArgs {
  minPrice?: number | string
  maxPrice?: number | string
}

interface validatePriceRangeResponse {
  validMinPrice: number
  validMaxPrice: number
  resetToDefaultPriceRange: boolean
}

export const validatePriceRange = ({
  minPrice,
  maxPrice,
}: validatePriceRangeArgs): validatePriceRangeResponse => {
  // convert the recieved inputs to integers
  const minPriceInt = parseInt(String(minPrice))
  const maxPriceInt = parseInt(String(maxPrice))

  // clone the default priceRange values
  const { minPrice: initMinPrice, maxPrice: initMaxPrice } = cloneDeep<{
    minPrice: number
    maxPrice: number
    // @ts-ignore - Why are cloning this?
  }>(initialFilters.priceRange)

  // construct an object of valid values, utilizing defaults
  const validPriceRange = {
    validMinPrice: initMinPrice,
    validMaxPrice: initMaxPrice,
    resetToDefaultPriceRange: true,
  }

  // if we receive a minPrice, validate it against the default (0)
  if (!isNaN(minPriceInt)) {
    // if less than default, set validated min to default min
    if (minPriceInt < initMinPrice) {
      validPriceRange.validMinPrice = initMinPrice
      // if its more than default, and less than received max, set the validated min to recieved min
    } else {
      validPriceRange.validMinPrice = minPriceInt
    }
  }

  // if we receive a maxPrice, validate it against the default (0)
  if (!isNaN(maxPriceInt)) {
    // if the received max is < default min, set validated max to default min
    if (maxPriceInt < initMinPrice) {
      validPriceRange.validMaxPrice = initMaxPrice
      // if its more than defaul min set the validated max to recieved max
    } else {
      validPriceRange.validMaxPrice = maxPriceInt
    }
  }

  // if the validated min > validated max switch the values
  if (validPriceRange.validMaxPrice && validPriceRange.validMinPrice) {
    if (validPriceRange.validMinPrice > validPriceRange.validMaxPrice) {
      validPriceRange.validMinPrice = maxPriceInt
      validPriceRange.validMaxPrice = minPriceInt
    } else if (
      validPriceRange.validMinPrice === validPriceRange.validMaxPrice
    ) {
      validPriceRange.validMinPrice = minPriceInt
      validPriceRange.validMaxPrice = minPriceInt + MAX_PRICE_OFFSET
    }
  }

  validPriceRange.resetToDefaultPriceRange =
    validPriceRange.validMinPrice === initMinPrice &&
    validPriceRange.validMaxPrice === initMaxPrice

  // return the validated price range
  return validPriceRange
}

interface validateFilterBrandsResponse {
  validatedBrands: String[]
  brandsResetToDefault: boolean
}

export const validateFilterBrands = (
  brands?: (string | null)[] | null
): validateFilterBrandsResponse => {
  const validResponse: {
    validatedBrands: string[]
    brandsResetToDefault: boolean
  } = {
    validatedBrands: [],
    brandsResetToDefault: true,
  }

  const defaultBrands = initialFilters.brands
  if (
    !isEmpty(brands) &&
    JSON.stringify(defaultBrands) !== JSON.stringify(brands)
  ) {
    validResponse.validatedBrands =
      brands?.filter(
        (b: string | null | undefined): b is string =>
          b !== null && b !== undefined
      ) ?? []
    validResponse.brandsResetToDefault = false
  }

  return validResponse
}

interface validateFiltersAndSortArgs {
  filters?: FilterBy
  sort?: SortBy
}

interface validateFiltersAndSortResponse {
  validFilters: FilterBy
  validSort: SortBy
  sortResetToDefault: boolean
}

/**
 * Validates filters and sort
 * if filters are same as default values, they are removed
 * if sort is same as Sortby.Distance (the default value)
 * then sortResetToDefault = true
 */
export const validateFiltersAndSort = ({
  filters,
  sort,
}: validateFiltersAndSortArgs): validateFiltersAndSortResponse => {
  const validResponse = {
    validFilters: {},
    validSort: SortBy.Distance,
    sortResetToDefault: true,
  }

  if (!!sort && sort !== validResponse.validSort) {
    validResponse.validSort = sort
    validResponse.sortResetToDefault = false
  }

  const { brands, customerReviewScore, priceRange, starRating } = filters ?? {}

  const { validatedBrands, brandsResetToDefault } = validateFilterBrands(brands)

  const { minScore, maxScore } = customerReviewScore ?? {}
  const { validMinScore, validMaxScore, resetToDefaultCustomerReviewScore } =
    validateCustomerReviewScore({
      minScore: minScore ?? '0',
      maxScore: maxScore ?? '0',
    })

  const { minPrice, maxPrice } = priceRange ?? {}
  const { validMinPrice, validMaxPrice, resetToDefaultPriceRange } =
    validatePriceRange({
      minPrice: minPrice ?? '0',
      maxPrice: maxPrice ?? '0',
    })

  const { minStars, maxStars } = starRating ?? {}
  const { validMinStars, validMaxStars, resetToDefaultStarRating } =
    validateStarRating({ minStars: minStars ?? '0', maxStars: maxStars ?? '0' })

  if (!isEmpty(filters)) {
    Object.keys(initialFilters).forEach(filter => {
      if (filter === 'brands' && !brandsResetToDefault) {
        validResponse.validFilters['brands'] = validatedBrands
      } else if (
        filter === 'customerReviewScore' &&
        !resetToDefaultCustomerReviewScore
      ) {
        validResponse.validFilters['customerReviewScore'] = {
          minScore: validMinScore,
          maxScore: validMaxScore,
        }
      } else if (filter === 'priceRange' && !resetToDefaultPriceRange) {
        if (validMaxPrice || validMinPrice) {
          validResponse.validFilters['priceRange'] = {
            minPrice: validMinPrice,
            maxPrice: validMaxPrice,
          }
        }
      } else if (filter === 'starRating' && !resetToDefaultStarRating) {
        validResponse.validFilters['starRating'] = {
          minStars: validMinStars,
          maxStars: validMaxStars,
        }
      } else {
        const filterValue = filters[filter] ?? 'false'
        const filterBool = stringToBoolean(filterValue)
        if (filterBool) validResponse.validFilters[filter] = filterBool
      }
    })
  }

  return validResponse
}
