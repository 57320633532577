import { useCallback, useRef, useEffect } from 'react'
import { IconButton, KeyCode } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import type { Suggestion } from 'use-places-autocomplete'
import type { GeocoderType } from 'src/constants/user'
import { initialGeocoder } from 'src/constants/user'
import { ResultsGeocoderOption } from './ResultsGeocoderOption'
import type { GeocoderProps } from '../Geocoder'
import { constructGeocoderOverride } from '../Geocoder/geocoderUtils'
import { useGeocoderSearch } from '../Geocoder/useGeocoderSearch'
import { ResultsAutocomplete } from '../ResultsAutocomplete'

export const ResultsGeocoder = ({
  label,
  checkTypes = false,
  focusOnInput = true,
  geocoder = {} as GeocoderType,
  helperText,
  errorText,
  isInvalid,
  isDisabled,
  listboxPosition,
  name,
  onBlur,
  placeholder,
  onResult,
  onClear,
  onClick,
  config,
  ...props
}: GeocoderProps) => {
  const defaultValue = geocoder?.placeName ?? ''
  const { ready, suggestions, value, setValue, clearSuggestions } =
    useGeocoderSearch({
      defaultValue,
      ...config,
    })
  const autocompleteRef = useRef<HTMLInputElement>(null)

  const { data: options } = suggestions ?? {}
  const showGeocoderOptions = value.trim() !== '' && !!options?.length

  const onKeyDown = event => {
    if (event.key === KeyCode.ESC) {
      clearSuggestions()
    }
    if (event.key === KeyCode.ENTER) {
      // prevent default to stop accidental form submit when user selects option with ENTER key
      event.preventDefault()

      if (showGeocoderOptions) onOptionSelect(options?.[0])
    }
  }

  const onChange = useCallback(
    debounce(e => {
      const value = e.target.value
      if (value === '') {
        onResult(initialGeocoder)
      }
      setValue(value, true)
    }, 300),
    []
  )

  const onOptionSelect = (selectedSuggestion: Suggestion) => {
    const address = selectedSuggestion.description
    autocompleteRef.current.value = address
    clearSuggestions()
    getGeocode({ address }).then(data => {
      const { place_id, types, address_components, geometry } = data?.[0]
      const { lat, lng } = getLatLng(data?.[0])
      const currentGeocoder = {
        addressComponents: address_components,
        center: [lat, lng],
        placeName: address,
        types,
        viewport: geometry?.viewport,
      }

      if (!checkTypes || types.includes('lodging'))
        currentGeocoder['placeId'] = place_id

      const constructedGeocoder = constructGeocoderOverride(currentGeocoder)

      onResult(constructedGeocoder)
    })
  }

  const onClearHandler = () => {
    setValue('', true)
    autocompleteRef.current.value = ''
    autocompleteRef.current?.focus() // return focus to input
    onClear?.()
    onResult(initialGeocoder)
  }

  const slotAfter = autocompleteRef.current?.value?.trim() !== '' && (
    <IconButton icon='clear' size='small' onClick={onClearHandler} />
  )

  useEffect(() => {
    setTimeout(() => {
      if (focusOnInput) autocompleteRef?.current?.focus()
    }, 300)
  }, [])

  useEffect(() => {
    // keep input value in sync with current geocoder placeName
    if (autocompleteRef.current) {
      autocompleteRef.current.value = geocoder?.placeName
    }
  }, [geocoder])

  return (
    ready && (
      <ResultsAutocomplete
        {...props}
        autoExpand
        autoComplete='off'
        defaultValue={defaultValue}
        errorText={errorText}
        helperText={helperText}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        label={label}
        listboxPosition={listboxPosition}
        name={name}
        placeholder={placeholder}
        ref={autocompleteRef}
        slotAfter={slotAfter}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onOptionSelect={onOptionSelect}
      >
        {showGeocoderOptions &&
          options.map(option => (
            <ResultsGeocoderOption key={option.place_id} option={option} />
          ))}
      </ResultsAutocomplete>
    )
  )
}
