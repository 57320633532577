import { useMemo } from 'react'
import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type {
  ExperienceActivityItinerary,
  ExperienceProduct,
  ExperienceStandardItinerary,
  ExperienceUnstructuredItinerary,
} from 'src/__generated__/graphql'
import { ExperienceCancellationType } from 'src/__generated__/graphql'
import { getLanguageNameByCode } from 'src/utils'
import {
  experiencesFeatureCss,
  experiencesFeaturesCss,
} from './experiencesFeaturesStyles'

const { Custom, Full } = ExperienceCancellationType ?? {}

interface ExperiencesFeaturesProps {
  experiencesProductData: ExperienceProduct
}

type ExperiencesFeature = {
  icon: AvailableIcons
  isHidden?: boolean
  title: string
}

const ExperiencesFeatures = ({
  experiencesProductData,
}: ExperiencesFeaturesProps) => {
  const { cancellationPolicy, languageGuides, ticketInfo } =
    experiencesProductData ?? {}
  const { duration } =
    (experiencesProductData?.itinerary as
      | ExperienceActivityItinerary
      | ExperienceStandardItinerary
      | ExperienceUnstructuredItinerary) ?? {}
  const { type: cancellationPolicyType } = cancellationPolicy ?? {}
  const { readableDurationRange } = duration ?? {}
  const { ticketTypeDescription } = ticketInfo ?? {}
  const features = useMemo<ExperiencesFeature[]>(() => {
    const hasFreeCancellation =
      cancellationPolicyType === Custom || cancellationPolicyType === Full
    // TODO: BE should return this
    const hasMobileTicket =
      ticketTypeDescription === 'Mobile or paper ticket accepted'
    const showLanguages = !isEmpty(languageGuides)

    // TODO: BE should return this
    const constructedLanguages = () => {
      if (!showLanguages) return ''

      try {
        const constructedFirstLanguage = getLanguageNameByCode(
          languageGuides?.[0]?.language
        )
        const title = `Offered in ${constructedFirstLanguage}`

        if (languageGuides.length > 1)
          return `${title} and ${languageGuides.length - 1} more`

        return title
      } catch (error) {
        return ''
      }
    }

    return [
      {
        icon: 'check',
        isHidden: !hasFreeCancellation,
        title: 'Free Cancellation',
      },
      {
        icon: 'accessTime',
        isHidden: !readableDurationRange,
        title: readableDurationRange,
      },
      {
        icon: 'smartPhone',
        isHidden: !hasMobileTicket,
        title: 'Mobile Ticket',
      },
      {
        icon: 'language',
        isHidden: !showLanguages,
        title: constructedLanguages(),
      },
    ]
  }, [experiencesProductData])

  return (
    !isEmpty(features) && (
      <div className={experiencesFeaturesCss}>
        {features.map(
          ({ icon, isHidden, title }, index) =>
            !isHidden && (
              <p key={`${title}-${index}`} className={experiencesFeatureCss}>
                <Icon name={icon} />
                <span>{title}</span>
              </p>
            )
        )}
      </div>
    )
  )
}

export { ExperiencesFeatures }
