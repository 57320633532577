import { SortBy } from 'src/__generated__/graphql'
import type { MapZoom } from 'src/common/components/Map/constants'

enum HotelResultsListView {
  grid = 'grid',
  list = 'list',
}

enum HotelResultsSearchParams {
  activeId = 'activeId',
  adults = 'adults',
  arrival = 'arrival',
  brands = 'brands',
  customerReviewScore = 'customerReviewScore',
  departure = 'departure',
  extendedLoading = 'extendedLoading',
  hoverId = 'hoverId',
  includeAccessibility = 'includeAccessibility',
  includeAirportShuttle = 'includeAirportShuttle',
  includeBreakfast = 'includeBreakfast',
  includeFitnessCenter = 'includeFitnessCenter',
  includeHotTub = 'includeHotTub',
  includeNonSmoking = 'includeNonSmoking',
  includeParking = 'includeParking',
  includePetFriendly = 'includePetFriendly',
  includePool = 'includePool',
  includeSpaServices = 'includeSpaServices',
  includeWifi = 'includeWifi',
  kids = 'kids',
  latitude = 'latitude',
  listView = 'listView',
  location = 'location',
  longitude = 'longitude',
  mapBounds = 'mapBounds',
  mapExpanded = 'mapExpanded',
  mapFitBounds = 'mapFitBounds',
  mapSearchThisArea = 'mapSearchThisArea',
  maxPrice = 'maxPrice',
  maxScore = 'maxScore',
  maxStars = 'maxStars',
  minPrice = 'minPrice',
  minScore = 'minScore',
  minStars = 'minStars',
  placeCountry = 'placeCountry',
  placeId = 'placeId',
  placeShortName = 'placeShortName',
  placeType = 'placeType',
  priceRange = 'priceRange',
  radius = 'radius',
  sort = 'sort',
  starRating = 'starRating',
}

enum HotelResultsSessionStorage {
  visitedIds = 'hotelResultsVisitedIds',
}

const filterKeys = [
  HotelResultsSearchParams.brands,
  HotelResultsSearchParams.includeAccessibility,
  HotelResultsSearchParams.includeAirportShuttle,
  HotelResultsSearchParams.includeBreakfast,
  HotelResultsSearchParams.includeFitnessCenter,
  HotelResultsSearchParams.includeHotTub,
  HotelResultsSearchParams.includeNonSmoking,
  HotelResultsSearchParams.includeParking,
  HotelResultsSearchParams.includePetFriendly,
  HotelResultsSearchParams.includePool,
  HotelResultsSearchParams.includeSpaServices,
  HotelResultsSearchParams.includeWifi,
  HotelResultsSearchParams.maxPrice,
  HotelResultsSearchParams.maxScore,
  HotelResultsSearchParams.maxStars,
  HotelResultsSearchParams.minPrice,
  HotelResultsSearchParams.minScore,
  HotelResultsSearchParams.minStars,
  'customerReviewScore',
  'starRating',
  'priceRange',
]

const initialMapZoom: MapZoom = 12

const initialSort: SortBy = SortBy.Recommended

const mapBoundsPadding: google.maps.Padding = {
  bottom: 100,
  left: 50,
  right: 50,
  top: 100,
}

// TODO: remove when handled on the BE.
const supportedInternationalCountries = [
  'Brazil',
  'Canada',
  'France',
  'Germany',
  'Italy',
  'Mexico',
  'Spain',
  'United Kingdom',
  'United States',
]

export {
  HotelResultsListView,
  HotelResultsSearchParams,
  HotelResultsSessionStorage,
  filterKeys,
  initialMapZoom,
  initialSort,
  mapBoundsPadding,
  supportedInternationalCountries,
}
