import dayjs from 'dayjs'
import type {
  ExperienceFilters,
  ExperienceSortBy,
} from 'src/__generated__/graphql'
import { experiencesSegment, resultsSegment } from 'src/constants'
import {
  combineAndOverrideExp,
  encodeExpSearchParams,
  validateDecodedExpSearchParams,
} from './experienceSearchParamsUtils'
import type { experiencesResultsUrlBasics } from './types'

interface generateExperiencesResultsUrlArgs
  extends experiencesResultsUrlBasics {
  filters?: ExperienceFilters
  placeId?: string
  sort?: ExperienceSortBy
  generateDefaultDates?: boolean
}

/**
 * Everything is optional.
 * Call this function with no arguments and the function
 * returns a url to the default location, with default
 * dates, and guests
 *
 * - See initialGeocoderLatitude, initialGeocoderLongitude,
 * and initialGeocoderPlaceName.
 *
 * Pass in a valid latitude, longitude and location name to
 * generate a URL that will display experiences for that location.
 */
const generateExperiencesResultsUrl = ({
  latitude,
  longitude,
  location,
  arrival,
  departure,
  filters,
  sort,
  generateDefaultDates = true,
}: generateExperiencesResultsUrlArgs): string => {
  const validValues = validateDecodedExpSearchParams({
    latitude,
    longitude,
    location,
    arrival,
    departure,
    sort,
    ...filters,
  })

  if (!generateDefaultDates) {
    validValues.arrival = arrival ? dayjs(arrival) : null
    validValues.departure = departure ? dayjs(departure) : null
  }

  const encodedSearchParams = encodeExpSearchParams(validValues)

  return `/${experiencesSegment}/${resultsSegment}?${encodedSearchParams}`
}

interface updateExperiencesResultsUrlArgs {
  searchParams: URLSearchParams
  filterValues?: ExperienceFilters
  sortValue?: ExperienceSortBy
  placeIdValue?: string
  overrides?: experiencesResultsUrlBasics
}

const updateExperiencesResultsUrl = ({
  searchParams,
  filterValues,
  sortValue,
  overrides = {},
}: updateExperiencesResultsUrlArgs) => {
  const {
    arrival,
    departure,
    latitude,
    longitude,
    location,
    sort,
    ...filters
  } = combineAndOverrideExp({
    searchParams,
    filterValues,
    sortValue,
    overrides,
  })

  return generateExperiencesResultsUrl({
    arrival,
    departure,
    latitude,
    longitude,
    location,
    filters,
    sort,
  })
}

export { generateExperiencesResultsUrl, updateExperiencesResultsUrl }
