import { gql } from 'src/__generated__'

export const searchBookingQuery = gql(`
query FindBooking($externalConfirmationId: String!, $email: String!) {
  findBooking(externalConfirmationId: $externalConfirmationId, email: $email) {
    ... on Booking {
      id
      hotelId
    }
    ... on ExperienceBooking {
      id
      externalConfirmationId
    }
  }
}
`)
