import classNames from 'classnames'
import { Triangle } from './Triangle'

interface ClusterMarkerProps {
  count: number
  label?: string
  selected?: boolean
}

export const ClusterMarker = ({
  count,
  label = 'events',
  selected,
}: ClusterMarkerProps) => {
  return (
    <div className='group'>
      <div className={classNames(resultsMarkerCss)}>{`${count} ${label}`}</div>
      <Triangle selected={selected} />
    </div>
  )
}

const resultsMarkerCss =
  'px-3 py-1 font-bold text-base rounded-xl group-hover:bg-forest-green group-hover:text-white bg-canyon'
