import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import { createSearchParams } from 'react-router-dom'
import type {
  ExperienceSortBy,
  ExperienceFilters,
} from 'src/__generated__/graphql'
import type { allExpSearchParamsDecoded } from 'src/constants'
import {
  defaultExperiencesResultsParams,
  defaultExperiencesResultsParamsStrs,
  initialExpFilters,
} from 'src/constants'
import { validateLocation } from './addressUtils'
import { formatDate, validateDates } from './dateUtils'
import { validateExpFiltersAndSort } from './experienceFilterUtils'
import type { experiencesResultsUrlBasics } from './types'

interface validateDecodedSearchParamsArgs
  extends experiencesResultsUrlBasics,
    ExperienceFilters {
  sort?: ExperienceSortBy
}

const validateDecodedExpSearchParams = ({
  latitude,
  longitude,
  location,
  arrival,
  departure,
  sort,
  ...filters
}: validateDecodedSearchParamsArgs): allExpSearchParamsDecoded => {
  const { validLocation, validLat, validLon } = validateLocation({
    location,
    latitude,
    longitude,
  })

  const { validArrival, validDeparture } = validateDates({
    arrival,
    departure,
    isSameDateRangeAllowed: true,
  })

  const { validFilters, validSort } = validateExpFiltersAndSort({
    filters,
    sort,
  })
  return {
    latitude: validLat,
    longitude: validLon,
    location: validLocation,
    arrival: validArrival,
    departure: validDeparture,
    sort: validSort,
    ...validFilters,
  }
}

const decodeAndValidateExpSearchParams = (
  searchParams: URLSearchParams
): allExpSearchParamsDecoded => {
  const decodedResponse = cloneDeep(defaultExperiencesResultsParams)

  const strings = ['location', 'placeId', 'sort']
  const floats = ['latitude', 'longitude']
  const objects = [
    'customerReviewScore',
    'duration',
    'priceRange',
    'tags',
    'timeOfDay',
  ]
  const dates = ['arrival', 'departure']

  Object.keys(decodedResponse).forEach(encodedKey => {
    const encodedValue = searchParams.get(encodedKey)

    if (floats.includes(encodedKey)) {
      decodedResponse[encodedKey] = parseFloat(encodedValue ?? '') ?? 200
    } else if (objects.includes(encodedKey)) {
      decodedResponse[encodedKey] = JSON.parse(encodedValue ?? '[]')
    } else if (dates.includes(encodedKey)) {
      decodedResponse[encodedKey] = dayjs(encodedValue)
    } else if (strings.includes(encodedKey)) {
      decodedResponse[encodedKey] = encodedValue
    } else {
      delete decodedResponse[encodedKey]
    }
  })

  const {
    location,
    latitude,
    longitude,
    arrival,
    departure,
    placeId,
    sort,
    ...filters
  } = decodedResponse

  return validateDecodedExpSearchParams({
    location,
    latitude,
    longitude,
    arrival,
    departure,
    sort,
    ...filters,
  })
}

interface encodeExpSearchParamsArgs extends ExperienceFilters {
  arrival?: Dayjs
  departure?: Dayjs
  latitude: number
  location: string
  longitude: number
  sort?: ExperienceSortBy
}
const encodeExpSearchParams = (
  decodedSearchParams: encodeExpSearchParamsArgs
): URLSearchParams => {
  const endcodedResponse = cloneDeep(defaultExperiencesResultsParamsStrs)

  const strings = ['location', 'sort']
  const numbers = ['latitude', 'longitude']
  const objects = [
    'customerReviewScore',
    'priceRange',
    'duration',
    'timeOfDay',
    'tags',
  ]
  const dates = ['arrival', 'departure']

  Object.keys(endcodedResponse).forEach(decodedKey => {
    const decodedValue = decodedSearchParams[decodedKey]
    if (decodedValue) {
      if (numbers.includes(decodedKey)) {
        endcodedResponse[decodedKey] = Number(decodedValue).toString()
      } else if (objects.includes(decodedKey)) {
        endcodedResponse[decodedKey] = JSON.stringify(decodedValue)
      } else if (dates.includes(decodedKey)) {
        endcodedResponse[decodedKey] = formatDate(dayjs(decodedValue))
      } else if (strings.includes(decodedKey)) {
        endcodedResponse[decodedKey] = String(decodedValue)
      }
    } else {
      delete endcodedResponse[decodedKey]
    }
  })

  return createSearchParams(endcodedResponse)
}

/**
 * combineAndOverride
 * @summary combines and overrides the current search params with the
 * current filterValues, sortValue, and overrides
 *
 * Priority is as so
 * Overrides - override current and default values
 * FilterValues / SortValue- override current and default values
 * Current Values - if no FilterValues / SortValues override default values
 * Default Values
 */

interface combineAndOverrideArgs {
  searchParams: URLSearchParams
  filterValues?: ExperienceFilters
  placeIdValue?: string
  mapExpandedValue?: boolean
  mapZoomValue?: boolean | number
  radiusValue?: string
  sortValue?: ExperienceSortBy
  overrides?: experiencesResultsUrlBasics
  openOnMap?: boolean
}

const combineAndOverrideExp = ({
  searchParams,
  filterValues,
  placeIdValue,
  sortValue,
  overrides = {},
}: combineAndOverrideArgs) => {
  const combinedValues = cloneDeep(defaultExperiencesResultsParams)
  const currentValues = decodeAndValidateExpSearchParams(searchParams)

  const endcodedResponse = cloneDeep(defaultExperiencesResultsParamsStrs)

  Object.keys(endcodedResponse).forEach(paramKey => {
    const override = overrides[paramKey] ?? false
    const current = currentValues[paramKey] ?? false

    if (paramKey === 'placeId') {
      if (placeIdValue) {
        combinedValues.placeId = placeIdValue
      } else if (current) {
        combinedValues.placeId = current
      } else {
        delete combinedValues.placeId
      }
    } else if (paramKey === 'sort') {
      if (sortValue) {
        combinedValues.sort = sortValue
      } else if (current) {
        combinedValues.sort = current
      } else {
        delete combinedValues.sort
      }
    } else if (Object.keys(initialExpFilters).includes(paramKey)) {
      if (isEmpty(filterValues)) {
        if (current) {
          combinedValues[paramKey] = current
        } else {
          delete combinedValues[paramKey]
        }
      } else {
        const filter = !isEmpty(filterValues) ? filterValues[paramKey] : false
        if (filter) {
          combinedValues[paramKey] = filter
        } else {
          delete combinedValues[paramKey]
        }
      }
    } else {
      if (override) {
        combinedValues[paramKey] = override
      } else if (current) {
        combinedValues[paramKey] = current
      }
    }
  })

  return combinedValues
}

export {
  validateDecodedExpSearchParams,
  encodeExpSearchParams,
  decodeAndValidateExpSearchParams,
  combineAndOverrideExp,
}
