const milesToKilometersRatio = 0.621371

const kilometersToMiles = (distance: number) =>
  distance * milesToKilometersRatio

const metersToMiles = (distance: number) =>
  distance * (milesToKilometersRatio / 1000)

const milesToMinutesWalking = (miles: number) => {
  const averageMph = 2.8
  const minutes = 60
  const averageMinutes = Math.round((minutes / averageMph) * miles)

  return averageMinutes >= 1 ? averageMinutes : 1
}

export { kilometersToMiles, metersToMiles, milesToMinutesWalking }
