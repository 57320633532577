interface DetailsHeroImageProps {
  alt: string
  height?: string
  src: string
}

export const DetailsHeroImage = ({
  alt,
  height = 'h-full',
  src,
}: DetailsHeroImageProps) => (
  <img
    alt={alt}
    className={detailsHeroImageSizeStyles({ height })}
    draggable='false'
    loading='lazy'
    src={src}
  />
)

const detailsHeroImageSizeStyles = ({
  height,
}: Partial<DetailsHeroImageProps>) => `object-cover w-full ${height}`
