import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getImageSearchQuery = gql(`
  query ImageSearch($limit: Int!, $searchString: String!) {
    imageSearch(limit: $limit, searchString: $searchString) 
  }
`)

export const useGetImageSearchQuery = () => useLazyQuery(getImageSearchQuery)
