import classNames from 'classnames'
import { getArrivalDate } from 'src/utils'

interface DateSummaryItem {
  date: string
  time: string | null
  title: string
}

interface DateSummaryProps {
  arrival: DateSummaryItem['date']
  checkin: DateSummaryItem['time']
  checkout: DateSummaryItem['time']
  departure: DateSummaryItem['date']
  hasBorder?: boolean
  hasPadding?: boolean
}

const DateSummary = ({
  arrival,
  checkin,
  checkout,
  departure,
  hasBorder = false,
  hasPadding = false,
}: DateSummaryProps) => {
  const dates: DateSummaryItem[] = [
    {
      date: getArrivalDate({
        date: arrival,
        format: 'ddd, MMMM D, YYYY',
      }),
      time: checkin,
      title: 'Check-in',
    },
    {
      date: getArrivalDate({
        date: departure,
        format: 'ddd, MMMM D, YYYY',
      }),
      time: checkout,
      title: 'Check-out',
    },
  ]

  return (
    <div
      className={classNames('auto-flow-dense grid grid-cols-2 gap-2', {
        'sm:b-none lg:b-1 lg:b-solid lg:b-color-grey-300 lg:rounded-2':
          hasBorder,
        'sm:p-0 lg:px-8 lg:py-4': hasPadding,
      })}
    >
      {dates.map(({ date, time, title }) => (
        <div key={title} className='space-y-2'>
          <h3 className='type-h6'>{title}</h3>
          <div className='type-body-2 c-grey-800'>
            <p className='m-0'>{date}</p>
            {!!time && (
              <p className='m-0' data-testid='DateSummary-time'>
                {time}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export { DateSummary }
export type { DateSummaryProps }
