import { useState } from 'react'
import { Modal, UnderlineTab } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import type { DatesType, GeocoderType } from 'src/constants/user'
import { initialDates, initialGeocoder } from 'src/constants/user'
import { HotelResultsSearchParams } from 'src/pages/hotels/results/hotelResultsConstants'
import { constructDates, constructGeocoder, stringToNumber } from 'src/utils'
import type { SearchHotelsProps } from './SearchHotels'
import { SearchHotels } from './SearchHotels'
import type { SearchExperiencesProps } from '../SearchExperiences'
import { SearchExperiences } from '../SearchExperiences'

enum SearchTabs {
  HOTELS = 'HOTELS',
  EXPERIENCES = 'EXPERIENCES',
}

interface SearchHotelsModalProps
  extends SearchHotelsProps,
    SearchExperiencesProps {
  initialTabIndex?: number
  onClose(): void
}

export const SearchProductsModal = ({
  checkSearchParams = false,
  checkTypes,
  focusOnGeocoderInput,
  initialTabIndex = 0,
  onClose,
}: SearchHotelsModalProps) => {
  const [searchParams] = useSearchParams()
  const arrival = searchParams.get(HotelResultsSearchParams.arrival)
  const departure = searchParams.get(HotelResultsSearchParams.departure)
  const latitude = searchParams.get(HotelResultsSearchParams.latitude)
  const longitude = searchParams.get(HotelResultsSearchParams.longitude)
  const placeId = searchParams.get(HotelResultsSearchParams.placeId)
  const placeName = searchParams.get(HotelResultsSearchParams.location)
  const updatedLatitude = stringToNumber(latitude)
  const updatedLongitude = stringToNumber(longitude)
  const [tabIndex, setTabindex] = useState(initialTabIndex)
  const [geocoder, setGeocoder] = useState(() =>
    checkSearchParams
      ? constructGeocoder({
          latitude: updatedLatitude,
          longitude: updatedLongitude,
          placeId,
          placeName,
        })
      : initialGeocoder
  )
  const [dates, setDates] = useState(() =>
    checkSearchParams ? constructDates({ arrival, departure }) : initialDates
  )

  const isHotelsTab = tabIndex === 0

  const onChangeGeocoder = (updatedGeocoder: GeocoderType) => {
    setGeocoder(updatedGeocoder)
  }

  const onChangeDates = (updatedDates: DatesType) => {
    setDates(updatedDates)
  }

  const updateTabIndex = (index: number) => {
    setTabindex(index)
  }

  const tabs = [
    {
      label: SearchTabs.HOTELS,
      onClick: () => {
        updateTabIndex(0)
      },
    },
    {
      label: SearchTabs.EXPERIENCES,
      onClick: () => {
        updateTabIndex(1)
      },
    },
  ]

  return (
    <Modal size='large' onDismiss={onClose}>
      <div className='mb-5 flex justify-center'>
        <UnderlineTab activeIndex={tabIndex} tabs={tabs} />
      </div>
      <div className='h-full w-full lg:px-11 lg:pb-8 lg:pt-6'>
        {isHotelsTab ? (
          <SearchHotels
            checkSearchParams={checkSearchParams}
            checkTypes={checkTypes}
            dates={dates}
            focusOnGeocoderInput={focusOnGeocoderInput}
            geocoder={geocoder}
            onChangeDates={onChangeDates}
            onChangeGeocoder={onChangeGeocoder}
            onClose={onClose}
          />
        ) : (
          <SearchExperiences
            checkTypes={checkTypes}
            dates={dates}
            focusOnGeocoderInput={focusOnGeocoderInput}
            geocoder={geocoder}
            onChangeDates={onChangeDates}
            onChangeGeocoder={onChangeGeocoder}
            onClose={onClose}
          />
        )}
      </div>
    </Modal>
  )
}
