import type { ReactNode } from 'react'

interface ResultsFiltersSectionProps {
  children: ReactNode
  title: ReactNode | string
}

export const ResultsFiltersSection = ({
  children,
  title,
}: ResultsFiltersSectionProps) => (
  <div className='flex flex-col gap-5'>
    <h6 className='text-h6'>{title}</h6>
    <div>{children}</div>
  </div>
)
