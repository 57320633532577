import isEmpty from 'lodash.isempty'
import { EventType } from 'src/__generated__/graphql'

const constructDisplayName = (name?: string, fallbackText?: string) => {
  const cleanFallBack = fallbackText?.trim() ?? null
  const defaultText = isEmpty(cleanFallBack)
    ? 'Could not load name'
    : cleanFallBack

  const cleanName = name?.trim() ?? null

  const displayName = isEmpty(cleanName) ? defaultText : cleanName

  return displayName
}

const getProductTypesAsObject = (
  productType: string
): {
  isExperience: boolean
  isStay: boolean
} => {
  const isExperience = !productType || productType === EventType.Experience

  return {
    isExperience,
    isStay: !isExperience,
  }
}

export { constructDisplayName, getProductTypesAsObject }
