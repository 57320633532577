import { forwardRef, useEffect, useId } from 'react'
import type { AvailableIcons } from '@travelpass/design-system'
import { Button, Icon, IconButton, KeyCode } from '@travelpass/design-system'
import {
  containerCss,
  labelCss,
  inputWrapCss,
  inputCss,
  helperTextCss,
} from './resultsInputStyles'

type NativeInput = React.ComponentPropsWithRef<'input'>

export interface ResultsInputProps extends NativeInput {
  errorText?: string
  fullWidth?: boolean
  isInvalid?: boolean
  isDisabled?: boolean
  focusOnInput?: boolean
  label?: string
  labelIcon?: AvailableIcons
  helperText?: string
  slotAfter?: JSX.Element
  onClear?: (e: React.MouseEvent | React.KeyboardEvent) => void
  onChange?:
    | React.ChangeEventHandler<HTMLInputElement>
    | React.FormEventHandler<HTMLInputElement>
  /** Useful for accessing the outer container element rather than the input itself */
  containerRef?: React.Ref<HTMLDivElement>
}

export const ResultsInput = forwardRef<HTMLInputElement, ResultsInputProps>(
  (
    {
      isDisabled = false,
      errorText,
      fullWidth = false,
      helperText,
      id,
      isInvalid = false,
      focusOnInput,
      label = null,
      labelIcon,
      placeholder,
      type = 'text',
      value,
      slotAfter = null,
      onClear,
      onClick,
      onKeyDown,
      onSubmit,
      containerRef,
      ...remainingProps
    },
    ref
  ) => {
    const autoId = useId()
    const htmlId = id || autoId
    const hasHelperText = !!helperText || !!errorText
    const helperTextId = hasHelperText ? `${htmlId}-helper-text` : null

    const noPaddingForSlotAfter =
      slotAfter?.type === IconButton || slotAfter?.type === Button

    if (import.meta.env.NODE_ENV !== 'production') {
      const missingAnyLabel = !label && !remainingProps['aria-label']
      if (missingAnyLabel) {
        console.warn(
          `Consider providing <Input id="${htmlId}"/> with either a label or aria-label prop. Label is shown visually, while aria-label is only surfaced to assistive technology (AT). A good label makes a difference.`
        )
      }
    }

    const handleKeyboardBehaviors: ResultsInputProps['onKeyDown'] = e => {
      // Clear input with Esc key
      if (e.key === KeyCode.ESC && !!value) {
        onClear?.(e)
      }
      // Trigger onSubmit with Enter key
      if (e.key === KeyCode.ENTER) {
        onSubmit?.(e)
      }
      // Also call user provided onKeyDown handler
      onKeyDown?.(e)
    }

    useEffect(() => {
      setTimeout(() => {
        if (focusOnInput && ref != null && typeof ref !== 'function')
          ref?.current?.focus()
      }, 300)
    }, [])

    return (
      <div className='space-y-1'>
        <div
          className={containerCss(fullWidth, noPaddingForSlotAfter)}
          data-disabled={isDisabled}
          data-invalid={isInvalid}
          ref={containerRef}
        >
          <div className='flex grow flex-row items-center gap-2.5 p-0'>
            <div className='min-w-0 grow space-y-1'>
              {!!label && (
                <label
                  className={labelCss}
                  data-required={remainingProps.required}
                  htmlFor={htmlId}
                >
                  {labelIcon && <Icon name={labelIcon} size='small' />}
                  {label}
                </label>
              )}
              {/* eslint-disable jsx-a11y/no-static-element-interactions */}
              <div
                className={inputWrapCss({
                  isDisabled,
                  isInvalid,
                })}
                onClick={isDisabled ? () => {} : onClick}
              >
                <input
                  {...remainingProps}
                  aria-describedby={helperTextId ?? 'What should this be?'}
                  className={inputCss}
                  disabled={isDisabled}
                  id={htmlId}
                  placeholder={placeholder}
                  ref={ref}
                  type={type}
                  value={value}
                  onKeyDown={handleKeyboardBehaviors}
                />
              </div>
            </div>
            {slotAfter}
          </div>
        </div>
        {hasHelperText && (
          <p
            className={helperTextCss(!!errorText)}
            id={helperTextId ?? 'What should go here??'}
          >
            {errorText || helperText}
          </p>
        )}
      </div>
    )
  }
)

ResultsInput.displayName = 'Input'
