import { Link, Modal } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { Fee } from 'src/__generated__/graphql'
import { termsPath } from 'src/constants'
import { getPriceWithDigits } from 'src/utils'
import type { HotelPriceSummaryProps } from '../HotelPriceSummary/hotelPriceSummaryConstants'

interface HotelTaxesAndFeesModalProps {
  surcharges?: HotelPriceSummaryProps['surcharges']
  onClose?(): void
}

export const HotelTaxesAndFeesModal = ({
  surcharges = [],
  onClose = () => {},
}: HotelTaxesAndFeesModalProps) => {
  const constructedSurcharges = () => {
    if (isEmpty(surcharges)) return

    return surcharges
      ?.filter(({ type }) => type !== 'Taxes')
      ?.map(({ amount, type }) => (
        <div key={type}>
          <span>{constructedSurchargesType(type)}:</span>{' '}
          {getPriceWithDigits(amount?.amount)}
        </div>
      ))
  }

  const constructedSurchargesType = (type: Fee['type']): string => {
    if (type === 'Tax/Service Fee') return 'Taxes and Fees'

    return type
  }

  return (
    <Modal size='medium' title='Taxes & Fees' onDismiss={onClose}>
      <div className='sm:space-y-6 lg:space-y-9'>
        <p className='type-body-1'>
          This charge includes estimated amounts the travel service provider
          (i.e. hotel, car rental company) pays for their taxes, and/or taxes
          that we pay, to taxing authorities on your booking (including but not
          limited to sales, occupancy, and value added tax). This amount may
          also include any amounts charged to us for resort fees, cleaning fees,
          and other fees and/or a fee we, the hotel supplier and/or the website
          you booked on, retain as part of the compensation for our and/or their
          services, which varies based on factors such as location, the amount,
          and how you booked. For more details, please see the{' '}
          <span className='flex-inline'>
            <Link
              label='Terms and Conditions'
              onClick={() => window.open(termsPath)}
            />
          </span>
          .
        </p>
        {constructedSurcharges()}
      </div>
    </Modal>
  )
}
