import { Skeleton } from '@travelpass/design-system'
import { hotelPriceSummaryRowCss } from '../hotelPriceSummaryStyles'

export const HotelPriceSummaryRowLoading = () => {
  return (
    <div className={hotelPriceSummaryRowCss({})}>
      <div className='h-20px md:h-20px w-140px'>
        <Skeleton />
      </div>
      <div className='h-20px w-75px'>
        <Skeleton />
      </div>
    </div>
  )
}
