import classNames from 'classnames'
import { getPriceWithDigits } from 'src/utils'
import type { HotelPriceSummaryRowProps } from './types'
import {
  hotelPriceSummaryRowCss,
  variantStyles,
} from '../hotelPriceSummaryStyles'

const HotelPriceSummaryRow = ({
  title,
  variant = 'text',
  value,
}: HotelPriceSummaryRowProps) => (
  <div
    className={hotelPriceSummaryRowCss({ variant })}
    data-testid='HotelPriceSummaryRow'
  >
    <div className={hotelPriceSummaryRowTitleCss({ variant })}>{title}</div>
    <div className={hotelPriceSummaryRowValueCss({ variant })}>
      {getPriceWithDigits(value)}
    </div>
  </div>
)

const hotelPriceSummaryRowTitleCss = ({
  variant,
}: Partial<HotelPriceSummaryRowProps>) =>
  classNames(variantStyles({ variant }), {
    'text-h6': variant === 'title',
  })

const hotelPriceSummaryRowValueCss = ({
  variant,
}: Partial<HotelPriceSummaryRowProps>) =>
  classNames(variantStyles({ variant }), {
    'font-roboto text-subtitle-1': variant === 'title',
  })

export { HotelPriceSummaryRow }
