import { Button, Divider } from '@travelpass/design-system'

interface LoadMoreProps {
  hasMoreResults: boolean
  isMoreResultsLoading: boolean
  onGetMoreResults(): void
}

export const LoadMore = ({
  hasMoreResults,
  isMoreResultsLoading,
  onGetMoreResults,
}: LoadMoreProps) => {
  const renderLoadMore = () => (
    <Button label='Load More' onClick={onGetMoreResults} />
  )
  const renderNoMore = () => (
    <div className='flex w-full max-w-lg items-center'>
      <Divider />
      <div className={loadMoreTextContainerCss}>
        <h3 className='text-body-1'>Showing all available results</h3>
      </div>
      <Divider />
    </div>
  )
  return (
    !isMoreResultsLoading && (
      <div className={loadMoreCss}>
        {hasMoreResults ? renderLoadMore() : renderNoMore()}
      </div>
    )
  )
}

const loadMoreCss = 'flex items-center justify-center my-8 px-4 w-full'

const loadMoreTextContainerCss = 'p-4 whitespace-nowrap'
