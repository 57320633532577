import type {
  HotelSearchResult,
  HotelSearchResultWithoutRates,
} from 'src/__generated__/graphql'
import { constructAddress, constructImageLinks } from 'src/utils'
import { DetailsMinimalHero } from './DetailsMinimalHero'
import { ReviewBadge } from '../ReviewBadge'

interface DetailsMinimalHotelHeroProps {
  detailsMinimalHotelHeroData: HotelSearchResult | HotelSearchResultWithoutRates
}

const DetailsMinimalHotelHero = ({
  detailsMinimalHotelHeroData,
}: DetailsMinimalHotelHeroProps) => {
  const { customerReviewScore, images, hotelAddress, name } =
    detailsMinimalHotelHeroData ?? {}
  const filteredImages =
    images?.filter(
      (image: string | null | undefined): image is string =>
        image !== null && image !== undefined
    ) ?? []
  const constructedImages = constructImageLinks(
    filteredImages,
    '1280x720'
  )?.slice(0, 5)
  const { city, postalCode, state, streetAddress } = hotelAddress ?? {}
  const subtitle = constructAddress({
    addressFirstLine: streetAddress ?? '',
    city: city ?? '',
    state: state ?? '',
    zipcode: postalCode ?? '',
  })

  return (
    <DetailsMinimalHero
      images={constructedImages}
      subtitle={subtitle}
      title={name ?? ''}
    >
      {!!customerReviewScore && (
        <ReviewBadge customerReviewScore={customerReviewScore} />
      )}
    </DetailsMinimalHero>
  )
}

export { DetailsMinimalHotelHero }
export type { DetailsMinimalHotelHeroProps }
