import { Divider, Skeleton } from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'
import { HotelPriceSummaryRowLoading } from './HotelPriceSummaryRow'
import type { HotelPriceSummaryProps } from './hotelPriceSummaryConstants'

interface HotelPriceSummaryLoadingProps {
  showLegal?: HotelPriceSummaryProps['showLegal']
}

export const HotelPriceSummaryLoading = ({
  showLegal = false,
}: HotelPriceSummaryLoadingProps) => {
  const constructedTitle = () => (
    <div className='h-20px md:h-24px w-50px'>
      <Skeleton />
    </div>
  )

  return (
    <div>
      <div className='flex flex-col gap-6' data-testid='HotelPriceSummary'>
        {constructedTitle()}
        <div className='flex flex-col gap-2'>
          <HotelPriceSummaryRowLoading />
          <HotelPriceSummaryRowLoading />
          <HotelPriceSummaryRowLoading />
        </div>
      </div>
      <Divider className='mb-8 mt-4' />
      <div className='flex flex-col gap-6'>
        {constructedTitle()}
        <div className='flex flex-col gap-2'>
          <HotelPriceSummaryRowLoading />
          <HotelPriceSummaryRowLoading />
        </div>
        {!isMobile && showLegal && (
          <div className='h-16px md:h-18px'>
            <Skeleton />
          </div>
        )}
      </div>
    </div>
  )
}
