import { CollectionItemIdType } from 'src/__generated__/graphql'
import { showStrikethroughPrice } from 'src/utils'
import {
  constructDisplayCancellation,
  constructDisplayBottomText,
  constructDisplayPrice,
  defaultProductData,
} from './resources'
import type { ProductCardData } from './types'
import { AddToMenu } from '../Collections/AddToMenu'
import { HotelCancellationBadge } from '../HotelPolicies'

interface ProductCardBottomProps {
  productCardData: ProductCardData
}

export const ProductCardBottom = ({
  productCardData,
}: ProductCardBottomProps) => {
  const { priceUnit: defaultPriceUnit } = defaultProductData
  const {
    cancellationPolicy,
    displayFrom,
    displayPriceUnit = false,
    priceUnit = defaultPriceUnit,
    primaryPrice,
    secondaryPrice,
    strikethroughPrice,
    id,
    name,
  } = productCardData ?? {}

  const isProductAvailable = !!primaryPrice

  const showStrikethrough =
    isProductAvailable &&
    showStrikethroughPrice({
      nightlyAverage: primaryPrice,
      strikethrough: strikethroughPrice,
    })

  const displayPrimaryPrice = constructDisplayPrice({
    defaultValue: '$-',
    price: primaryPrice,
  })

  const displayStrikethroughPrice = constructDisplayPrice({
    condition: showStrikethrough,
    price: strikethroughPrice,
  })

  const displayBottomText = constructDisplayBottomText({
    condition: isProductAvailable,
    displayPriceUnit,
    priceUnit,
    secondaryPrice,
  })

  const displayCancellation = constructDisplayCancellation({
    isProductAvailable,
    cancellationPolicy,
  })

  return (
    <>
      <div className='flex items-end justify-between'>
        <div className='z-2 relative'>
          <AddToMenu
            item={{
              id,
              name,
              type: CollectionItemIdType.Experience,
            }}
            placement='top'
            variant='text'
          />
        </div>
        <div className='flex min-h-8 flex-col items-end gap-1 text-right'>
          {displayFrom && (
            <div className='align-center flex justify-start'>
              <p className='text-body-2 color-grey-800 line-clamp-1 leading-tight'>
                from
              </p>
            </div>
          )}
          <div className='line-clamp-1 flex w-full flex-row items-center justify-end gap-1.5'>
            <p className='text-body-1 color-forest text-right font-medium leading-none'>
              {displayPrimaryPrice}
            </p>
            {displayStrikethroughPrice && (
              <p className='text-body-1 color-grey-600 relative font-medium leading-none line-through'>
                {displayStrikethroughPrice}
              </p>
            )}
          </div>
          <p className='text-body-2 color-grey-800 line-clamp-1 leading-tight'>
            {displayBottomText}
          </p>
          {displayCancellation && (
            <div className='self-end'>
              <HotelCancellationBadge type={displayCancellation} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
