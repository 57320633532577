import { env } from 'src/utils'

const googleMapsConfigObject = {
  int: 'AIzaSyCdo-Zt8GMmcW06kYEsM8fseIwZ4X3ufwU',
  stg: 'AIzaSyBkLm_zlR5aLMgqfIy95Wt_tnVcknAp_hQ',
  prod: 'AIzaSyBA0Qaedi7s3gwRT7DPl3pEVlNRcbp9P1w',
}

export const googleMapsConfig = googleMapsConfigObject[env]

const googleMapIdsDefaultConfigObject = {
  int: '3aedb12c41a84cab',
  stg: 'ad6f3d7f7b0de253',
  prod: '941790f1f0865cfb',
}

export const googleMapIdsDefaultConfig = googleMapIdsDefaultConfigObject[env]

const googleMapIdsGuideDraftConfigObject = {
  int: '7dbb331e6ef76581',
  stg: 'f5b384b960e0c199',
  prod: '65d14768a9f6224f',
}

export const googleMapIdsGuideDraftConfig =
  googleMapIdsGuideDraftConfigObject[env]
