import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getPresignedUrlsLazyQuery = gql(`
  query GetPresignedUrlsLazy($count: Int = 1, $presignedUrlType: PresignedUrlType) {
    getPresignedUrls(count: $count, presignedUrlType: $presignedUrlType)
  }
`)

export const useGetPresignedUrlsLazyQuery = () =>
  useLazyQuery(getPresignedUrlsLazyQuery)
