import classNames from 'classnames'

interface TriangleProps {
  selected?: boolean
}

export const Triangle = ({ selected }: TriangleProps) => (
  <div className='flex justify-center'>
    <div className={triangleCss(selected)}></div>
  </div>
)

const triangleCss = (selected: TriangleProps['selected']) =>
  classNames(
    'width-0 height-0 border-bottom-0 border-6 border-solid border-transparent group-hover:border-t-forest-green',
    {
      'border-t-forest-green': selected,
      'border-t-canyon': !selected,
    }
  )
