import classNames from 'classnames'
import type { HotelPoliciesProps } from './types'

const hotelPolicyContentClasses = ({
  contentType,
  showExcerpt,
}: Partial<HotelPoliciesProps>) =>
  classNames(`${contentType} c-grey800`, {
    'line-clamp-3': showExcerpt,
  })

const hotelPolicyTitleClasses = ({ titleType }: Partial<HotelPoliciesProps>) =>
  `${titleType} c-black capitalize`

export { hotelPolicyContentClasses, hotelPolicyTitleClasses }
