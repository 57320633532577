import classNames from 'classnames'
import type { HotelPriceSummaryRowProps } from './HotelPriceSummaryRow/types'

const variantStyles = ({ variant }: Partial<HotelPriceSummaryRowProps>) =>
  classNames({
    'color-forest font-600': variant === 'subtitle',
    'color-new-forest font-700': variant === 'title',
    'color-grey-800': variant !== 'subtitle' && variant !== 'title',
  })

const hotelPriceSummaryRowCss = ({
  variant,
}: Partial<HotelPriceSummaryRowProps>) =>
  classNames(
    'flex gap-2 justify-between text-body-1',
    variantStyles({ variant })
  )

export { hotelPriceSummaryRowCss, variantStyles }
