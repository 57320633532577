import dayjs from 'dayjs'
import { formatDate } from 'src/utils'
import type { HotelCancellationTimelineItem } from './types'

const constructDate = ({ date }: Partial<HotelCancellationTimelineItem>) =>
  formatDate(dayjs(date), 'MMMM DD, YYYY')

const constructTime = ({
  date,
  type,
}: Partial<HotelCancellationTimelineItem>) =>
  `${type === 'NONE' ? 'After' : 'Until'} ${formatDate(dayjs(date), 'hh:mm A')}`

export { constructDate, constructTime }
