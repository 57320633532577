import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import { constructDisplayName } from 'src/utils'
import {
  constructDisplayReviewScore,
  constructDisplaySubtitle,
  constructDisplayTotalReviews,
} from './resources'
import type { ProductCardData } from './types'

/**
 * ProductCardTop renders the following pieces
 * - Card Title
 * - Address or Duration
 * - Reveiew Score and Total Reviews
 */

interface ProductCardTopProps {
  productCardData: ProductCardData
}

export const ProductCardTop = ({ productCardData }: ProductCardTopProps) => {
  const {
    address,
    customerReviewScore,
    customerReviewScoreScale,
    durationMinutes,
    duration,
    name,
    totalCustomerReviews,
  } = productCardData ?? {}
  const displayName = constructDisplayName(name)
  const displaySubtitle = constructDisplaySubtitle({
    address,
    durationMinutes,
    duration,
  })
  const displayReviewScore = constructDisplayReviewScore({
    customerReviewScore,
    customerReviewScoreScale,
  })
  const displayTotalReviews = constructDisplayTotalReviews(totalCustomerReviews)

  return (
    <div className={productCardTopCss}>
      <div className={productCardTitleContainerCss}>
        <h3 className={productCardTitleCss}>{displayName}</h3>
      </div>
      <div className={productCardSubTitleContainerCss(!!durationMinutes)}>
        {!!durationMinutes && <Icon name='accessTime' size='small' />}
        <p className={productCardSubTitleCss}>{displaySubtitle}</p>
      </div>
      <div className={productCardReviewContainerCss(!!customerReviewScore)}>
        <p className={productCardReviewCss}>{displayReviewScore}</p>
        <p className={productCardSubTitleCss}>{displayTotalReviews}</p>
      </div>
    </div>
  )
}
const productCardTopCss = 'flex flex-col gap-1 min-h-21'

const productCardTitleContainerCss = 'min-h-10'

const productCardTitleCss =
  'text-base my-0 leading-tight font-archivo line-clamp-2 color-forest font-semibold'

const productCardSubTitleContainerCss = (addGap: boolean) =>
  classNames('flex flex-row items-center min-h-4 c-grey-800', {
    'gap-1': addGap,
  })

const productCardSubTitleCss = 'color-grey-800 line-clamp-1 text-body-2'

const productCardReviewContainerCss = (displayScore: boolean) =>
  classNames('flex flex-row min-h-4', {
    'gap-1': displayScore,
  })

const productCardReviewCss = 'color-forest line-clamp-1 text-body-2'
