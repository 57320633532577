import { useSnackbar } from '@travelpass/design-system'
import { dispatchToggleProfileModal } from 'src/pages/guides/results/dispatchToggleProfileModal'

export const useOnClickOwnerProfile = () => {
  const { addErrorSnack } = useSnackbar()
  return (accountHandle: string) => {
    if (!accountHandle)
      return addErrorSnack({
        title: 'Unable to access user profile at this time.',
      })
    dispatchToggleProfileModal({ accountHandle })
  }
}
