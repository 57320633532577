import type { ComponentPropsWithoutRef } from 'react'
import React, { useState } from 'react'
import { EmptyState } from '@travelpass/design-system'
import classNames from 'classnames'
import { constructImageLink } from 'src/utils'

interface ResultsCardImageProps extends ComponentPropsWithoutRef<'img'> {
  imageDimensions?: string
}

export const ResultsCardImage = ({
  alt,
  imageDimensions = '525x525',
  loading = 'lazy',
  src,
  onLoad = () => {},
  ...props
}: ResultsCardImageProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const imageWithDimensions = constructImageLink(src, imageDimensions)

  if (src)
    return (
      <div
        className={classNames('bg-grey-400 left-0 top-0 h-full w-full', {
          'animate-pulse': isLoading,
        })}
      >
        <img
          {...props}
          alt={alt}
          className={classNames('left-0 top-0 h-full w-full object-cover', {
            'opacity-0': isLoading,
            'opacity-100': !isLoading,
          })}
          loading={loading}
          src={imageWithDimensions}
          onLoad={events => {
            setIsLoading(false)
            onLoad(events)
          }}
        />
      </div>
    )

  return (
    <div className='bg-grey-400 absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center'>
      <div className='w-50'>
        <EmptyState name='noImages' />
      </div>
    </div>
  )
}
