export const TOGGLE_ADD_TO_GUIDE_MODAL = 'toggleAddToGuidesModal'

export const dispatchToggleAddToGuideModal = ({
  isModalOpen,
  item,
}: {
  isModalOpen: boolean
  item: AddToItem
}) => {
  const event = new CustomEvent(TOGGLE_ADD_TO_GUIDE_MODAL, {
    detail: { isModalOpen, item },
  })
  window?.dispatchEvent(event)
}
