import classNames from 'classnames'

export const containerCss = (
  fullWidth: boolean,
  noPaddingForSlotAfter: boolean
) =>
  classNames(
    'b-1px b-solid b-transparent inline-flex pl-6 py-2 rounded-4 focus-within:b-canyon data-[invalid="true"]:b-canyon data-[invalid="true"]:b-red',
    'min-w-0 min-h-0 children:min-w-0 children:min-h-0',
    fullWidth ? 'w-full' : 'w-auto',
    noPaddingForSlotAfter ? 'pr-2' : 'pr-6'
  )

export const labelCss =
  'flex gap-2 items-center type-body-1-medium-desktop text-left capitalize all-[svg]:color-new-forest data-[required="true"]:after:content-["*"] after:color-red'

export const helperTextCss = (hasError: boolean) =>
  classNames(
    'type-subtext px-6 text-left m-0 data-[invalid="true"]:color-current-color',
    hasError ? 'color-red' : 'color-grey-700'
  )

export const inputWrapCss = ({
  isDisabled,
  isInvalid,
}: {
  isDisabled: boolean
  isInvalid: boolean
}) =>
  classNames('flex flex-row items-center p-0 gap-2.5', {
    'bg-grey-200': isDisabled,
    'bg-white': !isDisabled,
    'b-current-color': isInvalid,
  })

export const inputCss =
  'bg-transparent b-none w-0 flex-grow outline-none p-0 type-body-2-desktop color-current-color cursor-pointer placeholder:color-grey-700 disabled:cursor-default'
