import { IconButton } from '@travelpass/design-system'
import classNames from 'classnames'

export const SlideUpDrawer = ({ children, isOpen, onClose }) => {
  return (
    <div
      className={classNames(
        'top-42.5 absolute bottom-0 left-0 right-0 w-full overflow-y-auto bg-white px-8 pt-6',
        {
          visible: isOpen,
          hidden: !isOpen,
        }
      )}
    >
      <div className='flex justify-end'>
        <IconButton
          aria-label='Close drawer content'
          icon='clear'
          size='large'
          onClick={onClose}
        />
      </div>
      {children}
    </div>
  )
}
