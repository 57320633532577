const formatPhoneNumber = (phoneNumberString: string) => {
  const phoneNumberAsNumber = parseInt(phoneNumberString)
  if (isNaN(phoneNumberAsNumber)) {
    return null
  }

  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1,2})(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return [
      `${match[1] ? '+' : ''}`,
      match[1],
      `${match[1] ? ' ' : ''}`,
      match[2],
      '-',
      match[3],
      '-',
      match[4],
    ].join('')
  }

  return null
}

const isValidPhoneNumber = (phoneNumber: string): boolean => {
  // Regular expression to match the phone number pattern "XXX-XXX-XXXX"
  const regex = /^\d{3}\d{3}\d{4}$/
  return regex.test(phoneNumber)
}

export { formatPhoneNumber, isValidPhoneNumber }
