import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'
import type { BookingOccupancy } from 'src/__generated__/graphql'
import { getTotalNights, getTotalRooms, getTotalRoomsGuests } from 'src/utils'
import {
  occupancySummaryCss,
  occupancySummaryItemCss,
} from './occupancySummaryStyles'

interface OccupancySummaryProps {
  arrival: string
  departure: string
  occupancies: BookingOccupancy[]
}

const OccupancySummary = ({
  arrival,
  departure,
  occupancies,
}: OccupancySummaryProps) => {
  const rooms = occupancies?.length ?? 1

  const items: { icon: AvailableIcons; title: string }[] = [
    {
      icon: 'personOutline',
      title: getTotalRoomsGuests({
        occupancies,
      }),
    },
    {
      icon: 'nightlight',
      title: getTotalNights({ arrival, departure }),
    },
    {
      icon: 'bed',
      title: getTotalRooms({ rooms }),
    },
  ]

  return (
    <div className={occupancySummaryCss} data-testid='OccupancySummary'>
      {items.map(({ icon, title }, index) => (
        <div
          key={`occupancy-summary-item-${index}`}
          className={occupancySummaryItemCss}
        >
          <Icon name={icon} />
          <div>{title}</div>
        </div>
      ))}
    </div>
  )
}

export { OccupancySummary }
export type { OccupancySummaryProps }
