import { useContext, useMemo } from 'react'
import type { IconButtonProps } from '@travelpass/design-system'
import { IconButton } from '@travelpass/design-system'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'

interface ScrollerButtonProps extends IconButtonProps {
  isNext?: boolean
}

export const ScrollerButton = ({
  isNext = false,
  ...props
}: ScrollerButtonProps) => {
  const {
    isFirstItemVisible,
    isLastItemVisible,
    scrollNext,
    scrollPrev,
    visibleElements,
    initComplete,
  } = useContext(VisibilityContext)
  const isDisabled = useMemo(() => {
    if (initComplete && !!visibleElements?.length) {
      if (isNext) return isLastItemVisible

      return isFirstItemVisible
    }

    return true
  }, [initComplete, isFirstItemVisible, isLastItemVisible, visibleElements])

  const onClick = () => {
    if (isNext) return scrollNext()

    return scrollPrev()
  }

  return <IconButton {...props} isDisabled={isDisabled} onClick={onClick} />
}
