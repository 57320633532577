import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'

interface MenuItemWithIconProps {
  endIcon?: AvailableIcons
  label: string
  startIcon?: AvailableIcons
  onClick(): void
}

export const MenuItemWithIcon = ({
  endIcon,
  label,
  startIcon,
  onClick,
}: MenuItemWithIconProps) => (
  <button
    className={classNames(
      'type-body-2 c-newForest transition-200 transition-ease-linear relative flex h-11 w-full cursor-pointer flex-col justify-center border-none bg-transparent transition-opacity hover:opacity-70 active:opacity-70',
      {
        'pl-9': startIcon,
        'pr-9': endIcon,
      }
    )}
    type='button'
    onClick={onClick}
  >
    {startIcon && (
      <span
        className='absolute bottom-0 left-0 top-0 flex flex-col justify-center'
        data-testid='MenuItemWithIcon-start-icon'
      >
        <Icon name={startIcon} />
      </span>
    )}
    <span className='truncate text-left'>{label}</span>
    {endIcon && (
      <span
        className='absolute bottom-0 right-0 top-0 flex flex-col justify-center'
        data-testid='MenuItemWithIcon-end-icon'
      >
        <Icon name={endIcon} />
      </span>
    )}
  </button>
)

export type { MenuItemWithIconProps }
