import { Skeleton } from '@travelpass/design-system'

export const ResultsCardReviewsLoading = () => (
  <div className='flex items-center gap-1'>
    <span className='of-hidden rounded-1 h-5 w-8'>
      <Skeleton />
    </span>
    <span className='h-4 w-16'>
      <Skeleton />
    </span>
  </div>
)
