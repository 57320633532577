import { appStoreNativeAppRoute, googlePlayNativeAppRoute } from 'src/constants'

export const NativeAppCta = () => {
  const title = 'Download our app'

  return (
    <div className='hide-on-print'>
      <div className='lg-flex-row-reverse b-1 b-solid b-grey-300 rounded-5 pb-67 relative flex flex-col items-center gap-5 overflow-hidden pl-2 pr-2 pt-11 text-center lg:justify-end lg:gap-8 lg:px-10 lg:py-9 lg:text-left'>
        <div className='grow-1 flex w-full max-w-full flex-col gap-4 lg:max-w-[calc(100%-460px)]'>
          <h2 className='type-h3'>{title}</h2>
          <p className='type-body-1'>
            Use the app as a companion while you are on your trip.
          </p>
          <div className='lg:items-initial flex flex-col items-center gap-4 lg:flex-row'>
            <a
              className='transition-opacity duration-200 ease-linear hover:opacity-70 active:opacity-70'
              href={appStoreNativeAppRoute}
              rel='noreferrer'
              target='_blank'
            >
              <img
                alt='Download our native app on the App Store'
                className='w-37 h-auto max-w-full lg:w-full'
                src='https://static.travelpass.com/assets/native-app/appstore.png'
              />
            </a>
            <a
              className='transition-opacity duration-200 ease-linear hover:opacity-70 active:opacity-70'
              href={googlePlayNativeAppRoute}
              rel='noreferrer'
              target='_blank'
            >
              <img
                alt='Get our native app on Google Play'
                className='w-37 h-auto max-w-full lg:w-full'
                src='https://static.travelpass.com/assets/native-app/googleplay.png'
              />
            </a>
          </div>
        </div>
        <img
          alt={`${title} QR Code - Booking Confirmation`}
          className='max-w-25 relative top-0 hidden h-auto w-full object-cover lg:top-2 lg:block'
          src='https://static.travelpass.com/assets/native-app/qr-code.png'
        />
        <img
          alt={`${title} Preview - Booking Confirmation`}
          className='max-w-293px lg:max-w-90 bottom--13 lg:right--10px absolute left-0 right-0 top-auto m-auto h-auto w-full lg:bottom-0 lg:left-auto lg:top--14 lg:m-0'
          src='https://static.travelpass.com/assets/native-app/preview.png'
        />
      </div>
    </div>
  )
}
