import type {
  ExperienceCustomerReviewScore,
  SearchExperiencesArgs,
} from 'src/__generated__/graphql'
import { ExperienceSortBy } from 'src/__generated__/graphql'

const {
  Recommended,
  CustomerReviewScore,
  PriceLowToHigh,
  PriceHighToLow,
  DurationShortToLong,
  DurationLongToShort,
} = ExperienceSortBy

const sortItems: { label: string; value: ExperienceSortBy }[] = [
  {
    label: 'Recommended',
    value: Recommended,
  },
  {
    label: 'Rating',
    value: CustomerReviewScore,
  },
  {
    label: 'Price Low to High',
    value: PriceLowToHigh,
  },
  {
    label: 'Price High to Low',
    value: PriceHighToLow,
  },
  {
    label: 'Duration Short to Long',
    value: DurationShortToLong,
  },
  {
    label: 'Duration Long to Short',
    value: DurationLongToShort,
  },
]

type ResultsRefetchArguments = Partial<SearchExperiencesArgs>

const initialMaxScore: ExperienceCustomerReviewScore['maxScore'] = '10.0'
const initialMinScore: ExperienceCustomerReviewScore['minScore'] = '1.0'
const initialSort: ExperienceSortBy = ExperienceSortBy.Recommended

export type { ResultsRefetchArguments }
export { initialMaxScore, initialMinScore, initialSort, sortItems }
