import { SortBy } from 'src/__generated__/graphql'
import type { DatesType, GeocoderType, GuestsType } from 'src/constants/user'
import {
  generateHotelResultsUrl,
  getGeocoderPlaceCountryFromAddressComponents,
  getGeocoderPlaceTypeFromAddressComponents,
  updateHotelResultsUrl,
} from 'src/utils'

const constructArguments = ({
  dates,
  geocoder,
  guests,
  searchParams,
  willUpdate = false,
}: {
  dates: DatesType
  geocoder: GeocoderType
  guests: GuestsType
  searchParams: URLSearchParams
  willUpdate?: boolean
}): string => {
  const [arrival, departure] = dates
  const { addressComponents, center, placeId, placeName, types, viewport } =
    geocoder
  const [latitude, longitude] = center
  const [adults, kids] = guests
  const updatedArguments = {
    arrival,
    departure,
    latitude,
    longitude,
    adults,
    kids,
    location: placeName,
  }
  const mapBounds = JSON.stringify(viewport) ?? ''
  const placeCountry =
    getGeocoderPlaceCountryFromAddressComponents(addressComponents)
  const placeFilteredType =
    getGeocoderPlaceTypeFromAddressComponents(addressComponents)

  searchParams.delete('placeCountry')
  searchParams.delete('placeId')
  searchParams.delete('placeShortName')
  searchParams.delete('placeType')
  const isPlaceIdAHotel = types?.includes('lodging') ?? false
  const updatedPlaceID = isPlaceIdAHotel ? placeId : ''

  if (willUpdate) {
    return updateHotelResultsUrl({
      overrides: updatedArguments,
      placeCountryValue: placeCountry ?? '',
      placeIdValue: updatedPlaceID,
      placeShortNameValue: placeFilteredType?.shortName ?? '',
      placeTypeValue: placeFilteredType?.type ?? '',
      searchParams,
    })
  }

  return generateHotelResultsUrl({
    ...updatedArguments,
    mapBounds,
    placeCountry: placeCountry ?? '',
    placeId: updatedPlaceID,
    placeShortName: placeFilteredType?.shortName ?? '',
    placeType: placeFilteredType?.type ?? '',
    sort: !isPlaceIdAHotel ? SortBy.Recommended : SortBy.Distance,
  })
}

export { constructArguments }
