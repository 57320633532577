import { Modal, ModalScrollContents } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { HotelCheckIn, HotelCheckInInstruction } from 'src/utils'
import { constructCheckInInstructions } from 'src/utils'
import { hotelPolicyContentClasses } from './hotelPoliciesStyles'

interface HotelCheckInInstructionsModalProps {
  checkin: HotelCheckIn
  checkinInstructions?: HotelCheckInInstruction[]
  onClose(): void
}

export const HotelCheckInInstructionsModal = ({
  checkin,
  checkinInstructions,
  onClose,
}: HotelCheckInInstructionsModalProps) => {
  const constructedCheckInInstructions =
    constructCheckInInstructions(checkinInstructions)

  return (
    <Modal size='medium' title='Check-in Instructions' onDismiss={onClose}>
      <ModalScrollContents>
        <div className={hotelCheckInInstructionsModalCss}>
          <p
            className={hotelPolicyContentClasses({
              contentType: 'type-body-1',
            })}
          >
            {checkin}
          </p>
          {!isEmpty(constructedCheckInInstructions) &&
            constructedCheckInInstructions.map((item, index) => (
              <p
                key={`${item}-${index}`}
                className={hotelPolicyContentClasses({
                  contentType: 'type-body-1',
                })}
              >
                {constructedCheckInInstructions}
              </p>
            ))}
        </div>
      </ModalScrollContents>
    </Modal>
  )
}

const hotelCheckInInstructionsModalCss = 'flex flex-col gap-4'
