const baseUrl = 'https://www.travelpass.com'

// MAIN SEGMENTS
const bookSegment = 'book'
const bookingsSegment = 'bookings'
const collectionsSegment = 'collections'
const competition = 'competition'
const dashboardSegment = 'dashboard'
const destinationsSegment = 'destinations'
const experiencesSegment = 'experiences'
const findMyBookingSegment = 'find-my-booking'
const guidesSegment = 'guides'
const hotelsSegment = 'hotels'
const infoSegment = 'info'
const profileSegment = 'profile'
const tripsSegment = 'trips'
const planSegment = 'plan'
const exploreSegment = 'explore'
const travelersSegment = 'travelers'

// HOTEL / EXPERIENCE / GUIDES SUB-SEGMENTS
const resultsSegment = 'results'

// INFO SUB-SEGMENTS
const faqSegment = 'faq'
const privacySegment = 'privacy'
const privacyNoticeSegment = 'privacy-notice'
const supportSegment = 'support'
const termsSegment = 'terms'

// GUIDES SUB-SEGMENTS
const itinerarySegment = 'itinerary'
const placesSegment = 'places'
const guideDraftSegment = 'list-guide'

// TRIPS SUB-SEGMENTS
const overviewSegment = 'overview'
const timelineSegment = 'timeline'

// PROMOTIONAL / MARKETING SEGMENTS
const ghostToursSegment = 'ghost-tours'
const insideScoopSegment = 'inside-scoop'
const utahJazzSegment = 'utah-jazz'
const awayGameItinerariesSegment = `${utahJazzSegment}/away-game-itineraries`
const utahJazzV2Segment = `${utahJazzSegment}/v2`

// MISC SEGMENTS
const nativeAppRedirectSegment = 'native-app-redirect'

// MAIN PATHS
const bookPath = `/${bookSegment}`
const bookingsPath = `/${bookingsSegment}`
const collectionsPath = `/${collectionsSegment}`
const competitionPath = `/${competition}`
const explorePath = `/${exploreSegment}`
const dashboardPath = `/${dashboardSegment}`
const destinationsPath = `/${destinationsSegment}/${resultsSegment}`
const experiencesPath = `/${experiencesSegment}`
const findMyBookingPath = `/${findMyBookingSegment}`
const hotelsPath = `/${hotelsSegment}`
const planPath = `/${planSegment}`
const profilePath = `/${profileSegment}`
const tripsPath = `/${tripsSegment}`

// COMPETITION PATHS
const competitionApplyPath = `/${competition}/apply`
const competitionAboutPath = `/${competition}/about#competition-nav`
const competitionFaqPath = `/${competition}/${faqSegment}`
const competitionLeaderboardPath = `/${competition}/leaderboard#competition-nav`

// EXPERIENCE PATHS
const experiencesResultsPath = `${experiencesPath}/${resultsSegment}`

// EXPLORE PATHS
const travelersPath = `${explorePath}/${travelersSegment}`
const travelersResultsPath = `${travelersPath}/${resultsSegment}`

// HOTEL PATHS
const hotelResultsPath = `/${hotelsSegment}/${resultsSegment}`

// INFO PATHS
const faqPath = `/${infoSegment}/${faqSegment}`
const privacyPath = `/${infoSegment}/${privacySegment}`
const privacyNoticePath = `/${infoSegment}/${privacyNoticeSegment}`
const supportPath = `/${infoSegment}/${supportSegment}`
const termsPath = `/${infoSegment}/${termsSegment}`

// GUIDES PATHS
const guidesEventsMapPath = '/map'
const guidesPath = `/${guidesSegment}`
const guidesResultsPath = `/${guidesSegment}/${resultsSegment}`
const guideDraftPath = `/${guidesSegment}/${guideDraftSegment}`

// TRIPS PATHS
const tripExplorePath = `/${tripsSegment}/${exploreSegment}`
const tripOverviewPath = `/${tripsSegment}/${overviewSegment}`
const tripTimelinePath = `/${tripsSegment}/${timelineSegment}`

// PROMOTIONAL / MARKETING PATHS
const ghostToursPath = `/${ghostToursSegment}`
const utahJazzPath = `/${utahJazzSegment}`
const awayGameItinerariesPath = `/${awayGameItinerariesSegment}`
const utahJazzV2Path = `/${utahJazzV2Segment}`

// MISC PATHS
const googlePlayNativeAppRoute =
  'https://play.google.com/store/apps/details?id=com.travelpassgroup.TravelPass'
const appStoreNativeAppRoute =
  'https://apps.apple.com/us/app/travelpass-travel-management/id1621360650'

export {
  appStoreNativeAppRoute,
  awayGameItinerariesSegment,
  awayGameItinerariesPath,
  baseUrl,
  bookingsSegment,
  bookingsPath,
  bookPath,
  bookSegment,
  collectionsPath,
  competitionAboutPath,
  competitionApplyPath,
  competitionFaqPath,
  competitionLeaderboardPath,
  competitionPath,
  dashboardPath,
  dashboardSegment,
  destinationsPath,
  destinationsSegment,
  experiencesPath,
  experiencesResultsPath,
  experiencesSegment,
  exploreSegment,
  explorePath,
  faqPath,
  faqSegment,
  ghostToursSegment,
  ghostToursPath,
  googlePlayNativeAppRoute,
  guidesEventsMapPath,
  guidesPath,
  guidesResultsPath,
  guidesSegment,
  hotelResultsPath,
  hotelsPath,
  hotelsSegment,
  infoSegment,
  insideScoopSegment,
  itinerarySegment,
  findMyBookingPath,
  findMyBookingSegment,
  guideDraftPath,
  guideDraftSegment,
  nativeAppRedirectSegment,
  overviewSegment,
  placesSegment,
  privacyNoticePath,
  privacyNoticeSegment,
  privacyPath,
  privacySegment,
  profilePath,
  profileSegment,
  resultsSegment,
  supportPath,
  supportSegment,
  termsPath,
  termsSegment,
  timelineSegment,
  travelersPath,
  travelersResultsPath,
  travelersSegment,
  tripOverviewPath,
  tripExplorePath,
  tripTimelinePath,
  tripsPath,
  tripsSegment,
  planPath,
  planSegment,
  utahJazzSegment,
  utahJazzPath,
  utahJazzV2Segment,
  utahJazzV2Path,
}
