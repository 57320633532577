import type { ReactNode } from 'react'
import classNames from 'classnames'
import { isProdEnv } from 'src/utils'

interface VerticalStepProps {
  children?: ReactNode
  label?: number
}

const VerticalStep = ({ children, label }: VerticalStepProps) => {
  const labelStr = label?.toString() || ''
  const hasLabel = !!labelStr
  if (!isProdEnv && labelStr.length > 2)
    console.warn('VerticalStep label should only be between 1-2 characters.')

  return (
    <li className={verticalStepClasses(hasLabel)}>
      {children}
      <div className={verticalStepDotClasses(hasLabel)}>{labelStr}</div>
    </li>
  )
}

const verticalStepClasses = (hasLabel: boolean) =>
  classNames('pb-8 pl-8 relative md:pl-9', {
    'min-h-12 md:min-h-13': hasLabel,
    'min-h-11': !hasLabel,
  })

const verticalStepDotClasses = (hasLabel: boolean) =>
  classNames(
    'absolute bg-black color-white flex flex-col items-center justify-center rounded-50% text-small-text top-0',
    {
      'h-4 left-0 w-4 md:h-5 md:w-5': hasLabel,
      'h-3 left-2px w-3 md:left-1': !hasLabel,
    }
  )

export { VerticalStep }
