import type { ReactNode } from 'react'
import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import {
  ExperienceCancellationType,
  RefundType,
} from 'src/__generated__/graphql'

const { None, Full, Partial, PartialWithFullPeriod } = RefundType
const { Custom } = ExperienceCancellationType

type HotelCancellationBadgeType =
  | 'SOLD_OUT'
  | RefundType
  | ExperienceCancellationType

interface HotelCancellationBadgeProps {
  content?: string
  isRow?: boolean
  label?: string
  type?: HotelCancellationBadgeType
  onClick?(): void
}

interface HotelCancellationBadgeItem {
  icon?: ReactNode | null
  title: string
}

const HotelCancellationBadge = ({
  content,
  isRow = false,
  label,
  type = None,
  onClick = () => {},
}: HotelCancellationBadgeProps) => {
  const constructHotelCancellationBadgeItem = ({
    type,
  }: Partial<HotelCancellationBadgeProps>): HotelCancellationBadgeItem => {
    if (type === Full)
      return {
        icon: <Icon name='event' size='small' />,
        title: 'Free Cancellation',
      }
    if (type === Partial || type === PartialWithFullPeriod || type === Custom)
      return {
        title: 'Partial Cancellation',
      }
    if (type === 'SOLD_OUT')
      return {
        icon: <Icon name='eventBusy' size='small' />,
        title: 'Sold Out',
      }

    return {
      title: 'Non refundable',
    }
  }

  const { icon, title } = constructHotelCancellationBadgeItem({ type }) ?? {}

  return (
    <div
      className={classNames('inline-flex sm:flex-col', {
        'gap-2 lg:flex-col': !isRow,
        'gap-6px lg:flex-row': isRow,
      })}
    >
      <div>
        <button
          className={hotelCancellationBadgeButtonClasses(type)}
          type='button'
          onClick={onClick}
        >
          {icon}
          <div className='font-size-3 font-600 line-height-3'>
            {label || title}
          </div>
        </button>
      </div>
      {content && (
        <p className='font-size-3 font-600 line-height-3 c-black h-26px flex flex-col justify-center'>
          {content}
        </p>
      )}
    </div>
  )
}

const hasValidType = (type: HotelCancellationBadgeProps['type']) =>
  type === Full ||
  type === Partial ||
  type === PartialWithFullPeriod ||
  type === Custom ||
  type === 'SOLD_OUT'

const hotelCancellationBadgeButtonClasses = (
  type: HotelCancellationBadgeProps['type']
) =>
  classNames(
    'flex flex-row gap-6px items-center cursor-pointer border-0 h-26px',
    {
      'rounded-1 justify-center transition-opacity duration-200 transition-ease-linear active:opacity-70 hover:opacity-70 px-3':
        hasValidType(type),
      'bg-#1584811A c-new-forest': type === Full,
      'bg-#E2CFBF4D c-canyonDark':
        type === Partial || type === PartialWithFullPeriod || type === Custom,
      'bg-#FFA1A133 c-redMedium': type === 'SOLD_OUT',
      'bg-transparent c-black': !hasValidType(type),
    }
  )

export { HotelCancellationBadge }
export type { HotelCancellationBadgeProps }
