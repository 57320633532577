import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { CollectionDetailsMenu } from '../CollectionDetailsMenu'

interface ReactCardProps extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode
  addToItem?: AddToItem
  onRemove?: VoidFunction
  isCollected?: boolean
  handleDelete?: () => void
  href?: string
  onClick?: (e) => void
  label?: string
  name?: string
  itemId?: string | number
}

export const ResultsCard = ({
  addToItem = null,
  isCollected = false,
  handleDelete,
  onRemove,
  children,
  className,
  href,
  label,
  name,
  itemId,
  ...props
}: ReactCardProps) => {
  return (
    <div
      className={classNames(resultsCardCss(!!props?.onClick), className)}
      data-testid='ResultsCard'
    >
      <div className='z-4 absolute right-3 top-3'>
        {addToItem && (
          <CollectionDetailsMenu
            hideGuide
            item={addToItem}
            onRemove={onRemove}
          />
        )}
      </div>
      {!href && props?.onClick && (
        <button
          className='b-none m-none p-none absolute left-0 top-0 h-full w-full cursor-pointer bg-transparent outline-none'
          data-testid='ResultsCard-button'
          draggable='false'
          tabIndex={-1}
          type='button'
          {...props}
        >
          <span className='sr-only'>
            {label || 'More information about this card, opens in a new tab'}
          </span>
        </button>
      )}
      {href && (
        <Link
          className='b-none m-none p-none absolute left-0 top-0 h-full w-full cursor-pointer bg-transparent outline-none'
          data-testid='ResultsCard-anchor'
          draggable='false'
          to={href}
          onClick={props?.onClick}
        >
          <span className='sr-only'>
            {label || 'More information about this card, opens in a new tab'}
          </span>
        </Link>
      )}
      {children}
    </div>
  )
}
// TODO: update focus/hover states for card when design is updated .
const resultsCardCss = (isClickable = false) =>
  classNames(
    'bg-white ease-linear h-full of-hidden relative rounded-3 transition-shadow-200',
    { 'hover:shadow-1': isClickable }
  )
