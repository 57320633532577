import { Modal, ModalNext } from '@travelpass/design-system'
import type { HotelCancellationPolicies } from 'src/utils'
import { constructCancellationPolicies } from 'src/utils'
import { HotelCancellationTimeline } from './HotelCancellationTimeline'
import type { HotelCancellationTimelineProps } from './HotelCancellationTimeline/HotelCancellationTimeline'

interface HotelCancellationProps {
  fullyRefundableUntil?: HotelCancellationTimelineProps['fullyRefundableUntil']
  nonRefundableAfter?: HotelCancellationTimelineProps['nonRefundableAfter']
  partiallyRefundableUntil?: HotelCancellationTimelineProps['partiallyRefundableUntil']
  policies: HotelCancellationPolicies
}

interface HotelCancellationModalProps extends HotelCancellationProps {
  onClose(): void
}

const HotelCancellationModal = ({
  fullyRefundableUntil,
  nonRefundableAfter,
  partiallyRefundableUntil,
  policies,
  onClose,
}: HotelCancellationModalProps) => {
  const constructedItems = constructCancellationPolicies(policies)?.map(
    cancellationPolicy => <li key={cancellationPolicy}>{cancellationPolicy}</li>
  )

  return (
    <ModalNext
      scroll={true}
      size='medium'
      title='Cancellation Policy'
      onClose={onClose}
    >
      <div className='space-y-8'>
        <HotelCancellationTimeline
          fullyRefundableUntil={fullyRefundableUntil}
          nonRefundableAfter={nonRefundableAfter}
          partiallyRefundableUntil={partiallyRefundableUntil}
        />
        <ul className='type-body-1 c-grey800 m-0 pl-5'>{constructedItems}</ul>
      </div>
    </ModalNext>
  )
}

export { HotelCancellationModal }
export type { HotelCancellationProps }
